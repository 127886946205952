import { useParams } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { invariant, nullthrows } from '../../../utils/invariant';
import {
  useGetCustomerPortalQuotationDownloadLinkQuery,
  useGetCustomerPortalQuotationQuery,
} from '../../../generated/graphql';
import { formatNumber } from '../../../utils/number';
import { DataField } from '../../../components/DataField';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { DownloadButton } from '../../document/components/DownloadButton';
import { useTranslation } from '../../../contexts/translation-context';

export const CustomerPortalQuotationPage = () => {
  const { i18n } = useTranslation();
  const { quotationId } = useParams<{ quotationId: string }>();
  invariant(quotationId);

  const [{ data }] = useGetCustomerPortalQuotationQuery({
    variables: {
      id: quotationId,
    },
  });
  const [{ data: downloadLinkData }] = useGetCustomerPortalQuotationDownloadLinkQuery({
    variables: {
      id: quotationId,
    },
  });

  const downloadLink = downloadLinkData?.customerPortalQuotationDownloadLink;
  const quotation = nullthrows(data?.customerPortalQuotation, i18n('customerPortal.quotes.notFound'));
  const title = i18n('customerPortal.quotes.quotation', {
    reference: quotation.reference,
  });
  const totalAmount = quotation.lines.reduce((acc, line) => acc + line.price, 0);

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: i18n('customerPortal.quotes.quotes'),
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-2">
            <DownloadButton downloadLink={downloadLink}>OFR</DownloadButton>
          </div>
        </div>

        <div className="flex gap-4 mb-8">
          <DataField title={i18n('customerPortal.quotes.type')}>{quotation.type}</DataField>
          <DataField title={i18n('customerPortal.quotes.quoteReference')}>{quotation.reference}</DataField>
          <DataField title={i18n('customerPortal.quotes.quoteDate')}>{formatDate(quotation.quotationDate)}</DataField>
          <DataField title={i18n('customerPortal.quotes.expirationDate')}>{formatDate(quotation.expiresAt)}</DataField>
          <DataField title={i18n('customerPortal.quotes.total')}>
            <span className="whitespace-nowrap">{`€ ${formatNumber(totalAmount, 2, {
              decimalSeperator: ',',
            })}`}</span>
          </DataField>
        </div>

        {quotation.notes.length > 0 && (
          <div className="mb-4">
            <DataField title="Notities">
              <span className="whitespace-pre-wrap">{quotation.notes}</span>
            </DataField>
          </div>
        )}

        <div>
          <div className="grid grid-cols-8 font-medium mb-2">
            <div className="col-span-3">{i18n('customerPortal.quotes.departurePlace')}</div>
            <div className="col-span-3">{i18n('customerPortal.quotes.destination')}</div>
            <div className="whitespace-nowrap">{i18n('customerPortal.quotes.distance')}</div>
            <div className="whitespace-nowrap">{i18n('customerPortal.quotes.price')}</div>
          </div>

          {quotation.lines.map((line) => {
            return (
              <div className="my-1" key={line.id}>
                <div className="grid grid-cols-8">
                  <div className="col-span-3">{`${line.departureCountry}${line.departurePostalCode} ${line.departureCity}`}</div>
                  <div className="col-span-3">{`${line.arrivalCountry}${line.arrivalPostalCode} ${line.arrivalCity}`}</div>
                  <div className="whitespace-nowrap">
                    {`${formatNumber(line.distance, 2, {
                      decimalSeperator: ',',
                    })} km`}
                  </div>
                  <div className="whitespace-nowrap">
                    {`€ ${formatNumber(line.price, 2, {
                      decimalSeperator: ',',
                    })}`}
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 mt-2">
                  {line.trailerTypes.map((trailerType) => {
                    return (
                      <div
                        className="bg-dark-05 rounded flex flex-col items-center justify-center px-2 py-1"
                        key={`${line.id}-${trailerType}`}
                      >
                        <TrailerTypeIcon type={trailerType} variant="small" />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

          <div className="grid grid-cols-8 font-medium mt-2">
            <div className="col-span-7">{i18n('customerPortal.quotes.total')}</div>
            <div className="whitespace-nowrap">{`€ ${formatNumber(totalAmount, 2, {
              decimalSeperator: ',',
            })}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};
