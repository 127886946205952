import { ChevronRightIcon } from 'lucide-react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbItem {
  name: string;
  to?: string;
}

export interface IBreadcrumbProps {
  items: IBreadcrumbItem[];
}

export const Breadcrumb: React.FC<IBreadcrumbProps> = (props) => {
  const { items } = props;

  return (
    <div className="flex items-center heading-one gap-2">
      {items.map((item, idx) => {
        const isLast = idx >= items.length - 1;

        return (
          <Fragment key={idx}>
            {item.to && !isLast ? <Link to={item.to}>{item.name}</Link> : <div>{item.name}</div>}
            {!isLast && <ChevronRightIcon className="h-6 w-6 self-end mb-1" />}
          </Fragment>
        );
      })}
    </div>
  );
};
