import { pdfjs, Document as PdfDocument, Page as PdfPage } from 'react-pdf';
import pdfWorkerUrl from 'pdfjs-dist/build/pdf.worker.min.js?url';
import { useState } from 'react';
import { ExternalLinkIcon, ChevronLeftIcon, ChevronRightIcon, FlipVertical2 } from 'lucide-react';
import classNames from '@utils/classnames';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerUrl;

export interface IPdfPreviewProps {
  file: string;
  filename: string;
  rotations?: Array<{ pageNumber: number; rotation: number }>;
  onRotate?: (pageNumber: number, rotation: number) => void;
}

export const PdfPreview = (props: IPdfPreviewProps) => {
  const { file, filename, rotations, onRotate } = props;
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const rotation = rotations?.find((r) => r.pageNumber === pageNumber)?.rotation || 0;
  return (
    <div className="border border-dark-05 rounded overflow-hidden w-full h-full flex flex-col">
      <div className="bg-dark-01 py-4 text-white flex justify-between">
        <div className="font-medium px-4">{filename}</div>

        <div className="flex">
          {onRotate && (
            <div className="mr-4 flex justify-center items-center">
              <button
                onClick={() => {
                  onRotate(pageNumber, rotation === 0 ? 180 : 0);
                }}
              >
                <FlipVertical2 className="w-4 h-4" />
              </button>
            </div>
          )}
          <a href={file} target="_blank" className="mr-2 flex justify-center items-center cursor-pointer">
            <ExternalLinkIcon className="w-4 h-4" />
          </a>
          <button
            className={classNames('px-2', {
              'opacity-50': pageNumber === 1,
            })}
            disabled={pageNumber === 1}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
          <div>{`${pageNumber} / ${numPages}`}</div>
          <button
            className={classNames('px-2', {
              'opacity-50': pageNumber === numPages,
            })}
            disabled={pageNumber === numPages}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      <div className="overflow-auto flex flex-1">
        <div className="mx-auto">
          <PdfDocument file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <PdfPage
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={800}
              rotate={rotation}
            />
          </PdfDocument>
        </div>
      </div>
    </div>
  );
};
