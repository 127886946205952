import { SearchIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetCustomerPortalInvoicesDocument,
  GetCustomerPortalInvoicesQuery,
  GetCustomerPortalInvoicesQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { calculateInvoiceTotals } from '../../sale/utils/calculate';
import { useTranslation } from '../../../contexts/translation-context';

export const CustomerPortalInvoicesPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const { i18n } = useTranslation();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomerPortalInvoicesQuery, GetCustomerPortalInvoicesQueryVariables>(
          GetCustomerPortalInvoicesDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customerPortalInvoices ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const tableHeaders: ITableHeader[] = [
    {
      id: 'id',
      name: i18n('customerPortal.invoices.invoiceNumber'),
    },
    {
      id: 'date',
      name: i18n('customerPortal.invoices.invoiceDate'),
    },
    {
      id: 'dueDate',
      name: i18n('customerPortal.invoices.dueDate'),
    },
    {
      id: 'total',
      name: i18n('customerPortal.invoices.total'),
    },
  ];

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = i18n('customerPortal.invoices.invoices');
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder={i18n('customerPortal.invoices.search')}
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={tableHeaders}
          data={page.data}
          mapData={(invoice) => {
            const totals = calculateInvoiceTotals(
              invoice.orders
                .map((o) =>
                  o.lines
                    .map((l) =>
                      l.sales.map((s) => {
                        return {
                          ...s,
                          vatRateId: s.vatRate.id,
                        };
                      }),
                    )
                    .flat(),
                )
                .flat(),
            );

            return [
              <Link to={`${invoice.id}`} className="link-text">
                {invoice.invoiceNumber ?? 'DRAFT'}
              </Link>,
              formatDate(invoice.date),
              invoice.expiresAt ? formatDate(invoice.expiresAt) : '-',
              <div className="whitespace-nowrap">{`€ ${formatNumber(totals.totalInclVat, 2, {
                decimalSeperator: ',',
              })}`}</div>,
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
