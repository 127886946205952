import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import { useInternalUpdateUserMutation, useGetInternalUserByIdQuery, UserRole } from 'generated/graphql';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { formatDateTime } from '../../../utils/date';
import { getDisplayError } from '../../../utils/get-display-error';
import { StatusBadge } from '../../../components/StatusBadge';
import { DataField } from '../../../components/DataField';
import { RoleSelect } from '../components/RoleSelect';
import { useAuth } from '../../../contexts/auth-context';
import { CheckboxWithLabel } from '../../../components/checkbox/CheckboxWithLabel';

const UserPage = () => {
  const { me } = useAuth();
  const { userId } = useParams<{ userId: string }>();
  invariant(userId);
  const [{ data, error }] = useGetInternalUserByIdQuery({
    variables: {
      id: userId,
    },
  });
  const [, updateUser] = useInternalUpdateUserMutation();

  const user = data?.internalUser;
  if (!user) {
    if (error) {
      return <div>Kon gebruiker niet laden: {error.message}</div>;
    } else {
      return <div>Gebruiker niet gevonden</div>;
    }
  } else {
    return (
      <>
        <PageHeader title={user.name} />

        <div>
          <div className="settings-page-heading">
            <div className="flex items-center gap-4">
              <h2 className="heading-two">{user.name}</h2>
              <StatusBadge color={user.isActive ? 'green' : 'red'}>{user.isActive ? 'Actief' : 'Inactief'}</StatusBadge>
            </div>

            <Button
              onTrigger={async () => {
                try {
                  const result = await updateUser({
                    id: user.id,
                    data: {
                      isActive: !user.isActive,
                    },
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success(user.isActive ? 'Gebruiker gedeactiveerd' : 'Gebruiker geheractiveerd');
                } catch (err) {
                  console.error(err);
                  toast.error(
                    `Kon gebruiker niet ${user.isActive ? 'deactiveren' : 'heractiveren'}: ${getDisplayError(err)}`,
                  );
                }
              }}
            >
              {user.isActive ? 'Deactiveer' : 'Activeer'}
            </Button>
          </div>

          <div>
            <div className="flex gap-8">
              <div>{user.image && <img src={user.image} className="w-24 h-24 rounded-lg" />}</div>
              <div>
                <DataField title="Rol">
                  <RoleSelect
                    isDisabled={user.id === me.id}
                    onSelect={async (newRole) => {
                      try {
                        const result = await updateUser({
                          id: user.id,
                          data: {
                            role: newRole as UserRole,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Rol gewijzigd');
                      } catch (err) {
                        console.error(err);
                        toast.error(`Kon rol niet wijzigen: ${getDisplayError(err)}`);
                      }
                    }}
                    value={user.role}
                  />
                </DataField>
                <DataField title="Naam">{user.name}</DataField>
                <DataField title="Email">{user.email}</DataField>
                <DataField title="Aangemaakt op">{formatDateTime(user.createdAt)}</DataField>

                <div className="my-4">
                  <CheckboxWithLabel
                    isChecked={user.shouldReceiveQuotationRequestNotifications}
                    onChange={async (newIsChecked) => {
                      try {
                        const result = await updateUser({
                          id: user.id,
                          data: {
                            shouldReceiveQuotationRequestNotifications: newIsChecked,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Notificatie voorkeuren gewijzigd');
                      } catch (err) {
                        console.error(err);
                        toast.error(`Kon notificatie voorkeuren niet wijzigen: ${getDisplayError(err)}`);
                      }
                    }}
                    labelText="Offerte aanvraag notificaties ontvangen"
                  />
                </div>

                <div className="my-4">
                  <CheckboxWithLabel
                    isChecked={user.shouldReceiveComplaintNotifications}
                    onChange={async (newIsChecked) => {
                      try {
                        const result = await updateUser({
                          id: user.id,
                          data: {
                            shouldReceiveComplaintNotifications: newIsChecked,
                          },
                        });
                        if (result.error) {
                          throw result.error;
                        }
                        toast.success('Notificatie voorkeuren gewijzigd');
                      } catch (err) {
                        console.error(err);
                        toast.error(`Kon notificatie voorkeuren niet wijzigen: ${getDisplayError(err)}`);
                      }
                    }}
                    labelText="Order klachten notificaties ontvangen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default UserPage;
