import { useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { GetCustomerPortalLocationByIdQuery, useGetCustomerPortalLocationByIdQuery } from '../../../generated/graphql';
import { DataField } from '../../../components/DataField';
import { useTranslation } from '../../../contexts/translation-context';

export type Location = NonNullable<GetCustomerPortalLocationByIdQuery['customerPortalLocation']>;

export const CustomerPortalLocationPage = () => {
  const { locationId } = useParams<{ locationId: string }>();
  invariant(locationId);
  const { i18n } = useTranslation();
  const [{ data, error }, reexecuteQuery] = useGetCustomerPortalLocationByIdQuery({
    variables: {
      id: +locationId,
    },
  });

  const location = data?.customerPortalLocation;
  if (!location) {
    if (error) {
      return (
        <div>
          {i18n('customerPortal.locations.couldNotLoadLocation', {
            error: error.message,
          })}
        </div>
      );
    } else {
      return <div>{i18n('customerPortal.locations.locationNotFound')}</div>;
    }
  } else {
    return (
      <>
        <PageHeader title={location.name} />

        <div>
          <div className="page-heading">
            <Breadcrumb
              items={[
                {
                  name: i18n('customerPortal.locations.locations'),
                  to: '..',
                },
                {
                  name: location.name,
                },
              ]}
            />
          </div>

          <DataField title={i18n('customerPortal.locations.language')}>{location.language}</DataField>

          <DataField title={i18n('customerPortal.locations.address')}>
            <div>{`${location.street} ${location.streetNumber}`}</div>
            <div>{`${location.country}${location.postalCode} ${location.city}`}</div>
          </DataField>
        </div>
      </>
    );
  }
};
