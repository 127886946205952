import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { PlusIcon, TrashIcon } from 'lucide-react';
import toast from 'react-hot-toast';

import { PageHeader } from '../../../components/PageHeader';
import { invariant } from '../../../utils/invariant';
import { Country, QuotationType, TrailerType, useCustomerPortalRequestQuoteMutation } from '../../../generated/graphql';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { useTranslation } from '../../../contexts/translation-context';
import { ISimpleSelectItem } from '../../../components/select/SimpleSelect';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { QUOTATION_TYPE_OPTIONS } from '../../quotation/constants';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { COUNTRY_VALUES } from '../../../utils/address';
import { InputField } from '../../../components/input/InputField';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { Button } from '../../../components/button/Button';
import { AutocompletePostalcode } from '../../location/components/AutocompletePostalcode';
import { TrailerTypes } from '../../order/pages/order/TrailerTypes';
import { getDisplayError } from '../../../utils/get-display-error';

const createQuotationRequestLineSchema = Yup.object().shape({
  departurePostalCode: Yup.mixed().nullable().required('Vereist'),
  arrivalPostalCode: Yup.mixed().nullable().required('Vereist'),
  trailerTypes: Yup.array().required('Vereist'),
});

const createQuotationRequestSchema = Yup.object().shape({
  reference: Yup.string().required('Vereist'),
  notes: Yup.string(),
  lines: Yup.array(createQuotationRequestLineSchema).required('Vereist'),
});

export interface ILineState {
  departure?: [number, number];
  arrival?: [number, number];
  fetchKey?: string;
}

interface IQuotationRequestLineValues {
  departurePostalCode: string;
  departureCountry: ISimpleSelectItem;
  departureCity: string;
  arrivalPostalCode: string;
  arrivalCountry: ISimpleSelectItem;
  arrivalCity: string;
  trailerTypes: TrailerType[];
}

interface IQuotationRequestValues {
  type: ISimpleSelectItem;
  reference: string;
  notes: string;
  lines: IQuotationRequestLineValues[];
}

export const CustomerPortalRequestQuotationPage = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const { customer } = useCustomerPortalCustomer();
  const [, requestQuote] = useCustomerPortalRequestQuoteMutation();

  const [_lines, setLines] = useState<ILineState[]>([
    {
      departure: undefined,
      arrival: undefined,
      fetchKey: Date.now().toString(),
    },
  ]);

  const initialLineValues: IQuotationRequestLineValues = {
    departurePostalCode: '',
    departureCountry: COUNTRY_VALUES.find((v) => v.key === customer.country)!,
    departureCity: '',
    arrivalPostalCode: '',
    arrivalCountry: COUNTRY_VALUES.find((v) => v.key === customer.country)!,
    arrivalCity: '',
    trailerTypes: [TrailerType.Tautliner],
  };

  const initialValues: IQuotationRequestValues = useMemo(() => {
    return {
      type: QUOTATION_TYPE_OPTIONS[0],
      reference: '',
      notes: '',
      lines: [
        {
          ...initialLineValues,
        },
      ],
    };
  }, []);

  const formikbag = useFormik({
    initialValues: initialValues,
    validationSchema: createQuotationRequestSchema,
    onSubmit: async (values) => {
      try {
        const { type, reference, notes, lines } = values;
        const customerId = customer?.id;
        invariant(customerId);
        const result = await requestQuote({
          data: {
            type: type.key as QuotationType,
            reference,
            notes,
            lines: lines.map((l) => {
              return {
                departurePostalCode: l.departurePostalCode,
                departureCountry: l.departureCountry.key as Country,
                departureCity: l.departureCity,
                arrivalPostalCode: l.arrivalPostalCode,
                arrivalCountry: l.arrivalCountry.key as Country,
                arrivalCity: l.arrivalCity,
                trailerTypes: l.trailerTypes,
              };
            }),
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          navigate(`../${result.data.customerPortalRequestQuote.id}`);
        }
        toast.success(i18n('customerPortal.quotes.requestSuccess'));
      } catch (err: any) {
        toast.error(
          i18n('customerPortal.quotes.requestFailed', {
            error: getDisplayError(err),
          }),
        );
      }
    },
  });

  const title = i18n('customerPortal.quotes.request');
  const { values, isSubmitting, handleSubmit, setFieldValue } = formikbag;
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: i18n('customerPortal.quotes.quotationRequests'),
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />
        </div>

        <FormikProvider value={formikbag}>
          <form onSubmit={handleSubmit}>
            <SimpleComboboxField
              items={QUOTATION_TYPE_OPTIONS}
              name="type"
              labelText={i18n('customerPortal.quotes.type')}
            />

            <InputField
              type="text"
              labelText={i18n('customerPortal.quotes.quoteReference')}
              name="reference"
              isDisabled={isSubmitting}
            />

            <TextAreaField
              labelText={i18n('customerPortal.quotes.notes')}
              name="notes"
              isDisabled={isSubmitting}
              spellCheck={true}
            />

            <FieldArray
              name="lines"
              render={(arrayHelpers) => (
                <div className="my-4">
                  <div className="flex justify-between items-end">
                    <h2 className="heading-two mb-2">{i18n('customerPortal.quotes.lines')}</h2>
                    <Button
                      onTrigger={() => {
                        const itemToAdd = { ...initialLineValues };
                        arrayHelpers.push(itemToAdd);
                        setLines((prev) => {
                          return [
                            ...prev,
                            {
                              departure: undefined,
                              arrival: undefined,
                              fetchKey: Date.now().toString(),
                            },
                          ];
                        });
                      }}
                      iconLeft={<PlusIcon className="button-icon" />}
                    >
                      {i18n('customerPortal.quotes.addLine')}
                    </Button>
                  </div>

                  {values['lines'].length > 0 ? (
                    <div>
                      {values['lines'].map((_line, idx) => {
                        return (
                          <div className="flex flex-col mt-4" key={`quotation-line-${idx}`}>
                            <div className="flex w-full gap-4">
                              <div className="grid quotation-line-form-columns gap-4 w-full">
                                <div>
                                  <div className="font-medium">{i18n('customerPortal.quoteLine.from')}</div>
                                  <div>
                                    <AutocompletePostalcode
                                      postalCodeName={`lines[${idx}].departurePostalCode`}
                                      countryName={`lines[${idx}].departureCountry`}
                                      cityName={`lines[${idx}].departureCity`}
                                      onAutocomplete={(_postalCode, _city, longitude, latitude) => {
                                        setLines((prev) => {
                                          prev[idx].departure = [longitude, latitude];
                                          return [...prev];
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="font-medium">{i18n('customerPortal.quoteLine.to')}</div>
                                  <div>
                                    <AutocompletePostalcode
                                      postalCodeName={`lines[${idx}].arrivalPostalCode`}
                                      countryName={`lines[${idx}].arrivalCountry`}
                                      cityName={`lines[${idx}].arrivalCity`}
                                      onAutocomplete={(_postalCode, _city, longitude, latitude) => {
                                        setLines((prev) => {
                                          prev[idx].arrival = [longitude, latitude];
                                          return [...prev];
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex items-center pt-6">
                                <Button
                                  onTrigger={() => {
                                    arrayHelpers.remove(idx);
                                    setLines((prev) => {
                                      return prev.filter((_, i) => i !== idx);
                                    });
                                  }}
                                >
                                  <TrashIcon className="button-icon" />
                                </Button>
                              </div>
                            </div>
                            <div>
                              <div className="max-w-6xl">
                                <TrailerTypes
                                  value={values.lines[idx].trailerTypes}
                                  onChange={(newTrailerTypes) => {
                                    setFieldValue(`lines[${idx}].trailerTypes`, newTrailerTypes);
                                  }}
                                  isMultiSelect={true}
                                  isRequired={true}
                                  variant="small"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>{i18n('customerPortal.quotes.noLines')}</div>
                  )}
                </div>
              )}
            />

            <div className="mt-8">
              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                iconLeft={<PlusIcon className="button-icon" />}
              >
                {i18n('customerPortal.quotes.request')}
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </>
  );
};
