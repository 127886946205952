import { TrashIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Button } from '../../../../components/button/Button';
import { ProductTypeComboboxField } from '../../productType/ProductTypeComboboxField';
import { InputField } from '../../../../components/input/InputField';
import { VatRateComboboxField } from '../../vatRate/VatRateComboboxField';
import { IOrderLineSaleValues } from '../orderLineSale/CreateOrderLineSale';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { ICreateOrderLineFieldsProps } from './CreateOrderLineFields';

export interface ICreateOrderLineSaleProps {
  index: number;
  onRemove: (index: number) => void;
  sales: Array<IOrderLineSaleValues>;
  customer: ICreateOrderLineFieldsProps['customer'];
}

export const CreateOrderLineSale: React.FC<ICreateOrderLineSaleProps> = (props) => {
  const { index, onRemove, sales, customer } = props;
  const formikbag = useFormikContext();
  const { settings } = useMinimalGeneralSettings();

  const dieselSurchargePercentage = customer?.dieselSurchargeEnabled
    ? customer?.hasCustomDieselSurcharge
      ? customer?.dieselSurchargePercentage ?? 0
      : settings.dieselSurchargePercentage
    : 0;

  const amount = sales[index].amount;
  const productType = sales[index].productType;
  const isDieselSurchargeSelected = productType?.key === settings.dieselSurchargeProductType?.id;
  useEffect(() => {
    if (isDieselSurchargeSelected) {
      const baseProduct = sales.find((p) => p.productType?.key === settings.dieselSurchargeBaseProductType?.id);
      if (baseProduct) {
        const parsedAmount = parseNumberInput(amount, 2);
        if (parsedAmount > 0) return;

        const surchargeValue = (parseNumberInput(baseProduct.unitPrice, 2) / 100) * (dieselSurchargePercentage / 100);
        formikbag.setFieldValue(`sales[${index}].unitPrice`, formatNumber(surchargeValue, 2));
      }
    }
  }, [productType?.key]);

  return (
    <div className="mt-4">
      <div className="flex gap-2 items-center">
        <div className="flex-1">
          <ProductTypeComboboxField labelText="Product" name={`sales[${index}].productType`} />
        </div>

        <Button
          onTrigger={() => {
            onRemove(index);
          }}
          iconLeft={<TrashIcon className="button-icon" />}
        >
          Verwijder
        </Button>
      </div>

      <div className="w-full flex gap-2">
        <div style={{ flex: 2 }}>
          <InputField labelText="Aantal" type="number" name={`sales[${index}].amount`} step="0.01" />
        </div>
        <div style={{ flex: 2 }}>
          <InputField labelText="Eenheidsprijs" type="number" name={`sales[${index}].unitPrice`} step="0.01" />
        </div>
        <div style={{ flex: 3 }}>
          <VatRateComboboxField labelText="Btw Tarief" name={`sales[${index}].vatRate`} />
        </div>
      </div>
      <InputField labelText="Extra info" type="text" name={`sales[${index}].externalNote`} />

      {isDieselSurchargeSelected && (
        <div className="text-sm">{`Diesel toeslag voor klant is ${formatNumber(dieselSurchargePercentage, 2)}%`}</div>
      )}
    </div>
  );
};
