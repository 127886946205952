import { KeyIcon } from 'lucide-react';

import { PageHeader } from '../../../components/PageHeader';
import { LinkButton } from '../../../components/button/ButtonLink';
import { useTranslation } from '../../../contexts/translation-context';

export const UserNotFoundPage = () => {
  const { i18n } = useTranslation();

  return (
    <div className="h-screen">
      <PageHeader title={i18n('auth.userNotFound.title')} />

      <main className="bg-dark-01 p-8 h-full">
        <div className="flex flex-col justify-center h-full items-center max-w-md mx-auto">
          <h1 className="heading-one mb-8 text-white">{i18n('auth.userNotFound.title')}</h1>

          <div className="text-white text-center my-8">{i18n('auth.userNotFound.description')}</div>

          <div className="flex justify-center w-full">
            <LinkButton color="primary" to="/customer-portal/login" iconLeft={<KeyIcon className="button-icon" />}>
              {i18n('auth.userNotFound.goToLoginPage')}
            </LinkButton>
          </div>
        </div>
      </main>
    </div>
  );
};
