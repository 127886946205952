import { useState } from 'react';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Input } from '../../../components/input/Input';
import { CallAssistantMutation, useCallAssistantMutation } from '../../../generated/graphql';
import { Spinner } from '../../../components/Spinner';
import classNames from '../../../utils/classnames';

type Messages = NonNullable<CallAssistantMutation['callAssistant']>['messages'];

export const AiAssistantPage = () => {
  const [message, setMessage] = useState('');
  const [, callAssistant] = useCallAssistantMutation();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [messages, setMessages] = useState<Messages>([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PageHeader title="AI Assistant" />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'AI Assistant',
              },
            ]}
          />
        </div>

        <div>
          <div>
            {messages.map((v) => {
              return (
                <div
                  className={classNames('flex flex-col my-2', {
                    'pl-4 items-end': v.role.toLowerCase() === 'assistant',
                    'items-start': v.role.toLowerCase() !== 'assistant',
                  })}
                  key={`msg-${v.id}`}
                >
                  <div
                    className={classNames('rounded px-2 py-1 whitespace-pre-line', {
                      'bg-dark-03 text-white': v.role.toLowerCase() === 'assistant',
                      'bg-dark-05': v.role.toLowerCase() === 'user',
                    })}
                  >
                    {v.message}
                  </div>
                  <div className="flex flex-wrap justify-end gap-1">
                    {v.toolCalls.map((toolCall) => {
                      return (
                        <div
                          className="text-sm rounded text-white bg-orange-01 px-1 mt-1"
                          key={toolCall.id}
                          title={toolCall.arguments}
                        >{`Tool: ${toolCall.toolName}`}</div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {isLoading && (
              <div className="my-2">
                <Spinner size={4} />
              </div>
            )}
          </div>

          <div>
            <Input
              type="text"
              placeholder='Stel een vraag en druk op "enter" om te verzenden.'
              value={message}
              onChange={setMessage}
              isDisabled={isLoading}
              onKeyDown={async (evt) => {
                if (isLoading) return;

                if (evt.key === 'Enter') {
                  setMessage('');
                  setMessages((prev) => {
                    return [
                      ...prev,
                      {
                        id: Date.now().toString(10),
                        idx: prev[prev.length - 1]?.idx + 1 ?? 0,
                        role: 'user',
                        message,
                        toolCalls: [],
                      },
                    ];
                  });
                  setIsLoading(true);
                  const res = await callAssistant({ message, sessionId });
                  if (res.error) {
                    console.error(res.error);
                    return;
                  }

                  const assistantSession = res.data?.callAssistant;
                  if (assistantSession) {
                    console.log(assistantSession);
                    setMessages(() => assistantSession.messages.sort((a, b) => a.idx - b.idx));
                    setSessionId(assistantSession.id);
                  }

                  setIsLoading(false);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
