import { useTranslation } from '../../../../contexts/translation-context';
import { formatDateTime } from '../../../../utils/date';
import { LogOrderComplaintDialog } from './OrderComplaintDialog';
import { useOrder } from './orderContext';

export const OrderComplaintsPage = () => {
  const { order } = useOrder();
  const { i18n } = useTranslation();

  return (
    <div>
      <div className="mb-4 flex justify-end">
        <LogOrderComplaintDialog orderId={order.id} />
      </div>

      <div className="grid gap-2 xl:grid-cols-2">
        {order.complaints.map((v) => {
          return (
            <div className="card" key={v.id}>
              <div>{v.content}</div>

              <div className="flex justify-between items-center mt-2">
                <div className="text-sm text-dark-02">{formatDateTime(v.createdAt)}</div>
                <div className="px-2 py-1 rounded-md inline-flex items-center bg-dark-04 text-xs">
                  {i18n(`orderComplaintType.${v.type}`)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
