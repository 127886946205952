import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { CheckIcon, ChevronsUpDownIcon, XIcon } from 'lucide-react';
import classNames from '@utils/classnames';
import { useCallback } from 'react';

export interface IComboboxProps<V extends Record<string, any>> {
  placeholder?: string;
  onQueryChange: (newQuery: string) => void;
  items: V[];
  selectedItem?: V | null;
  onSelect: (value: V | null) => void;
  keyName: string;
  display: (value: V) => string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
}

export function Combobox<V extends Record<string, any>>(props: IComboboxProps<V>) {
  const {
    placeholder,
    keyName,
    onQueryChange,
    items,
    display,
    selectedItem,
    onSelect,
    isDisabled,
    isInvalid,
    onBlur,
    isNullable,
  } = props;

  const displayValue = useCallback(
    (v: any) => {
      if (v) {
        return display(v);
      } else {
        return '';
      }
    },
    [display],
  );

  return (
    <div className="flex gap-1">
      <HeadlessCombobox
        as="div"
        // @ts-ignore
        value={selectedItem}
        // @ts-ignore
        onChange={onSelect}
        disabled={isDisabled}
        onBlur={onBlur}
        // @ts-ignore
        nullable={isNullable}
        className="w-full"
      >
        <div className="relative">
          <HeadlessCombobox.Input
            className={classNames(
              'w-full border rounded-lg bg-offwhite py-2 pl-3 pr-10 focus:border-indigo-500 focus:outline-none',
              {
                'border-dark-04': !isInvalid,
                'border-feedback-negative': isInvalid,
              },
            )}
            onChange={(event) => onQueryChange(event.target.value)}
            displayValue={displayValue}
            placeholder={placeholder}
            autoComplete="off"
          />
          <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
            <ChevronsUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </HeadlessCombobox.Button>

          {items.length > 0 && (
            <HeadlessCombobox.Options className="absolute z-dropdown max-h-60 w-full overflow-auto bg-offwhite py-1 text-base ring-1 ring-dark-04 focus:outline-none shadow-md mt-1 rounded-lg">
              {items.map((item) => (
                <HeadlessCombobox.Option
                  // @ts-ignore
                  key={item[keyName]}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-pointer select-none py-2 pl-3 pr-9',
                      active ? 'text-orange-01' : 'text-dark-01',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div>{display(item)}</div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-orange-01' : 'text-dark-01',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </HeadlessCombobox.Option>
              ))}
            </HeadlessCombobox.Options>
          )}
        </div>
      </HeadlessCombobox>

      {Boolean(selectedItem && isNullable) && (
        <div
          className="w-8 flex items-center justify-center hover:bg-dark-05 rounded-md cursor-pointer"
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();

            onSelect(null);
            onQueryChange('');
          }}
        >
          <XIcon className="button-icon" />
        </div>
      )}
    </div>
  );
}
