import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Language, useCreateCustomerContactMutation } from 'generated/graphql';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { formatPhoneNumber } from '../../../utils/phonenumber';
import { sleep } from '../../../utils/sleep';
import { getDisplayError } from '../../../utils/get-display-error';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { CheckboxField } from '../../../components/checkbox/CheckboxField';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { useTranslation } from '../../../contexts/translation-context';

const createContactSchema = Yup.object().shape({
  name: Yup.string().min(1, 'errors.required').required('errors.required'),
  email: Yup.string().email('errors.invalidEmail'),
  phone: Yup.string().phone(),
  language: Yup.mixed().nullable().required('errors.required'),
  shouldReceiveInvoices: Yup.boolean(),
  shouldReceiveOrderConfirmations: Yup.boolean(),
  shouldReceiveCMR: Yup.boolean(),
  shouldReceiveQuotations: Yup.boolean(),
  shouldReceiveDieselSurcharge: Yup.boolean(),
});

export const CustomerPortalCreateContactPage = () => {
  const navigate = useNavigate();
  const { customer, refreshData: refetchCustomerData } = useCustomerPortalCustomer();
  const [, createContactMutation] = useCreateCustomerContactMutation();
  const { i18n } = useTranslation();

  return (
    <div>
      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: i18n('customerPortal.contacts.contacts'),
              to: '..',
            },
            {
              name: i18n('customerPortal.contacts.newContact'),
            },
          ]}
        />
      </div>

      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          function: '',
          language: LANGUAGE_VALUES.find((v) => v.key === customer.language)!,
          shouldReceiveInvoices: false,
          shouldReceiveOrderConfirmations: false,
          shouldReceiveCMR: false,
          shouldReceiveQuotations: false,
          shouldReceiveDieselSurcharge: false,
          internalNotes: '',
        }}
        validationSchema={createContactSchema}
        onSubmit={async (values) => {
          try {
            const result = await createContactMutation({
              data: {
                ...values,
                phone: formatPhoneNumber(values.phone, false),
                language: values.language.key as Language,
                customerId: customer.id,
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchCustomerData();
              await sleep(100);
              navigate('..');
            }
            toast.success(i18n('customerPortal.contacts.contactCreated'));
          } catch (err: any) {
            toast.error(
              i18n('customerPortal.contacts.couldNotCreateContact', {
                error: getDisplayError(err),
              }),
            );
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText={i18n('customerPortal.contacts.name')} type="text" name="name" />
            <InputField labelText={i18n('customerPortal.contacts.email')} type="email" name="email" />
            <InputField labelText={i18n('customerPortal.contacts.phone')} type="tel" name="phone" />
            <InputField labelText={i18n('customerPortal.contacts.function')} type="text" name="function" />
            <SimpleSelectField
              labelText={i18n('customerPortal.contacts.language')}
              items={LANGUAGE_VALUES}
              name="language"
            />
            {/* <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} /> */}

            <h2 className="heading-two mb-2">{i18n('customerPortal.contacts.notifications')}</h2>
            <div className="grid grid-cols-4 gap-x-4 mb-2">
              <CheckboxField labelText={i18n('customerContactNotifications.invoice')} name="shouldReceiveInvoices" />
              <CheckboxField
                labelText={i18n('customerContactNotifications.orderConfirmation')}
                name="shouldReceiveOrderConfirmations"
              />
              <CheckboxField labelText={i18n('customerContactNotifications.cmr')} name="shouldReceiveCMR" />
              <CheckboxField
                labelText={i18n('customerContactNotifications.quotation')}
                name="shouldReceiveQuotations"
              />
              <CheckboxField
                labelText={i18n('customerContactNotifications.dieselSurcharge')}
                name="shouldReceiveDieselSurcharge"
              />
            </div>

            <Button
              type="submit"
              color="primary"
              iconLeft={<PlusIcon className="button-icon" />}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {i18n('customerPortal.contacts.createContact')}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
