import { CalendarDaysIcon, ClockIcon, TrashIcon } from 'lucide-react';
import { PageHeader } from 'components/PageHeader';
import {
  CreditNoteStatus,
  GetInvoiceQuery,
  OrderLineStopType,
  useApproveCreditNoteMutation,
  useDeleteCreditNoteMutation,
  useGenerateCreditNoteMutation,
  useGetCreditNoteQuery,
  useSendCreditNoteMutation,
} from 'generated/graphql';
import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { DataField } from '../../../../components/DataField';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { formatDate, formatDateTime, formatInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber } from '../../../../utils/number';
import { FileUploaderButton } from '../../../document/components/FileUploaderButton';
import { calculateInvoiceTotals } from '../../utils/calculate';
import { InputField } from '../../../../components/input/InputField';
import { FormDialog } from '../../../../components/dialog/FormDialog';
import { useAuth } from '../../../../contexts/auth-context';

export type Invoice = NonNullable<GetInvoiceQuery['invoice']>;

const CreditNotePage = () => {
  const { me } = useAuth();
  const navigate = useNavigate();
  const { creditNoteId } = useParams<{ creditNoteId: string }>();
  invariant(creditNoteId);
  const [{ data, error }, refetch] = useGetCreditNoteQuery({
    variables: {
      id: creditNoteId,
    },
  });
  const [_sendCreditNoteState, sendCreditNote] = useSendCreditNoteMutation();
  const [_approveCreditNoteState, approveCreditNote] = useApproveCreditNoteMutation();
  const [_genCreditNoteState, generateCreditNote] = useGenerateCreditNoteMutation();
  const [_delCreditNoteState, deleteCreditNote] = useDeleteCreditNoteMutation();

  const refreshData = useCallback(() => {
    return refetch({
      requestPolicy: 'network-only',
    });
  }, [refetch]);

  const creditNote = nullthrows(data?.creditNote, 'Creditnota niet gevonden');
  const totals = calculateInvoiceTotals(
    creditNote.creditNoteOrders
      .map((o) =>
        o.lines
          .map((l) => {
            return {
              ...l,
              vatRateId: l.vatRate.id,
            };
          })
          .flat(),
      )
      .flat(),
  );
  const title = `Creditnota ${creditNote.creditNoteNumber ?? 'DRAFT'}`;
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: `Creditnota's`,
                to: '/internal/finance/creditnotes',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-2">
            <div>
              <FileUploaderButton
                buttonText="CDN"
                title="Creditnota"
                file={creditNote.document}
                generateDocument={async () => {
                  const res = await generateCreditNote({
                    creditNoteId: creditNote.id,
                  });
                  if (res.error) {
                    throw res.error;
                  }
                }}
                disableUpload
              />
            </div>

            {creditNote.status === CreditNoteStatus.Draft && (
              <FormDialog
                triggerText="Creditnota goedkeuren"
                title="Ben je zeker dat je de creditnota wilt goedkeuren?"
                submitText="Creditnota goedkeuren"
                initialValues={{ creditNoteNumber: '' }}
                onSubmit={async (values) => {
                  const creditNoteNumber = values.creditNoteNumber.trim();

                  try {
                    const result = await approveCreditNote({
                      creditNoteId: creditNote.id,
                      creditNoteNumber: creditNoteNumber ? +creditNoteNumber : undefined,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Creditnota goedgekeurd');
                  } catch (err) {
                    toast.error(`Kon creditnota niet goedkeuren: ${getDisplayError(err)}`);
                  }
                }}
              >
                <div>Ben je zeker dat je deze creditnota wilt goedkeuren?</div>

                <div>
                  Vul het credit nota nummer alleen in als je manueel een ander factuurnummer wilt gebruiken (uit het
                  verleden), anders zal het de automatische nummering de mist in laten gaan.
                </div>

                <InputField labelText="Credit nota nummer" name="creditNoteNumber" type="number" />
              </FormDialog>
            )}

            {creditNote.status !== CreditNoteStatus.Draft && (
              <ConfirmDialog
                triggerText="Verzend creditnota"
                title="Ben je zeker dat je de creditnota wilt versturen?"
                submitText="Verzend creditnota"
                description={
                  <div>
                    <div>Ben je zeker dat je de creditnota wilt versturen naar de volgende contacten?</div>
                    <ul>
                      {creditNote.customer.contacts
                        .filter((c) => c.shouldReceiveInvoices)
                        .map((contact) => {
                          return (
                            <li key={contact.id}>
                              {contact.name} - {contact.email}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                }
                onSubmit={async () => {
                  try {
                    const result = await sendCreditNote({
                      creditNoteId: creditNote.id,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Creditnota verstuurd');
                  } catch (err) {
                    toast.error(`Kon creditnota niet versturen: ${getDisplayError(err)}`);
                  }
                }}
              />
            )}

            {me.role === 'Admin' && (
              <ConfirmDialog
                triggerText={<TrashIcon className="button-icon" />}
                title="Creditnota verwijderen?"
                submitText="Verwijder creditnota"
                description={
                  <div>
                    Ben je zeker dat je dit credit nota wilt verwijderen? Dit kan ervoor zorgen dat de credit nota
                    nummering niet meer opeenlopend zijn.
                  </div>
                }
                onSubmit={async () => {
                  try {
                    const result = await deleteCreditNote({
                      creditNoteId: creditNote.id,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Creditnota verwijderd');
                    navigate('..');
                  } catch (err) {
                    toast.error(`Kon creditnota niet verwijderen: ${getDisplayError(err)}`);
                  }
                }}
              />
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-6 mb-4">
          <DataField title="Klant">
            {creditNote.customer.name} ({creditNote.customer.id})
          </DataField>
          <DataField title="Gestructureerde mededeling">{creditNote.structuredComment}</DataField>
          <DataField title="Factuur datum">{formatDate(creditNote.date)}</DataField>
          {creditNote.sentAt && <DataField title="Verstuurd op">{formatDate(creditNote.sentAt)}</DataField>}
          {creditNote.sentAt && <DataField title="Vervalt op">{formatDate(creditNote.expiresAt)}</DataField>}
          {creditNote.paidAt && <DataField title="Betaald op">{formatDateTime(creditNote.paidAt)}</DataField>}
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          {!!creditNote.internalNotes && <DataField title="Interne Notities">{creditNote.internalNotes}</DataField>}
          {!!creditNote.externalNotes && <DataField title="Externe Notities">{creditNote.externalNotes}</DataField>}
        </div>

        <div>
          {creditNote.creditNoteOrders.map((creditNoteOrder) => {
            const orderTotals = calculateInvoiceTotals(
              creditNoteOrder.lines
                .map((l) => {
                  return {
                    ...l,
                    vatRateId: l.vatRate.id,
                  };
                })
                .flat(),
            );
            const order = creditNoteOrder.order;
            const stops = order.lines.map((l) => l.stops).flat();

            return (
              <div key={`order-${order.orderNumber}`}>
                <Link to={`/internal/orders/${order.id}/general`} className="heading-two mb-4">
                  Order {order.orderNumber} - {order.customerRef}
                </Link>

                <div>
                  <div>
                    {stops
                      .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                      .map((stop) => {
                        return (
                          <div className="grid grid-cols-4" key={`stop-${stop.id}`}>
                            <div className="font-medium">
                              {stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}
                            </div>
                            <div>
                              {stop.location.country}-{stop.location.name}
                            </div>

                            <div className="flex gap-2 items-center">
                              <CalendarDaysIcon className="w-4 h-4" />
                              {formatDate(stop.date)}
                            </div>
                            <div className="flex gap-2 items-center">
                              <ClockIcon className="w-4 h-4" />
                              {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="border-t py-4 my-4 border-dark-04">
                    <div className="grid grid-cols-4 gap-x-4 mb-1">
                      <div className="bold-label">Beschrijving</div>
                      <div className="bold-label">Aantal</div>
                      <div className="bold-label">Eenheidsprijs</div>
                      <div className="bold-label">Totaal</div>
                    </div>

                    <div className="grid grid-cols-4 gap-x-4 gap-y-1">
                      {creditNoteOrder.lines.map((line) => {
                        return (
                          <React.Fragment key={`line-${line.id}`}>
                            <div>{line.productType.nameNl}</div>
                            <div>
                              {formatNumber(line.amount, 2, {
                                decimalSeperator: ',',
                              })}
                            </div>
                            <div>
                              €{' '}
                              {formatNumber(line.unitPrice, 2, {
                                decimalSeperator: ',',
                              })}
                            </div>
                            <div>
                              €{' '}
                              {formatNumber((line.amount * line.unitPrice) / 100, 2, {
                                decimalSeperator: ',',
                              })}
                            </div>

                            {!!line.notes && (
                              <div className="col-span-5">
                                <div className="text-sm">Extra informatie: {line.notes}</div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>

                  <div className="bold-label border-b border-dark-04 pb-4 my-4">
                    Order totaal: €{' '}
                    {formatNumber(orderTotals.totalExclVat, 2, {
                      decimalSeperator: ',',
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-2">
          <div></div>
          <div className="grid grid-cols-2 text-md gap-x-4">
            <div className="bold-label">Totaal excl. btw:</div>
            <div>
              €{' '}
              {formatNumber(totals.totalExclVat, 2, {
                decimalSeperator: ',',
              })}
            </div>
            <div className="bold-label">Totaal btw:</div>
            <div>
              €{' '}
              {formatNumber(totals.totalVat, 2, {
                decimalSeperator: ',',
              })}
            </div>
            <div className="bold-label">Totaal incl. btw:</div>
            <div>
              €{' '}
              {formatNumber(totals.totalInclVat, 2, {
                decimalSeperator: ',',
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditNotePage;
