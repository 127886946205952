import { EmailType } from '../../../../generated/graphql';

export const EmailNames = {
  [EmailType.Invoice]: 'Factuur',
  [EmailType.CreditNote]: 'Creditnota',
  [EmailType.OrderConfirmation]: 'Order bevestiging',
  [EmailType.OrderCancelled]: 'Order geannuleerd',
  [EmailType.TransportOrder]: 'Transport order',
  [EmailType.TransportOrderReminder]: 'Transport order herinnering',
  [EmailType.TransportOrderCancellation]: 'Transport order annulering',
  [EmailType.Cmr]: 'CMR',
  [EmailType.CmrReminder]: 'CMR Herinnering',
  [EmailType.UrgentCmrReminder]: 'Dringende CMR Herinnering',
  [EmailType.CmrReminderRegularCarriers]: 'CMR Herinnering vaste vervoerders',
  [EmailType.SelfBilling]: 'Self Billing',
  [EmailType.Quotation]: 'Offerte',
  [EmailType.TruckDocumentExpiration]: 'Trekker document verval herinnering',
  [EmailType.Planning]: 'Planning',
  [EmailType.ForgotPassword]: 'Wachtwoord vergeten',
  [EmailType.UserInvite]: 'Uitnodiging gebruiker',
  [EmailType.DieselSurcharge]: 'Diesel toeslag',
  [EmailType.CustomerPortalRequest]: 'Account aanvraag klantenportaal',
  [EmailType.UnreadNotifications]: 'Ongelezen notificaties',
};
