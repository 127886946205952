import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';

import { useAllGeneralSettings } from '../../all-settings-context';
import {
  EmailType,
  Language,
  useEmailParamsQuery,
  useLinkEmailAttachmentMutation,
  useUnlinkEmailAttachmentMutation,
  useUpdateEmailContentMutation,
} from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { nullthrows } from '../../../../utils/invariant';
import { MultiFileUploaderButton } from '../../../document/components/MultiFileUploaderButton';
import { EmailNames } from './constants';
import { Button } from '../../../../components/button/Button';
import { TemplateTextAreaField } from '../../../../components/textarea/TemplateTextAreaField';

const EmailTypeSettingsPage = () => {
  const { emailType: _emailType } = useParams<{ emailType: string }>();
  const emailType = nullthrows(_emailType, 'Email type is a required route parameter') as EmailType;
  if (!Object.values(EmailType).includes(emailType as EmailType)) {
    throw new Error(`Invalid email type: ${emailType}`);
  }

  const { settings } = useAllGeneralSettings();
  const [_linkDocState, linkDocument] = useLinkEmailAttachmentMutation();
  const [_unlinkDocState, unlinkDocument] = useUnlinkEmailAttachmentMutation();
  const [_updateEmailContentState, updateEmailContent] = useUpdateEmailContentMutation();
  const [_emailParams] = useEmailParamsQuery({
    variables: {
      emailType,
    },
  });

  const emailParams = _emailParams.data?.emailParams ?? [];
  return (
    <div className="grid 2xl:grid-cols-2 gap-4">
      {[Language.Nl, Language.En, Language.Fr, Language.De].map((language) => {
        const subject =
          settings.emailContents.find((c) => c.language === language && c.emailType === emailType)?.subject ?? '';
        const content =
          settings.emailContents.find((c) => c.language === language && c.emailType === emailType)?.content ?? '';

        return (
          <div className="p-4 border-dark-05 border rounded" key={`${emailType}-${language}`}>
            <h2 className="heading-two mb-4">{`${EmailNames[emailType]} - ${language}`}</h2>

            <MultiFileUploaderButton
              buttonText="Bijlagen"
              title={`Bijlagen ${EmailNames[emailType]} - ${language}`}
              files={settings.emailAttachments
                .filter((a) => a.emailType === emailType && a.language === language)
                .map((a) => a.document)}
              onSubmit={async (documents) => {
                let count = 0;
                await Promise.allSettled(
                  documents.map(async (doc) => {
                    try {
                      const result = await linkDocument({
                        emailType,
                        documentId: doc.id,
                        language,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      count += 1;
                      toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                    } catch (err) {
                      toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                    }
                  }),
                );
              }}
              unlinkDocument={async (docId: string) => {
                try {
                  const attachment = nullthrows(
                    settings.emailAttachments.find((a) => a.document.id === docId),
                    'Attachment not found',
                  );
                  const result = await unlinkDocument({
                    id: attachment.id,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Bestand gewist');
                } catch (err) {
                  toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                }
              }}
            />

            <div className="mt-4">
              <h2 className="heading-two mb-2">Inhoud</h2>
              <Formik
                initialValues={{ subject, content }}
                onSubmit={async (values) => {
                  try {
                    const { subject, content } = values;
                    const result = await updateEmailContent({
                      emailType,
                      language,
                      subject,
                      content,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Email inhoud aangepast');
                  } catch (err: any) {
                    toast.error('Kon email inhoud niet aanpassen: ' + getDisplayError(err));
                  }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <TemplateTextAreaField labelText="Onderwerp" name="subject" params={emailParams} rows={1} />
                    <TemplateTextAreaField labelText="Inhoud" name="content" params={emailParams} rows={10} />

                    <Button
                      type="submit"
                      color="primary"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      iconLeft={<PlusIcon className="button-icon" />}
                    >
                      Pas email inhoud aan
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmailTypeSettingsPage;
