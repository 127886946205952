import { IterableElement } from 'type-fest';
import { TrashIcon } from 'lucide-react';
import toast from 'react-hot-toast';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { Customer, useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { useDeleteCustomerUserInviteMutation, useDeactivateCustomerUserMutation } from '../../../generated/graphql';
import { useTranslation } from '../../../contexts/translation-context';

export type CustomerUser = IterableElement<Customer['users']>;
export type CustomerUserInvite = IterableElement<Customer['invitedUsers']>;

export const CustomerPortalUsersPage = () => {
  const { customer } = useCustomerPortalCustomer();
  const [, deactivateCustomerUser] = useDeactivateCustomerUserMutation();
  const [, deleteInvite] = useDeleteCustomerUserInviteMutation();
  const { i18n } = useTranslation();

  return (
    <div>
      <PageHeader title={i18n('customerPortal.users.users')} />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: i18n('customerPortal.users.users'),
            },
          ]}
        />

        {/* <div className="flex justify-end">
          <LinkButton to="invite" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
            {i18n('customerPortal.users.inviteUser')}
          </LinkButton>
        </div> */}
      </div>

      <div>
        <h2 className="heading-two mb-2">{i18n('customerPortal.users.invitedUsers')}</h2>

        {!customer.invitedUsers.length && <div>{i18n('customerPortal.users.noInvitedUsers')}</div>}

        <div className="grid gap-4 grid-cols-2">
          {customer.invitedUsers.map((u) => {
            return (
              <div className="card flex justify-between items-center" key={u.id}>
                <div className="card-heading-text">{u.email}</div>

                <ConfirmDialog
                  triggerText={<TrashIcon className="button-icon" />}
                  title={i18n('customerPortal.users.removeInvite')}
                  submitText={i18n('customerPortal.users.removeInvite')}
                  description={i18n('customerPortal.users.confirmRemoveInvite', {
                    email: u.email,
                  })}
                  onSubmit={async () => {
                    try {
                      const result = await deleteInvite({
                        inviteId: u.id,
                        customerId: customer.id,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success(i18n('customerPortal.users.inviteRemoved'));
                    } catch (err) {
                      toast.error(
                        i18n('customerPortal.users.couldNotRemoveInvite', {
                          error: getDisplayError(err),
                        }),
                      );
                    }
                  }}
                />
              </div>
            );
          })}
        </div>

        <h2 className="heading-two mb-2 mt-8">{i18n('customerPortal.users.users')}</h2>

        {!customer.users.length && <div>{i18n('customerPortal.users.noUsersFound')}</div>}

        <div className="grid grid-cols-3 gap-4">
          {customer.users.map((u) => {
            return (
              <div className="card" key={u.id}>
                <div className="card-heading-text">{u.name}</div>
                <div className="card-subtle-text">{u.email}</div>
                <div className="card-subtle-text">
                  {u.isActive ? i18n('customerPortal.users.active') : i18n('customerPortal.users.inactive')}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
