import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';

import { useAllGeneralSettings } from '../../all-settings-context';
import { DocumentType, Language, useUpdateDocumentNoteMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { nullthrows } from '../../../../utils/invariant';
import { DocumentTypeNames } from './constants';
import { Button } from '../../../../components/button/Button';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';

const DocumentNoteTypeSettingsPage = () => {
  const { docType: _docType } = useParams<{ docType: string }>();
  const documentType = nullthrows(_docType, 'Document type is a required route parameter') as DocumentType;
  if (!Object.values(DocumentType).includes(documentType as DocumentType)) {
    throw new Error(`Invalid document type: ${documentType}`);
  }

  const { settings } = useAllGeneralSettings();
  const [_updateDocumentNoteState, updateDocumentNote] = useUpdateDocumentNoteMutation();

  return (
    <div className="grid 2xl:grid-cols-2 gap-4">
      {[Language.Nl, Language.En, Language.Fr, Language.De].map((language) => {
        const content =
          settings.documentNotes.find((c) => c.language === language && c.documentType === documentType)?.content ?? '';

        return (
          <div className="p-4 border-dark-05 border rounded" key={`${documentType}-${language}`}>
            <h2 className="heading-two mb-4">{`${DocumentTypeNames[documentType]} - ${language}`}</h2>

            <div className="mt-4">
              <h2 className="heading-two mb-2">Inhoud</h2>
              <Formik
                initialValues={{ content }}
                onSubmit={async (values) => {
                  try {
                    const { content } = values;
                    const result = await updateDocumentNote({
                      documentType,
                      language,
                      content,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Document note inhoud aangepast');
                  } catch (err: any) {
                    toast.error('Kon document note inhoud niet aanpassen: ' + getDisplayError(err));
                  }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <TextAreaField labelText="Inhoud" name="content" rows={10} />

                    <Button
                      type="submit"
                      color="primary"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      iconLeft={<PlusIcon className="button-icon" />}
                    >
                      Pas document note inhoud aan
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DocumentNoteTypeSettingsPage;
