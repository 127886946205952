import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { Fragment } from 'react';
import { PlusIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import classNames from '@utils/classnames';

import { useCreatePurchaseMutation } from '../../../../../generated/graphql';
import { formatNumber } from '../../../../../utils/number';
import { FileUploaderButton } from '../../../../document/components/FileUploaderButton';
import { calculateLinesTotalExclVat, calculateLinesTotalVat } from '../../../../order/utils/price';
import { useCreatePurchaseCtx } from './context';
import { formatInputDate } from '../../../../../utils/date';
import { getDisplayError } from '../../../../../utils/get-display-error';
import { Button } from '../../../../../components/button/Button';
import { InputField } from '../../../../../components/input/InputField';
import { nullthrows } from '../../../../../utils/invariant';

const createPurchaseSchema = Yup.object().shape({
  invoiceNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  invoiceDate: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export const PurchaseReview = () => {
  const { state, dispatch } = useCreatePurchaseCtx();
  const navigate = useNavigate();
  const [_createPurchaseRes, createPurchase] = useCreatePurchaseMutation();

  const totalExclVat = state.orders.reduce(
    (acc, order) => acc + calculateLinesTotalExclVat(order.lines.map((l) => l.purchases).flat()),
    0,
  );
  const totalVat = state.orders.reduce(
    (acc, order) => acc + calculateLinesTotalVat(order.lines.map((l) => l.purchases).flat()),
    0,
  );

  const supplier = nullthrows(state.selectedSupplier, 'No supplier selected');

  return (
    <div>
      <div className="mb-4">
        <FileUploaderButton
          buttonText="Upload aankoopfactuur"
          title="Aankoopfactuur"
          file={state.document}
          onSubmit={async (document) => {
            dispatch({
              type: 'UPLOAD_DOC',
              document,
            });
          }}
        />
      </div>

      <Formik
        initialValues={{
          invoiceNumber: '',
          invoiceDate: formatInputDate(new Date()),
        }}
        validationSchema={createPurchaseSchema}
        onSubmit={async (values) => {
          try {
            if (!state.document) {
              throw new Error('Moet een aankoopfactuur uploaden');
            }

            const result = await createPurchase({
              data: {
                orderIds: state.orders.map((o) => o.id),
                invoiceNumber: values.invoiceNumber,
                date: values.invoiceDate,
                documentId: state.document.id,
              },
            });
            if (result.error) {
              throw result.error;
            }
            toast.success('Aankoopfactuur aangemaakt');
            navigate('..');
          } catch (err: any) {
            toast.error('Aankoopfactuur aanmaken mislukt: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Factuur nummer" type="text" name="invoiceNumber" />
              <InputField labelText="Factuur datum" type="date" name="invoiceDate" />

              <div className="my-4 max-w-xl">
                <div className="heading-two mb-2">Orders</div>

                {state.orders.map((order) => {
                  const totalExclVat = calculateLinesTotalExclVat(order.lines.map((l) => l.purchases).flat());

                  return (
                    <div className="mb-2 grid grid-cols-4 gap-x-4 gap-y-1" key={order.id}>
                      <div className="font-medium text-lg col-span-2">{order.orderNumber ?? order.id}</div>
                      <div className="font-medium text-lg whitespace-nowrap">
                        €{' '}
                        {formatNumber(totalExclVat, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>
                      <div className="whitespace-nowrap">
                        €{' '}
                        {formatNumber(totalVat, 2, {
                          decimalSeperator: ',',
                        })}
                      </div>

                      <>
                        {order.lines.map((line) => {
                          return line.purchases.map((purchase) => {
                            return (
                              <Fragment key={`${order.id}-${line.id}-${purchase.id}`}>
                                <div>{purchase.productType.nameNl}</div>
                                <div className="text-right whitespace-nowrap">
                                  {formatNumber(purchase.amount, 2, {
                                    decimalSeperator: ',',
                                  })}{' '}
                                  x €{' '}
                                  {formatNumber(purchase.unitPrice, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div className="font-medium whitespace-nowrap">
                                  €{' '}
                                  {formatNumber((purchase.amount * purchase.unitPrice) / 100, 2, {
                                    decimalSeperator: ',',
                                  })}
                                </div>
                                <div
                                  className={classNames('whitespace-nowrap', {
                                    'text-feedback-negative': purchase.vatRate.id !== supplier.defaultVatRate.id,
                                  })}
                                >
                                  {`€ ${formatNumber(
                                    (purchase.amount * purchase.unitPrice * purchase.vatRatePercentage) / 1000000,
                                    2,
                                    {
                                      decimalSeperator: ',',
                                    },
                                  )} - ${formatNumber(purchase.vatRatePercentage, 2, {
                                    decimalSeperator: ',',
                                  })}%`}
                                </div>

                                {!!purchase.externalNote && (
                                  <div className="col-span-4">
                                    <div className="text-sm">Extra informatie: {purchase.externalNote}</div>
                                  </div>
                                )}
                              </Fragment>
                            );
                          });
                        })}
                      </>

                      <div className="col-span-3 py-1" />
                    </div>
                  );
                })}
              </div>

              <div className="grid grid-cols-2 w-96">
                <div className="font-medium">Totaal excl btw</div>
                <div className="whitespace-nowrap">
                  €{' '}
                  {formatNumber(totalExclVat, 2, {
                    decimalSeperator: ',',
                  })}
                </div>
                <div className="font-medium">Totaal btw</div>
                <div className="whitespace-nowrap">
                  €{' '}
                  {formatNumber(totalVat, 2, {
                    decimalSeperator: ',',
                  })}
                </div>
                <div className="font-medium">Totaal incl btw</div>
                <div className="whitespace-nowrap">
                  €{' '}
                  {formatNumber(totalExclVat + totalVat, 2, {
                    decimalSeperator: ',',
                  })}
                </div>
              </div>

              <div className="mt-4">
                <Button
                  type="submit"
                  color="primary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  iconLeft={<PlusIcon className="button-icon" />}
                >
                  Maak aankoopfactuur aan
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
