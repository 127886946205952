import classNames from '@utils/classnames';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster, resolveValue } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { AppRoutes } from './AppRoutes';
import { ErrorFallback } from './components/ErrorFallback';
import { SpinnerBlock } from './components/Spinner';
import { GraphQLProvider } from './contexts/graphql-context';
import { TranslationProvider } from './contexts/translation-context';

// @ts-ignore
const APP_VERSION = import.meta.env.VITE_APP_VERSION;

export const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(err) => {
        captureException(err);
      }}
      onReset={() => {
        window.location.reload();
      }}
    >
      <TranslationProvider>
        <HelmetProvider>
          <Helmet>
            <link href="/favicon.svg" rel="shortcut icon" type="image/x-icon"></link>
            <meta name="app:version" content={APP_VERSION ?? 'dev'} />
          </Helmet>

          <BrowserRouter>
            <GraphQLProvider>
              <Suspense fallback={<SpinnerBlock message="Loading..." className="h-screen" />}>
                <AppRoutes />

                <Toaster position="bottom-right">
                  {(t) => (
                    <div
                      className={classNames('flex justify-center items-center flex-wrap px-4 py-2 rounded', {
                        'opacity-0': !t.visible,
                        'opacity-100': t.visible,
                        'bg-feedback-negative text-white': t.type === 'error',
                        'bg-green-01 text-white': t.type === 'success',
                        'bg-offwhite text-dark-01 border': t.type !== 'error' && t.type !== 'success',
                      })}
                    >
                      {resolveValue(t.message, t)}
                    </div>
                  )}
                </Toaster>
              </Suspense>
            </GraphQLProvider>
          </BrowserRouter>
        </HelmetProvider>
      </TranslationProvider>
    </ErrorBoundary>
  );
};
