import { useResolvedPath } from 'react-router-dom';

import { EmailType } from '../../../../generated/graphql';
import { ITabItem, Tabs } from '../../../../components/tabs/Tabs';
import { EmailNames } from './constants';

const EmailSettingsPage = () => {
  const tabItems: ITabItem[] = [
    EmailType.Invoice,
    EmailType.CreditNote,
    EmailType.OrderConfirmation,
    EmailType.OrderCancelled,
    EmailType.TransportOrder,
    EmailType.TransportOrderReminder,
    EmailType.TransportOrderCancellation,
    EmailType.Cmr,
    EmailType.UrgentCmrReminder,
    EmailType.CmrReminder,
    EmailType.CmrReminderRegularCarriers,
    EmailType.SelfBilling,
    EmailType.Quotation,
    EmailType.TruckDocumentExpiration,
    EmailType.Planning,
    EmailType.ForgotPassword,
    EmailType.UserInvite,
    EmailType.DieselSurcharge,
    EmailType.CustomerPortalRequest,
    EmailType.UnreadNotifications,
  ].map((v) => {
    return {
      title: EmailNames[v],
      path: useResolvedPath(v).pathname,
    };
  });

  return <Tabs items={tabItems} />;
};

export default EmailSettingsPage;
