import {
  BanknoteIcon,
  BellIcon,
  BellDotIcon,
  CogIcon,
  PieChartIcon,
  InboxIcon,
  MapPinIcon,
  TruckIcon,
  UsersIcon,
  FileIcon,
  EyeIcon,
  CalendarIcon,
  BotIcon,
} from 'lucide-react';
import { Suspense, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from '@utils/classnames';

import { useAuth } from '../../contexts/auth-context';
import { SpinnerBlock } from '../Spinner';
import { useNotificationsCtx } from '../../app/notification/pages/context';
import { useMinimalGeneralSettings } from '../../contexts/minimal-settings-context';
import { Avatar } from '../avatar/Avatar';
import { roleToNumber } from '../../app/auth/roles.client';
import { UserRole } from '../../generated/graphql';

export interface IDashboardProps {
  children: React.ReactNode;
}

export const Dashboard: React.FC<IDashboardProps> = (props) => {
  const { children } = props;
  const { me } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const {
    state: { hasUnreadNotifications },
  } = useNotificationsCtx();
  const { incomingDocCount } = useMinimalGeneralSettings();

  const navigation = useMemo(() => {
    const routes = [];

    if (roleToNumber(me.role) >= roleToNumber(UserRole.Admin)) {
      routes.push({ name: 'Statistieken', to: '/internal/stats', icon: PieChartIcon });
    }

    routes.push(
      { name: 'Orders', to: '/internal/orders', icon: InboxIcon },
      { name: 'Planning', to: '/internal/planning', icon: CalendarIcon },
      { name: 'Klanten', to: '/internal/customers', icon: UsersIcon },
      { name: 'Vervoerders', to: '/internal/suppliers', icon: TruckIcon },
      { name: 'Locaties', to: '/internal/locations', icon: MapPinIcon },
      { name: `Inkomende docs (${incomingDocCount})`, to: '/internal/incoming-docs', icon: FileIcon },
    );

    if (roleToNumber(me.role) >= roleToNumber(UserRole.EmployeePlus)) {
      routes.push({ name: 'Financieel', to: '/internal/finance', icon: BanknoteIcon });
    }

    if (roleToNumber(me.role) >= roleToNumber(UserRole.Admin)) {
      routes.push(
        { name: 'Instellingen', to: '/internal/settings', icon: CogIcon },
        { name: 'Audit Logs', to: '/internal/audit-logs', icon: EyeIcon },
        { name: 'AI Assistant beta', to: '/internal/ai-assistant', icon: BotIcon },
      );
    }

    return routes;
  }, [incomingDocCount]);

  const hideBars = params.get('minimal') === '1';

  const mePageActive = location.pathname.startsWith('/internal/me');
  const notificationsPageActive = location.pathname.startsWith('/internal/notifications');
  const currentRouteIdx = useMemo(() => {
    if (mePageActive || notificationsPageActive) {
      return null;
    }

    let sortedRoutes = navigation
      .map((i, idx) => {
        return {
          ...i,
          idx,
        };
      })
      .sort((a, b) => {
        return b.to.length - a.to.length;
      });

    for (let route of sortedRoutes) {
      if (location.pathname.startsWith(route.to)) {
        return route.idx;
      }
    }

    return null;
  }, [location.pathname]);

  if (hideBars) {
    return (
      <main className="p-6">
        <Suspense fallback={<SpinnerBlock message="Loading..." />}>{children}</Suspense>
      </main>
    );
  } else {
    return (
      <div className="grid h-screen" style={{ gridTemplateColumns: '20vw 1fr' }}>
        <div className="flex flex-col overflow-y-auto">
          <div className="flex flex-grow flex-col bg-dark-01 border-r pt-5 border-box">
            <div className="mb-4">
              <Link to="/internal">
                <img style={{ width: '12rem', height: '5rem' }} className="ml-5" src="/static/logo.svg" />
              </Link>
            </div>

            <div className="sidebar-item">
              <div className="sidebar-item-icon-container">
                <Avatar name={me.name} url={me.image} />
              </div>

              <div className="flex-1 flex justify-between h-full">
                <Link
                  to="/internal/me"
                  className={classNames('flex-1 h-full flex items-center overflow-hidden px-4 hover:bg-orange-02', {
                    'bg-orange-01 text-dark-01': mePageActive,
                  })}
                >
                  {me.name}
                </Link>

                <Link
                  to="/internal/notifications"
                  className={classNames('flex justify-center items-center px-4 hover:bg-orange-02', {
                    'bg-orange-01 text-dark-01': notificationsPageActive,
                  })}
                >
                  <span className="sr-only">Bekijk notificaties</span>
                  {hasUnreadNotifications ? (
                    <BellDotIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </Link>
              </div>
            </div>

            <div className="flex flex-1 flex-col">
              <nav className="flex-1">
                {navigation.map((item, idx) => {
                  const isCurrent = idx === currentRouteIdx;
                  return (
                    <Link
                      key={item.name}
                      to={item.to}
                      data-active={isCurrent ? 'true' : 'false'}
                      className="sidebar-item hoverable-sidebar-item"
                    >
                      <div className="sidebar-item-icon-container">
                        <item.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      </div>
                      <div className="sidebar-item-text-container">{item.name}</div>
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>

        <div className="overflow-y-auto">
          <main className="px-16 py-1 pb-16">
            <Suspense fallback={<SpinnerBlock message="Loading..." />}>{children}</Suspense>
          </main>
        </div>
      </div>
    );
  }
};
