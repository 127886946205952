import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import {
  GetInternalUsersDocument,
  GetInternalUsersQuery,
  GetInternalUsersQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { LinkButton } from '../../../components/button/ButtonLink';

const UsersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetInternalUsersQuery, GetInternalUsersQueryVariables>(
          GetInternalUsersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.internalUsers ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  return (
    <>
      <PageHeader title="Gebruikers" />

      <div className="settings-page-heading">
        <h2 className="heading-two">Gebruikers</h2>

        <div className="flex gap-4">
          <div className="flex w-96">
            <Input
              type="text"
              placeholder="Zoek een gebruiker..."
              value={searchValue}
              onChange={setSearchValue}
              iconLeft={<SearchIcon className="input-icon" />}
            />
          </div>
          <div>
            <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
              Nieuwe gebruiker
            </LinkButton>
          </div>
        </div>
      </div>

      <div className="grid gap-4 grid-cols-3 mb-4">
        {page.data.map((user) => {
          return (
            <Link to={user.id} key={user.id}>
              <div className="card" data-clickable="true">
                <div className="flex justify-between">
                  <div className="card-heading-text">{user.name}</div>
                  <div className="card-subtle-text uppercase">{user.isActive ? 'Actief' : 'Inactief'}</div>
                </div>

                <div>{user.email}</div>

                <div className="card-subtle-text">{user.role}</div>
              </div>
            </Link>
          );
        })}
      </div>

      <Pagination
        hasPrevious={page.hasPrevious}
        previous={page.previous}
        hasNext={page.hasNext}
        next={page.next}
        isFetching={page.isFetching}
      />
    </>
  );
};

export default UsersPage;
