import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from 'lucide-react';
import { Suspense, useEffect, useState } from 'react';

import { Breadcrumb } from '../../../../../components/Breadcrumb';
import { Button } from '../../../../../components/button/Button';
import { PageHeader } from '../../../../../components/PageHeader';
import { Input } from '../../../../../components/input/Input';
import { OrderSelect } from './OrderSelect';
import { CreateInvoiceProvider, useCreateInvoiceCtx } from './context';
import { CustomerSelect } from './CustomerSelect';
import { InvoiceReview } from './Review';

const CreateInvoiceComponent = () => {
  const [searchValue, setSearchValue] = useState('');
  const { state: invoiceState, dispatch } = useCreateInvoiceCtx();

  useEffect(() => {
    setSearchValue('');
  }, [invoiceState.selectedCustomer]);

  let title = '';
  switch (invoiceState.activeWindow) {
    case 'SELECT_CUSTOMER':
      title = 'Selecteer een klant';
      break;
    case 'SELECT_ORDERS':
      title = 'Selecteer order(s)';
      break;
    case 'REVIEW':
      title = 'Review';
      break;
  }

  let searchText = 'search';
  switch (invoiceState.activeWindow) {
    case 'SELECT_CUSTOMER':
      searchText = 'Zoek een klant';
      break;
    case 'SELECT_ORDERS':
      searchText = 'Zoek order(s)';
      break;
  }

  let canGoForward = false;
  if (invoiceState.activeWindow === 'SELECT_CUSTOMER') {
    canGoForward = invoiceState.selectedCustomer !== null;
  } else if (invoiceState.activeWindow === 'SELECT_ORDERS') {
    canGoForward = invoiceState.orders.length > 0;
  }

  return (
    <>
      <PageHeader title="Nieuwe verkoop" />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: 'Verkopen',
              to: '/internal/finance/sales',
            },
            {
              name: 'Nieuwe verkoop',
              to: '/internal/finance/sales/new',
            },
          ]}
        />
      </div>

      <div className="flex justify-between mb-4">
        <h2 className="heading-two self-start">
          {invoiceState.selectedCustomer ? 'Selecteer order(s)' : 'Selecteer een klant'}
        </h2>

        <div className="flex gap-4">
          <div>
            <Button
              iconLeft={<ChevronLeftIcon className="button-icon" />}
              isDisabled={invoiceState.activeWindow === 'SELECT_CUSTOMER'}
              onTrigger={() => {
                dispatch({
                  type: 'GO_BACK',
                });
              }}
            >
              Keer terug
            </Button>
          </div>

          <div>
            <Button
              color="primary"
              iconRight={<ChevronRightIcon className="button-icon" />}
              isDisabled={!canGoForward}
              onTrigger={() => {
                dispatch({
                  type: 'GO_FORWARD',
                });
              }}
            >
              Ga verder
            </Button>
          </div>
        </div>
      </div>

      {invoiceState.activeWindow !== 'REVIEW' && (
        <div className="mb-4">
          <Input
            type="text"
            placeholder={searchText}
            value={searchValue}
            onChange={setSearchValue}
            iconLeft={<SearchIcon className="input-icon" />}
          />
        </div>
      )}

      <Suspense fallback="loading...">
        {invoiceState.activeWindow === 'SELECT_CUSTOMER' && <CustomerSelect searchValue={searchValue} />}
        {invoiceState.activeWindow === 'SELECT_ORDERS' && <OrderSelect searchValue={searchValue} />}
        {invoiceState.activeWindow === 'REVIEW' && <InvoiceReview />}
      </Suspense>
    </>
  );
};

const CreateInvoicePage = () => {
  return (
    <CreateInvoiceProvider>
      <CreateInvoiceComponent />
    </CreateInvoiceProvider>
  );
};

export default CreateInvoicePage;
