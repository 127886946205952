import { CheckIcon, PlusIcon, XIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { PageHeader } from 'components/PageHeader';
import { Pagination } from 'components/pagination/Pagination';
import { SearchType, SearchWithType } from 'components/search-with-type/SearchWithType';
import { StatusText } from 'components/StatusText';
import { ITableHeader, Table } from 'components/table/Table';
import {
  OrderDocumentType,
  OrderLineStopType,
  CustomerPortalOrdersFilterInputData,
  GetCustomerPortalOrdersDocument,
  GetCustomerPortalOrdersQuery,
  GetCustomerPortalOrdersQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDate, formatInputTime } from '../../../utils/date';
import { formatNumber } from '../../../utils/number';
import {
  ORDER_STATUS_COLOR,
  ORDER_STATUS_OPTIONS,
  SALE_STATUS_COLOR,
  SALE_STATUS_OPTIONS,
} from '../../order/constants';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { calculateLinesTotalExclVat } from '../../order/utils/price';
import { LinkButton } from '../../../components/button/ButtonLink';
import { useTranslation } from '../../../contexts/translation-context';

export type CustomerOrder = GetCustomerPortalOrdersQuery['customerPortalOrders'][0];

export const CustomerPortalOrdersPage = () => {
  const client = useClient();
  const { i18n } = useTranslation();

  const searchTypes: SearchType[] = [
    {
      label: i18n('customerPortal.orders.invoiceRef'),
      value: 'customerRef',
    },
    {
      label: i18n('customerPortal.orders.orderRef'),
      value: 'orderNumber',
    },
  ];

  const tableHeaders: ITableHeader[] = [
    {
      id: 'id',
      name: i18n('customerPortal.orders.orderRef'),
    },
    {
      id: 'customerRef',
      name: i18n('customerPortal.orders.invoiceRef'),
    },
    {
      id: 'loadLocation',
      name: i18n('customerPortal.orders.loadLocation'),
    },
    {
      id: 'unloadLocation',
      name: i18n('customerPortal.orders.unloadLocation'),
    },
    {
      id: 'loadDate',
      name: i18n('customerPortal.orders.loadDate'),
    },
    {
      id: 'loadTime',
      name: i18n('customerPortal.orders.loadTime'),
    },
    {
      id: 'unloadDate',
      name: i18n('customerPortal.orders.unloadDate'),
    },
    {
      id: 'unloadTime',
      name: i18n('customerPortal.orders.unloadTime'),
    },
    {
      id: 'saleAmount',
      name: i18n('customerPortal.orders.invoiceTotal'),
    },
    {
      id: 'status',
      name: i18n('customerPortal.orders.status'),
    },
    {
      id: 'saleStatus',
      name: i18n('customerPortal.orders.invoiceStatus'),
    },
    {
      id: 'cmr',
      name: i18n('customerPortal.orders.hasCMR'),
    },
  ];

  // Filter values
  const [search, setSearch] = useState({
    value: '',
    type: searchTypes[0].value,
  });

  const [filters, setFilters] = useState<CustomerPortalOrdersFilterInputData>({});
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomerPortalOrdersQuery, GetCustomerPortalOrdersQueryVariables>(
          GetCustomerPortalOrdersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customerPortalOrders ?? [];
    },
    [client, filters],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [filters]);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      const filters: CustomerPortalOrdersFilterInputData = {};

      const setSearch = (value: string, type: string) => {
        switch (type) {
          case 'orderNumber':
            filters.orderNumber = value;
            break;
          case 'customerRef':
            filters.customerRef = value;
            break;
        }
      };

      if (search.value) {
        setSearch(search.value, search.type);
      }

      setFilters(filters);
    }, 100);
    return () => clearTimeout(timeoutRef);
  }, [search]);

  return (
    <>
      <PageHeader title={i18n('customerPortal.orders.orders')} />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">{i18n('customerPortal.orders.orders')}</h1>

          <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
            {i18n('customerPortal.orders.placeOrder')}
          </LinkButton>
        </div>

        <div className="flex flex-wrap justify-end items-end gap-4 mb-4">
          <div className="flex-1">
            <SearchWithType
              value={search.value}
              onChange={(val) => setSearch((prev) => ({ ...prev, value: val }))}
              types={searchTypes}
              selectedType={search.type}
              onTypeChange={(val) => setSearch((prev) => ({ ...prev, type: val }))}
            />
          </div>
        </div>

        <Table
          idKey="id"
          headers={tableHeaders}
          data={page.data}
          mapData={(order) => {
            const orderStatusColor = ORDER_STATUS_COLOR[order.status] ?? 'black';
            const orderStatusText = i18n(`orderStatus.${order.status}`) ?? order.status;
            const saleStatusColor = SALE_STATUS_COLOR[order.saleStatus] ?? 'black';
            const saleStatusText = i18n(`orderSaleStatus.${order.saleStatus}`) ?? order.saleStatus;
            const loadStops = order.lines.map((v) => v.stops.filter((s) => s.type === OrderLineStopType.Load)).flat();
            const unloadStops = order.lines
              .map((v) => v.stops.filter((s) => s.type === OrderLineStopType.Unload))
              .flat();
            const loadDates = Array.from(new Set(loadStops.map((s) => formatDate(s.date)))).join(', ');
            const unloadDates = Array.from(new Set(unloadStops.map((s) => formatDate(s.date)))).join(', ');
            const loadTimes = Array.from(new Set(loadStops.map((s) => formatInputTime(s.timeStart)))).join(', ');
            const unloadTimes = Array.from(new Set(unloadStops.map((s) => formatInputTime(s.timeEnd)))).join(', ');
            const lineSales = order.lines.map((l) => l.sales).flat();
            const saleTotal = calculateLinesTotalExclVat(lineSales);

            return [
              <Link to={order.id} className="link-text whitespace-nowrap">
                {order.orderNumber ?? 'DRAFT'}
              </Link>,
              order.customerRef,
              [
                ...new Set(
                  order.lines
                    .map((l) =>
                      l.stops
                        .filter((s) => s.type === OrderLineStopType.Load)
                        .map((s) => s.location.city)
                        .flat(),
                    )
                    .flat(),
                ),
              ].join(', '),
              [
                ...new Set(
                  order.lines
                    .map((l) =>
                      l.stops
                        .filter((s) => s.type === OrderLineStopType.Unload)
                        .map((s) => s.location.city)
                        .flat(),
                    )
                    .flat(),
                ),
              ].join(', '),
              loadDates,
              loadTimes,
              unloadDates,
              unloadTimes,
              <div className="whitespace-nowrap">{`€ ${formatNumber(saleTotal, 2, {
                decimalSeperator: ',',
              })}`}</div>,
              <StatusText color={orderStatusColor}>{orderStatusText}</StatusText>,
              <StatusText color={saleStatusColor}>{saleStatusText}</StatusText>,
              order.documents.find((d) => d.type === OrderDocumentType.Cmr) ? (
                <CheckIcon className="w-6 h-6 text-feedback-positive" />
              ) : (
                <XIcon className="w-6 h-6 text-feedback-negative" />
              ),
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
