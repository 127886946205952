import { PlusIcon, StarIcon } from 'lucide-react';
import classNames from '@utils/classnames';
import * as Dialog from '@radix-ui/react-dialog';

import { DataField } from '../../../components/DataField';
import { useState } from 'react';
import { useRateOrderMutation } from '../../../generated/graphql';
import { RatingInput } from './RatingInput';
import { Button } from '../../../components/button/Button';
import toast from 'react-hot-toast';
import { getDisplayError } from '../../../utils/get-display-error';

export interface IRatingDatafieldProps {
  order: {
    id: string;
    rating: number;
  };
}

export const RatingDatafield: React.FC<IRatingDatafieldProps> = (props) => {
  const { order } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [rateState, rateOrder] = useRateOrderMutation();
  const [newRating, setNewRating] = useState(order.rating);

  return (
    <DataField title="Rating">
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Trigger asChild disabled={rateState.fetching}>
          <div className="flex cursor-pointer">
            {new Array(5).fill('').map((_v, idx) => {
              return (
                <StarIcon
                  className={classNames('w-4 h-4', {
                    'text-orange-02 fill-orange-02': idx < order.rating,
                    'text-dark-04': idx >= order.rating,
                  })}
                  key={`${order.id}-${idx}`}
                />
              );
            })}
          </div>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay className="dialog-overlay" />
          <Dialog.Content className="dialog-content">
            <Dialog.Title className="heading-one mb-4">Rate Order</Dialog.Title>

            <RatingInput value={newRating} onChange={setNewRating} />

            <div className="mt-8">
              <Button
                color="primary"
                iconLeft={<PlusIcon className="button-icon" />}
                onTrigger={async () => {
                  try {
                    const result = await rateOrder({
                      orderId: order.id,
                      rating: newRating,
                    });
                    if (result.error) {
                      throw result.error;
                    }
                    toast.success('Rating aangepast');
                    setIsOpen(false);
                  } catch (err) {
                    toast.error('Kon rating niet aanpassen: ' + getDisplayError(err));
                  }
                }}
              >
                Pas rating aan
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </DataField>
  );
};
