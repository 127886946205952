import { useParams } from 'react-router-dom';

import { PageHeader } from '../../../../components/PageHeader';
import {
  GetDocumentLinkDocument,
  GetDocumentLinkQuery,
  GetDocumentLinkQueryVariables,
  useGetIncomingOrderQuery,
} from '../../../../generated/graphql';
import { invariant, nullthrows } from '../../../../utils/invariant';
import { Button } from '../../../../components/button/Button';
import { DownloadIcon } from 'lucide-react';
import { useClient } from 'urql';
import { useState } from 'react';

const IncomingOrderPage = () => {
  const client = useClient();
  const { orderEmailId } = useParams<{ orderEmailId: string }>();
  invariant(orderEmailId);
  const [{ data }] = useGetIncomingOrderQuery({
    variables: {
      id: orderEmailId,
    },
  });
  const email = nullthrows(data?.incomingOrder, 'IncomingOrder not found');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PageHeader title={email.subject} />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Binnengekomen Order</h1>
        </div>

        <div>
          <div>Van: {email.from}</div>
          <div>Onderwerp: {email.subject}</div>
          {email.attachments.length > 0 && (
            <div className="my-2">
              <div className="font-medium mb-1">Bijlagen</div>
              <div className="flex flex-wrap gap-2">
                {email.attachments.map((attachment) => {
                  const document = attachment.document;
                  return (
                    <Button
                      key={attachment.id}
                      iconLeft={<DownloadIcon className="button-icon" />}
                      isLoading={isLoading}
                      onTrigger={async () => {
                        setIsLoading(true);
                        try {
                          const result = await client
                            .query<GetDocumentLinkQuery, GetDocumentLinkQueryVariables>(GetDocumentLinkDocument, {
                              id: document.id,
                            })
                            .toPromise();
                          if (result.error) {
                            throw result.error;
                          }

                          window.open(result.data?.documentLink!, '_blank');
                        } catch (err) {
                          console.error(err);
                        }
                        setIsLoading(false);
                      }}
                    >
                      {attachment.document.name}
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
          <div className="mt-4 whitespace-pre-line">
            <div className="font-medium">Inhoud</div>
            <div>{email.content.trim()}</div>
          </div>
        </div>

        <div className="my-4">
          <div className="heading-two mb-2">Prediction</div>
          <pre className="bg-dark-05 rounded p-2">
            {email.aiPrediction ? JSON.stringify(JSON.parse(email.aiPrediction), null, 2) : 'Geen prediction'}
          </pre>
        </div>
      </div>
    </>
  );
};

export default IncomingOrderPage;
