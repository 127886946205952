import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '../../../../components/Breadcrumb';
import { Button } from '../../../../components/button/Button';
import { InputField } from '../../../../components/input/InputField';
import { PageHeader } from '../../../../components/PageHeader';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';
import {
  CustomerType,
  InvoiceDeliveryMethod,
  InvoiceFrequency,
  useCreateCustomerMutation,
  CreateCustomerInputData,
  Country,
  Language,
} from '../../../../generated/graphql';
import { COUNTRY_VALUES } from '../../../../utils/address';
import { getDisplayError } from '../../../../utils/get-display-error';
import { nullthrows } from '../../../../utils/invariant';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { CargoTypeComboboxField, cargoTypeToComboboxItem } from '../../../order/cargoType/CargoTypeComboboxField';
import { CUSTOMER_TYPE_VALUES, INVOICE_DELIVERY_METHOD_VALUES, INVOICE_FREQUENCY_VALUES } from '../../select-values';
import { AutocompletePostalcode } from '../../../location/components/AutocompletePostalcode';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../../order/vatRate/VatRateComboboxField';
import { TrailerTypes } from '../../../order/pages/order/TrailerTypes';
import { Customer } from '../../CustomerComboboxField';
import { parseNumberInput } from '../../../../utils/number';

const createCustomerSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  type: Yup.mixed().nullable().required('Vereist'),
  vatNumber: Yup.string().vatNumber().required('Vereist'),
  companyNumber: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().nullable().required('Vereist'),
  language: Yup.mixed().nullable().required('Vereist'),
  paymentTerm: Yup.string().required('Vereist'),
  invoiceFrequency: Yup.mixed().nullable().required('Vereist'),
  invoiceDeliveryMethod: Yup.mixed().nullable().required('Vereist'),
  defaultCargoType: Yup.mixed().nullable().required('Vereist'),
  defaultVatRate: Yup.mixed().nullable().required('Vereist'),
  sendCMRWithInvoice: Yup.boolean().required('Vereist'),
  autoInvoicing: Yup.boolean().required('Vereist'),
  dieselSurchargeEnabled: Yup.boolean().required('Vereist'),
  hasCustomDieselSurcharge: Yup.boolean().required('Vereist'),
  hasSelfBilling: Yup.boolean().required('Vereist'),
});

const CreateCustomerPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { settings } = useMinimalGeneralSettings();
  const [, createCustomerMutation] = useCreateCustomerMutation();
  const [params] = useSearchParams();

  const initialValues = {
    name: '',
    vatNumber: '',
    companyNumber: '',
    street: '',
    streetNumber: '',
    city: '',
    postalCode: '',
    country: COUNTRY_VALUES.find((v) => v.key === Country.Be)!,
    language: LANGUAGE_VALUES.find((v) => v.key === Language.Nl)!,
    paymentTerm: '30',
    invoiceFrequency: INVOICE_FREQUENCY_VALUES[0],
    invoiceDeliveryMethod: INVOICE_DELIVERY_METHOD_VALUES[0],
    internalNotes: '',
    externalNotes: '',
    type: CUSTOMER_TYPE_VALUES[0],
    defaultCargoType: settings.defaultCargoType ? cargoTypeToComboboxItem(settings.defaultCargoType) : null,
    defaultVatRate: settings.defaultVatRate ? vatRateToComboboxItem(settings.defaultVatRate) : null,
    sendCMRWithInvoice: false,
    autoInvoicing: false,
    dieselSurchargeEnabled: false,
    hasCustomDieselSurcharge: false,
    defaultTrailerTypes: [],
    cmrReminderOverwrite: '',
    dieselSurchargePercentage: '0',
    hasSelfBilling: false,
  };

  return (
    <>
      <PageHeader title="Nieuwe klant" />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Klanten',
                to: '/internal/customers',
              },
              {
                name: 'Nieuwe klant',
                to: '/internal/customers/new',
              },
            ]}
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={createCustomerSchema}
          onSubmit={async (values) => {
            try {
              const { defaultCargoType, defaultVatRate, ...otherValues } = values;
              const inputData: CreateCustomerInputData = {
                ...otherValues,
                country: values.country.key as Country,
                language: values.language.key as Language,
                paymentTerm: +values.paymentTerm,
                invoiceFrequency: values.invoiceFrequency.key as InvoiceFrequency,
                invoiceDeliveryMethod: values.invoiceDeliveryMethod.key as InvoiceDeliveryMethod,
                type: values.type.key as CustomerType,
                defaultCargoTypeId: nullthrows(defaultCargoType?.key, 'Standaard cargo type is vereist'),
                defaultVatRateId: nullthrows(defaultVatRate?.key, 'Standaard btw type is vereist'),
                cmrReminderOverwrite: values.cmrReminderOverwrite ? +values.cmrReminderOverwrite : null,
                dieselSurchargePercentage:
                  values.dieselSurchargePercentage && !values.dieselSurchargeEnabled
                    ? parseNumberInput(values.dieselSurchargePercentage, 2)
                    : 0,
              };

              if (!inputData.dieselSurchargeEnabled) {
                inputData.dieselSurchargePercentage = 0;
                inputData.hasCustomDieselSurcharge = false;
              }

              if (!inputData.hasCustomDieselSurcharge) {
                inputData.dieselSurchargePercentage = 0;
              }

              const result = await createCustomerMutation({
                data: inputData,
              });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                const popupId = params.get('popup-id');
                if (popupId) {
                  const data: Customer = result.data.createCustomer;
                  window.opener?.postMessage(
                    {
                      id: popupId,
                      type: 'created-customer',
                      data,
                    },
                    '*',
                  );
                }
                navigate(`../${result.data.createCustomer.id}/general${search}`);
              }
              toast.success('Klant aangemaakt');
            } catch (err: any) {
              toast.error('Kon klant niet aanmaken: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Naam" type="text" name="name" />
              <SimpleSelectField labelText="Type klant" items={CUSTOMER_TYPE_VALUES} name="type" />
              <InputField labelText="BTW Nummer" type="text" name="vatNumber" />
              <InputField labelText="Ondernemingsnummer" type="text" name="companyNumber" />
              <div className="flex gap-4">
                <div style={{ flex: 4 }}>
                  <InputField labelText="Straat" type="text" name="street" />
                </div>
                <div style={{ flex: 1 }}>
                  <InputField labelText="Nr" type="text" name="streetNumber" />
                </div>
              </div>
              <AutocompletePostalcode />
              <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />

              <h2 className="heading-two mb-2">Facturatie</h2>
              <InputField labelText="Betaaltermijn" type="number" name="paymentTerm" />
              <SimpleSelectField
                labelText="Factuur frequentie"
                items={INVOICE_FREQUENCY_VALUES}
                name="invoiceFrequency"
              />
              <SimpleSelectField
                labelText="Factuur levering"
                items={INVOICE_DELIVERY_METHOD_VALUES}
                name="invoiceDeliveryMethod"
              />
              <InputField labelText="CMR Herinnering overwrite" type="number" name="cmrReminderOverwrite" step="1" />
              <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
              <TextAreaField labelText="Externe notities (voor vervoerder)" name="externalNotes" spellCheck={true} />

              <div className="grid grid-cols-2 gap-x-2">
                <CheckboxField labelText="Automatische facturatie" name="autoInvoicing" />
                <CheckboxField labelText="CMRs bij facturatie email" name="sendCMRWithInvoice" />
                <CheckboxField labelText="Self billing" name="hasSelfBilling" />
              </div>

              <h2 className="heading-two mb-2">Diesel toeslag</h2>
              <CheckboxField labelText="Gebruikt diesel toeslag" name="dieselSurchargeEnabled" />
              {values['dieselSurchargeEnabled'] && (
                <div>
                  <CheckboxField
                    labelText="Eigen diesel toeslag"
                    name="hasCustomDieselSurcharge"
                    isDisabled={!values['dieselSurchargeEnabled']}
                  />
                  <InputField
                    labelText="Eigen diesel toeslag"
                    type="number"
                    name="dieselSurchargePercentage"
                    step="0.01"
                    isDisabled={!values['dieselSurchargeEnabled'] || !values['hasCustomDieselSurcharge']}
                  />
                </div>
              )}

              <h2 className="heading-two mb-2">Standaard waarden</h2>
              <CargoTypeComboboxField labelText="Standaard inhoud type" name="defaultCargoType" />
              <VatRateComboboxField labelText="Standaard BTW Tarief" name="defaultVatRate" />

              <h2 className="heading-two mb-2">Standaard Trailer types</h2>

              <div className="my-8">
                <TrailerTypes
                  value={values.defaultTrailerTypes}
                  onChange={(newTrailerTypes) => {
                    setFieldValue('defaultTrailerTypes', newTrailerTypes);
                  }}
                  isMultiSelect={true}
                />
              </div>

              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                iconLeft={<PlusIcon className="button-icon" />}
              >
                Maak klant aan
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateCustomerPage;
