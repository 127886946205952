import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { PlusIcon } from 'lucide-react';
import toast from 'react-hot-toast';

import { Button } from '../../../../components/button/Button';
import { useAllGeneralSettings } from '../../all-settings-context';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { InputField } from '../../../../components/input/InputField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { MailboxTypeSelectOptions } from './constants';
import { MailboxType, useCreateMailboxMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';

const newMailboxSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  type: Yup.mixed().nullable().required('Vereist'),
  imapHost: Yup.string().min(1, 'Vereist').required('Vereist'),
  imapPort: Yup.string().matches(/\d+/, 'Moet een getal zijn').min(1, 'Vereist').required('Vereist'),
  imapTls: Yup.boolean(),
  imapUser: Yup.string().min(1, 'Vereist').required('Vereist'),
  imapPassword: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export const ConfigureMailboxPage = () => {
  const navigate = useNavigate();
  const { refreshData } = useAllGeneralSettings();
  const [_createMailboxResult, createMailbox] = useCreateMailboxMutation();

  const initialValues = useMemo(() => {
    return {
      type: MailboxTypeSelectOptions[0],
      name: '',
      imapHost: '',
      imapPort: '',
      imapUser: '',
      imapPassword: '',
      imapTls: true,
    };
  }, []);

  return (
    <div>
      <h2 className="heading-two">Configureer nieuwe mailbox</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={newMailboxSchema}
        onSubmit={async (values) => {
          try {
            const result = await createMailbox({
              data: {
                ...values,
                type: values.type.key as MailboxType,
                imapPort: +values.imapPort,
              },
            });
            if (result.error) {
              throw result.error;
            }
            refreshData();
            toast.success('Mailbox aangemaakt');
            navigate('..');
          } catch (err) {
            toast.error('Kon mailbox niet aanmaken: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <SimpleSelectField labelText="Type" items={MailboxTypeSelectOptions} name="type" />

            <InputField labelText="Naam" type="text" name="name" />
            <InputField labelText="IMAP User" type="text" name="imapUser" />
            <InputField labelText="IMAP Password" type="password" name="imapPassword" />

            <InputField labelText="IMAP Host" type="text" name="imapHost" />
            <InputField labelText="IMAP Port" type="number" name="imapPort" />
            <CheckboxField labelText="IMAP TLS" name="imapTls" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<PlusIcon className="button-icon" />}
            >
              Maak mailbox aan
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
