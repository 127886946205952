import { CalendarDaysIcon, ClockIcon } from 'lucide-react';
import { PageHeader } from 'components/PageHeader';
import { GetInvoiceQuery, OrderLineStopType, useGetCustomerPortalInvoiceQuery } from 'generated/graphql';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { invariant, nullthrows } from '@utils/invariant';

import { Breadcrumb } from 'components/Breadcrumb';
import { DataField } from 'components/DataField';
import { formatDate, formatInputTime } from '../../../utils/date';
import { calculateInvoiceTotals } from '../../sale/utils/calculate';
import { formatNumber } from '../../../utils/number';
import { useTranslation } from '../../../contexts/translation-context';

export type Invoice = NonNullable<GetInvoiceQuery['invoice']>;

export const CustomerPortalInvoicePage = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  invariant(invoiceId);
  const { i18n } = useTranslation();
  const [{ data }] = useGetCustomerPortalInvoiceQuery({
    variables: {
      id: invoiceId,
    },
  });

  const invoice = nullthrows(data?.customerPortalInvoice, i18n('customerPortal.invoices.notFound'));
  const totals = calculateInvoiceTotals(
    invoice.orders
      .map((o) =>
        o.lines
          .map((l) =>
            l.sales.map((s) => {
              return {
                ...s,
                vatRateId: s.vatRate.id,
              };
            }),
          )
          .flat(),
      )
      .flat(),
  );

  const title = i18n('customerPortal.invoices.invoice', {
    invoiceNumber: invoice.invoiceNumber ?? 'DRAFT',
  });
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: i18n('customerPortal.invoices.invoices'),
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />
        </div>

        <div className="flex flex-wrap gap-6 mb-4">
          <DataField title={i18n('customerPortal.invoices.structuredComment')}>{invoice.structuredComment}</DataField>
          <DataField title={i18n('customerPortal.invoices.invoiceDate')}>{formatDate(invoice.date)}</DataField>
          {!!invoice.customerRef && (
            <DataField title={i18n('customerPortal.invoices.reference')}>{invoice.customerRef}</DataField>
          )}
          {invoice.sentAt && (
            <DataField title={i18n('customerPortal.invoices.sentAt')}>{formatDate(invoice.sentAt)}</DataField>
          )}
          {invoice.sentAt && (
            <DataField title={i18n('customerPortal.invoices.dueDate')}>{formatDate(invoice.expiresAt)}</DataField>
          )}
        </div>

        <div>
          {invoice.orders.map((order) => {
            const orderTotals = calculateInvoiceTotals(
              order.lines
                .map((l) =>
                  l.sales.map((s) => {
                    return {
                      ...s,
                      vatRateId: s.vatRate.id,
                    };
                  }),
                )
                .flat(),
            );

            return (
              <div key={`order-${order.orderNumber}`}>
                <div className="flex justify-between">
                  <Link to={`../../orders/${order.id}`} className="heading-two mb-4">
                    Order {order.orderNumber} - {order.customerRef}
                  </Link>
                </div>

                <div>
                  {order.lines.map((line) => {
                    return (
                      <div key={`line-${line.id}`}>
                        <div>
                          {line.stops
                            .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                            .map((stop) => {
                              return (
                                <div className="grid grid-cols-4" key={`line-${line.id}-${stop.id}`}>
                                  <div className="font-medium">
                                    {stop.type === OrderLineStopType.Load
                                      ? i18n('customerPortal.invoices.load')
                                      : i18n('customerPortal.invoices.unload')}
                                  </div>
                                  <div>
                                    {stop.location.country}-{stop.location.name}
                                  </div>

                                  <div className="flex gap-2 items-center">
                                    <CalendarDaysIcon className="w-4 h-4" />
                                    {formatDate(stop.date)}
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <ClockIcon className="w-4 h-4" />
                                    {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                                  </div>
                                </div>
                              );
                            })}
                        </div>

                        <div className="border-t py-4 my-4 border-dark-04">
                          <div className="grid grid-cols-5 gap-x-4 mb-1">
                            <div className="bold-label">{i18n('customerPortal.invoices.description')}</div>
                            <div className="bold-label">{i18n('customerPortal.invoices.amount')}</div>
                            <div className="bold-label">{i18n('customerPortal.invoices.unitPrice')}</div>
                            <div className="bold-label">{i18n('customerPortal.invoices.total')}</div>
                            <div className="bold-label">{i18n('customerPortal.invoices.vat')}</div>
                          </div>

                          <div className="grid grid-cols-5 gap-x-4 gap-y-1">
                            {line.sales.map((sale) => {
                              return (
                                <React.Fragment key={`sale-${sale.id}`}>
                                  <div>{sale.productType.nameNl}</div>
                                  <div className="whitespace-nowrap">
                                    {formatNumber(sale.amount, 2, {
                                      decimalSeperator: ',',
                                    })}
                                  </div>
                                  <div className="whitespace-nowrap">
                                    €{' '}
                                    {formatNumber(sale.unitPrice, 2, {
                                      decimalSeperator: ',',
                                    })}
                                  </div>
                                  <div className="whitespace-nowrap">
                                    €{' '}
                                    {formatNumber((sale.amount * sale.unitPrice) / 100, 2, {
                                      decimalSeperator: ',',
                                    })}
                                  </div>
                                  <div className="whitespace-nowrap">
                                    €{' '}
                                    {formatNumber(
                                      (sale.amount * sale.unitPrice * sale.vatRatePercentage) / 1000000,
                                      2,
                                      {
                                        decimalSeperator: ',',
                                      },
                                    )}{' '}
                                    -{' '}
                                    {formatNumber(sale.vatRatePercentage, 2, {
                                      decimalSeperator: ',',
                                    })}
                                    %
                                  </div>

                                  {!!sale.externalNote && (
                                    <div className="col-span-5">
                                      <div className="text-sm">
                                        {i18n('customerPortal.invoices.extraInformation', {
                                          extraInformation: sale.externalNote,
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="bold-label border-b border-dark-04 pb-4 my-4 whitespace-nowrap">
                    {i18n('customerPortal.invoices.orderTotal', {
                      total: formatNumber(orderTotals.totalExclVat, 2, {
                        decimalSeperator: ',',
                      }),
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-2">
          <div></div>
          <div className="grid grid-cols-2 text-md gap-x-4">
            <div className="bold-label">{i18n('customerPortal.invoices.totalExclVat')}</div>
            <div className="whitespace-nowrap">
              €{' '}
              {formatNumber(totals.totalExclVat, 2, {
                decimalSeperator: ',',
              })}
            </div>
            <div className="bold-label">{i18n('customerPortal.invoices.totalVat')}</div>
            <div className="whitespace-nowrap">
              €{' '}
              {formatNumber(totals.totalVat, 2, {
                decimalSeperator: ',',
              })}
            </div>
            <div className="bold-label">{i18n('customerPortal.invoices.totalInclVat')}</div>
            <div className="whitespace-nowrap">
              €{' '}
              {formatNumber(totals.totalInclVat, 2, {
                decimalSeperator: ',',
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
