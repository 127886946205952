import { ArrowDownIcon, ArrowUpIcon, CalendarDaysIcon, ClockIcon, TagIcon, TrashIcon } from 'lucide-react';
import diff from 'object-diff';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { IterableElement } from 'type-fest';
import { Button } from '../../../../components/button/Button';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import {
  OrderLineStopType,
  useCreateOrderLineStopMutation,
  useDeleteOrderLineStopMutation,
  useMoveOrderLineStopMutation,
  useUpdateOrderLineStopMutation,
} from '../../../../generated/graphql';
import { formatDate, formatInputTime } from '../../../../utils/date';
import { getDisplayError } from '../../../../utils/get-display-error';
import { useOrderLine } from '../orderLine/orderLineContext';
import { OrderLine } from '../orderLine/OrderLines';
import CreateOrderLineStopForm from './CreateOrderLineStop';
import UpdateOrderLineStopForm, { INormalizedOrderLineStopValues } from './UpdateOrderLineStop';
import { StopWarnings } from '../../components/StopsVerification';

export enum ActiveScreen {
  List,
  Create,
  Update,
}

export type ScreenState =
  | {
      activeScreen: ActiveScreen.Create | ActiveScreen.List;
    }
  | {
      activeScreen: ActiveScreen.Update;
      stop: IterableElement<OrderLine['stops']>;
    };

export interface ICreateStopProps {
  goBack: () => void;
}

const CreateStop: React.FC<ICreateStopProps> = (props) => {
  const { goBack } = props;
  const { orderLine, refreshData: refetchOrder } = useOrderLine();
  const [, createStopMutation] = useCreateOrderLineStopMutation();
  const sortedStops = orderLine.stops.sort((a, b) => a.sequenceIndex - b.sequenceIndex);

  return (
    <CreateOrderLineStopForm
      initialType={sortedStops.length ? OrderLineStopType.Unload : OrderLineStopType.Load}
      initialDate={sortedStops[sortedStops.length - 1]?.date}
      onComplete={async (values) => {
        try {
          const { location, ...otherValues } = values;
          const result = await createStopMutation({
            orderLineId: orderLine.id,
            data: {
              ...otherValues,
              sequenceIndex: orderLine.stops.length * 10,
              locationId: location.id,
            },
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            refetchOrder();
            goBack();
          }
          toast.success('Stop aangemaakt');
        } catch (err: any) {
          toast.error('Kon stop niet aanmaken: ' + getDisplayError(err));
        }
      }}
      onCancel={() => {
        goBack();
      }}
    />
  );
};

export interface IUpdateStopProps {
  stopId: number;
  initialValues: INormalizedOrderLineStopValues;
  goBack: () => void;
}

const UpdateStop: React.FC<IUpdateStopProps> = (props) => {
  const { stopId, initialValues, goBack } = props;
  const { refreshData: refetchOrder } = useOrderLine();
  const [, updateStop] = useUpdateOrderLineStopMutation();

  return (
    <UpdateOrderLineStopForm
      initialValue={initialValues}
      onComplete={async (values) => {
        try {
          const { location, ...otherValues } = diff(initialValues, values);
          const result = await updateStop({
            id: stopId,
            data: {
              ...otherValues,
              locationId: location ? location.id : undefined,
            },
          });
          if (result.error) {
            throw result.error;
          }
          if (result.data) {
            refetchOrder();
            goBack();
          }
          toast.success('Stop aangepast');
        } catch (err: any) {
          toast.error('Kon stop niet aanpassen: ' + getDisplayError(err));
        }
      }}
      onCancel={() => {
        goBack();
      }}
    />
  );
};

export const OrderLineStops = () => {
  const { orderLine } = useOrderLine();
  const [screenState, setScreenState] = useState<ScreenState>({
    activeScreen: ActiveScreen.List,
  });
  const [, deleteOrderStop] = useDeleteOrderLineStopMutation();
  const [moveStopState, moveOrderLineStop] = useMoveOrderLineStopMutation();
  const sortedStops = orderLine.stops.sort((a, b) => a.sequenceIndex - b.sequenceIndex);

  const moveStop = async (stopId: number, offset: number) => {
    try {
      const result = await moveOrderLineStop({
        orderLineStopId: stopId,
        offset,
      });
      if (result.error) {
        throw result.error;
      }
      toast.success('Stop verplaatst');
    } catch (err: any) {
      toast.error('Kon stop niet verplaatsen: ' + getDisplayError(err));
    }
  };

  switch (screenState.activeScreen) {
    case ActiveScreen.List: {
      return (
        <div>
          <div className="flex justify-between items-center">
            <h2 className="heading-two">Stops</h2>
            <div>
              <Button
                onTrigger={() => {
                  setScreenState({
                    activeScreen: ActiveScreen.Create,
                  });
                }}
              >
                Nieuwe Stop
              </Button>
            </div>
          </div>

          <StopWarnings stops={sortedStops} />

          <div>
            {sortedStops.map((stop, stopIdx) => {
              return (
                <div
                  key={stop.id}
                  className="bg-dark-05 rounded p-4 my-4 hover:bg-orange-01 cursor-pointer"
                  onClick={() => {
                    setScreenState({
                      activeScreen: ActiveScreen.Update,
                      stop,
                    });
                  }}
                >
                  <div className="flex justify-between">
                    <div className="font-medium">{stop.type === OrderLineStopType.Load ? 'Laad' : 'Los'}</div>
                    <div className="flex gap-2">
                      <Button
                        onTrigger={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();

                          moveStop(stop.id, -1);
                        }}
                        isLoading={moveStopState.fetching}
                        isDisabled={stopIdx === 0}
                      >
                        <ArrowUpIcon className="w-4 h-4" />
                      </Button>
                      <Button
                        onTrigger={(evt) => {
                          evt.preventDefault();
                          evt.stopPropagation();

                          moveStop(stop.id, 1);
                        }}
                        isLoading={moveStopState.fetching}
                        isDisabled={stopIdx === sortedStops.length - 1}
                      >
                        <ArrowDownIcon className="w-4 h-4" />
                      </Button>
                      <ConfirmDialog
                        triggerText={<TrashIcon className="button-icon" />}
                        title="Verwijder order stop"
                        submitText="Verwijder"
                        description={
                          <div>{`Ben je zeker dat je volgende order stop ${stop.location.name} wilt wissen?`}</div>
                        }
                        onSubmit={async () => {
                          try {
                            const res = await deleteOrderStop({
                              id: stop.id,
                            });
                            if (res.error) {
                              throw res.error;
                            }
                          } catch (err) {
                            toast.error('Kon contacten niet verwijderen: ' + getDisplayError(err));
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div>{`${stop.location.name}`}</div>
                      <div>{`${stop.location.street} ${stop.location.streetNumber}`}</div>
                      <div>{`${stop.location.country}-${stop.location.postalCode} ${stop.location.city}`}</div>
                    </div>
                    <div>
                      <div className="flex items-center">
                        <TagIcon className="w-4 h-4 mr-2" />
                        <div>{stop.reference}</div>
                      </div>
                      <div className="flex items-center">
                        <CalendarDaysIcon className="w-4 h-4 mr-2" />
                        <div>{formatDate(stop.date)}</div>
                      </div>
                      <div className="flex items-center">
                        <ClockIcon className="w-4 h-4 mr-2" />
                        <div>{`${formatInputTime(stop.timeStart)} - ${formatInputTime(stop.timeEnd)}`}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">{stop.notes}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    case ActiveScreen.Create: {
      return (
        <CreateStop
          goBack={() => {
            setScreenState({
              activeScreen: ActiveScreen.List,
            });
          }}
        />
      );
    }
    case ActiveScreen.Update: {
      const values = {
        location: screenState.stop.location,
        type: screenState.stop.type,
        date: screenState.stop.date,
        timeStart: screenState.stop.timeStart,
        timeEnd: screenState.stop.timeEnd,
        reference: screenState.stop.reference,
        notes: screenState.stop.notes,
      };

      return (
        <UpdateStop
          stopId={screenState.stop.id}
          initialValues={values}
          goBack={() => {
            setScreenState({
              activeScreen: ActiveScreen.List,
            });
          }}
        />
      );
    }
  }
};
