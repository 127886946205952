import { PlusIcon, TrashIcon } from 'lucide-react';
import { Button } from 'components/button/Button';
import { FieldArray, Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { useUpdateGeneralSettingsMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { useAllGeneralSettings } from '../all-settings-context';
import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { cargoTypeToComboboxItem, CargoTypeComboboxField } from '../../order/cargoType/CargoTypeComboboxField';
import { PackageTypeComboboxField, packageTypeToComboboxItem } from '../../order/packageType/PackageTypeComboboxField';
import { ProductTypeComboboxField, productTypeToComboboxItem } from '../../order/productType/ProductTypeComboboxField';
import { InputField } from '../../../components/input/InputField';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../order/vatRate/VatRateComboboxField';
import { formatNumber, parseNumberInput } from '../../../utils/number';

const orderSettingsSchema = Yup.object().shape({
  cargoType: Yup.mixed().nullable().required('Vereist'),
  packageType: Yup.mixed().nullable().required('Vereist'),
  productType: Yup.mixed().nullable().required('Vereist'),
  vatRate: Yup.mixed().nullable().required('Vereist'),
  orderNumber: Yup.string().required('Vereist'),
});

interface IOrderSettingsValues {
  cargoType: ISimpleComboboxItem | null;
  packageType: ISimpleComboboxItem | null;
  productType: ISimpleComboboxItem | null;
  secondProductType: ISimpleComboboxItem | null;
  thirdProductType: ISimpleComboboxItem | null;
  vatRate: ISimpleComboboxItem | null;
  orderNumber: string;
  cmrReminderIntervals: string[];
  cmrAutoApproveTreshold: string;
  truckDocumentReminderInterval: string;
  dieselSurchargePercentage: string;
  dieselSurchargeProductType: ISimpleComboboxItem | null;
  dieselSurchargeBaseProductType: ISimpleComboboxItem | null;
}

const OrderSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [_updateState, updateGeneralSettings] = useUpdateGeneralSettingsMutation();

  const initialValues: IOrderSettingsValues = useMemo(() => {
    return {
      cargoType: settings.defaultCargoType ? cargoTypeToComboboxItem(settings.defaultCargoType) : null,
      packageType: settings.defaultPackageType ? packageTypeToComboboxItem(settings.defaultPackageType) : null,
      productType: settings.defaultProductType ? productTypeToComboboxItem(settings.defaultProductType) : null,
      secondProductType: settings.secondDefaultProductType
        ? productTypeToComboboxItem(settings.secondDefaultProductType)
        : null,
      thirdProductType: settings.thirdDefaultProductType
        ? productTypeToComboboxItem(settings.thirdDefaultProductType)
        : null,
      vatRate: settings.defaultVatRate ? vatRateToComboboxItem(settings.defaultVatRate) : null,
      orderNumber: '' + settings.orderNumber,
      cmrReminderIntervals: settings.cmrReminderIntervals.map((v) => '' + v),
      cmrAutoApproveTreshold: '' + settings.cmrAutoApproveTreshold,
      truckDocumentReminderInterval: '' + settings.truckDocumentReminderInterval,
      dieselSurchargePercentage: formatNumber(settings.dieselSurchargePercentage, 2),
      dieselSurchargeProductType: settings.dieselSurchargeProductType
        ? productTypeToComboboxItem(settings.dieselSurchargeProductType)
        : null,
      dieselSurchargeBaseProductType: settings.dieselSurchargeBaseProductType
        ? productTypeToComboboxItem(settings.dieselSurchargeBaseProductType)
        : null
    };
  }, [settings]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={orderSettingsSchema}
      onSubmit={async (values) => {
        try {
          const patch: Partial<typeof values> = diff(initialValues, values);
          const {
            cargoType,
            packageType,
            productType,
            secondProductType,
            thirdProductType,
            orderNumber,
            vatRate,
            cmrReminderIntervals,
            cmrAutoApproveTreshold,
            truckDocumentReminderInterval,
            dieselSurchargePercentage,
            dieselSurchargeProductType,
            dieselSurchargeBaseProductType,
            ...otherValues
          } = patch;
          const result = await updateGeneralSettings({
            data: {
              defaultCargoTypeId: cargoType?.key,
              defaultPackageTypeId: packageType?.key,
              defaultProductTypeId: productType?.key,
              secondDefaultProductTypeId: secondProductType?.key,
              thirdDefaultProductTypeId: thirdProductType?.key,
              defaultVatRateId: vatRate?.key,
              orderNumber: orderNumber ? +orderNumber : undefined,
              cmrReminderIntervals: cmrReminderIntervals ? cmrReminderIntervals.map((v) => +v) : undefined,
              cmrAutoApproveTreshold: cmrAutoApproveTreshold ? +cmrAutoApproveTreshold : undefined,
              truckDocumentReminderInterval: truckDocumentReminderInterval ? +truckDocumentReminderInterval : undefined,
              dieselSurchargePercentage: dieselSurchargePercentage
                ? parseNumberInput(dieselSurchargePercentage, 2)
                : undefined,
              dieselSurchargeProductTypeId: dieselSurchargeProductType?.key,
              dieselSurchargeBaseProductTypeId: dieselSurchargeBaseProductType?.key,
              ...otherValues,
            },
          });
          if (result.error) {
            throw result.error;
          }
          toast.success('Instellingen aangepast');
        } catch (err: any) {
          toast.error('Kon algemene instellingen niet aanpassen: ' + getDisplayError(err));
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CargoTypeComboboxField labelText="Standaard inhoud" name="cargoType" />

            <PackageTypeComboboxField labelText="Standaard verpakking" name="packageType" />

            <ProductTypeComboboxField labelText="Standaard product type" name="productType" />

            <ProductTypeComboboxField labelText="Tweede standaard product type" name="secondProductType" />

            <ProductTypeComboboxField labelText="Derde standaard product type" name="thirdProductType" />

            <VatRateComboboxField labelText="Standaard btw type" name="vatRate" />

            <InputField labelText="Volgende order nummer" type="number" name="orderNumber" />

            <InputField
              labelText="Vervallen trekker document herinnering email interval"
              type="number"
              name="truckDocumentReminderInterval"
            />

            <InputField
              labelText="Minimum zekerheid voor automatisch goedkeuren CMR"
              type="number"
              name="cmrAutoApproveTreshold"
            />

            <div className="heading-three">Diesel toeslag</div>

            <ProductTypeComboboxField
              labelText="Diesel toeslag basis voor berekening"
              name="dieselSurchargeBaseProductType"
            />

            <ProductTypeComboboxField labelText="Diesel toeslag product" name="dieselSurchargeProductType" />

            <InputField
              labelText="Standaard diesel toeslag percentage"
              step="0.01"
              type="number"
              name="dieselSurchargePercentage"
            />

            <FieldArray
              name="cmrReminderIntervals"
              render={(arrayHelpers) => (
                <div className="my-4">
                  <div className="flex justify-between">
                    <div className="heading-three">CMR herinneringen</div>
                    <Button onTrigger={() => arrayHelpers.push('')}>Voeg interval toe</Button>
                  </div>
                  {values.cmrReminderIntervals.map((interval, index) => (
                    <div className="flex items-center gap-2" key={index}>
                      <InputField
                        labelText={`Herinnering "${index + 1}" na x dagen`}
                        type="number"
                        name={`cmrReminderIntervals.${index}`}
                      />
                      <div>
                        <Button type="button" onTrigger={() => arrayHelpers.remove(index)}>
                          <TrashIcon className="w-4 h-4" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<PlusIcon className="button-icon" />}
            >
              Pas instellingen aan
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default OrderSettingsPage;
