import React, { MouseEvent } from 'react';

import { BaseButton, BaseButtonProps } from './BaseButton';

export interface ButtonProps {
  onTrigger?: (evt: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  color?: BaseButtonProps['color'];
  children?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  width?: string;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  ariaLabel?: string;
}
export type Ref = HTMLButtonElement;

export const Button = React.forwardRef<Ref, ButtonProps>((props, ref) => {
  const {
    onTrigger,
    type = 'button',
    color = 'secondary',
    children,
    isDisabled,
    isLoading,
    width = 'w-auto',
    iconLeft,
    iconRight,
    ariaLabel,
  } = props;

  return (
    <BaseButton
      isLoading={isLoading}
      onClick={onTrigger}
      type={type}
      color={color}
      disabled={isDisabled}
      className={width}
      style={{ height: '2.75rem' }}
      aria-label={ariaLabel}
      iconLeft={iconLeft}
      iconRight={iconRight}
      ref={ref}
    >
      {children}
    </BaseButton>
  );
});
