import { Breadcrumb } from '../../components/Breadcrumb';
import { PageHeader } from '../../components/PageHeader';
import { useTranslation } from '../../contexts/translation-context';
import { useCustomerPortalCustomer } from './CustomerPortalCustomerContext';

export const CustomerPortalHomePage = () => {
  const { customer } = useCustomerPortalCustomer();
  const { i18n } = useTranslation();

  return (
    <div>
      <PageHeader
        title={i18n('customerPortal.home.title', {
          customerName: customer.name,
        })}
      />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: i18n('customerPortal.home.customerPortal'),
            },
          ]}
        />
      </div>

      <p>
        {i18n('customerPortal.home.description', {
          customerName: customer.name,
        })}
      </p>
    </div>
  );
};
