import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { PageHeader } from '../../../../components/PageHeader';
import { Pagination } from '../../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../../components/table/Table';
import {
  GetIncomingOrdersQuery,
  GetIncomingOrdersQueryVariables,
  GetIncomingOrdersDocument,
} from '../../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../../hooks/usePagination';
import { formatDateTime } from '../../../../utils/date';

const ORDERS_HEADERS: ITableHeader[] = [
  {
    id: 'orderId',
    name: 'Order Nr',
  },
  {
    id: 'subject',
    name: 'Onderwerp',
  },
  {
    id: 'from',
    name: 'Van',
  },
  {
    id: 'receivedAt',
    name: 'Ontvangen op',
  },
  {
    id: 'startedProcessingAt',
    name: 'Verwerking gestart op',
  },
  {
    id: 'isProcessed',
    name: 'Is verwerkt?',
  },
];

const IncomingOrdersPage = () => {
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetIncomingOrdersQuery, GetIncomingOrdersQueryVariables>(
          GetIncomingOrdersDocument,
          {
            id: variables.cursor,
            take: variables.take,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.incomingOrders ?? [];
    },
    [client],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  return (
    <>
      <PageHeader title="Orders" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Binnengekomen Orders</h1>
        </div>

        <Table
          idKey="id"
          headers={ORDERS_HEADERS}
          data={page.data}
          mapData={(orderEmail) => {
            const subject = orderEmail.subject.trim();

            return [
              orderEmail.order ? (
                <Link to={`../${orderEmail.order.id}/general`} className="link-text whitespace-nowrap">
                  {orderEmail.order.orderNumber ?? 'DRAFT'}
                </Link>
              ) : (
                'Geen order'
              ),
              <Link to={`${orderEmail.id}`} className="link-text whitespace-nowrap">
                {subject || 'Geen onderwerp'}
              </Link>,
              orderEmail.from,
              formatDateTime(orderEmail.receivedAt),
              formatDateTime(orderEmail.startedProcessingAt),
              orderEmail.isProcessed ? 'Ja' : 'Neen',
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default IncomingOrdersPage;
