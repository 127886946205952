import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { StatusText } from '../../../components/StatusText';
import { ITableHeader, Table } from '../../../components/table/Table';
import { GetInvoicesDocument, GetInvoicesQuery, GetInvoicesQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { calculateInvoiceTotals } from '../utils/calculate';
import { InvoiceStatusToColor, InvoiceStatusToText } from '../utils/status';
import { ExportInvoicesButton } from './ExportSalesButton';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/ButtonLink';

const INVOICES_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Factuur Nummer',
  },
  {
    id: 'customerRef',
    name: 'Klant referentie',
  },
  {
    id: 'date',
    name: 'Factuur Datum',
  },
  {
    id: 'dueDate',
    name: 'Vervaldatum',
  },
  {
    id: 'customer',
    name: 'Klant',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
];

export const SalesInvoicesPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetInvoicesQuery, GetInvoicesQueryVariables>(
          GetInvoicesDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.invoices ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = 'Verkopen';

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Financieel',
                to: '/internal/finance',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek een verkoop..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
            <div>
              <ExportInvoicesButton />
            </div>
            <div>
              <LinkButton to="new-manual" iconLeft={<PlusIcon className="button-icon" />}>
                Nieuwe manuele verkoop
              </LinkButton>
            </div>
            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                Nieuwe verkoop
              </LinkButton>
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={INVOICES_HEADERS}
          data={page.data}
          mapData={(invoice) => {
            const totals = calculateInvoiceTotals([
              ...invoice.orders
                .map((o) =>
                  o.lines
                    .map((l) =>
                      l.sales.map((s) => {
                        return {
                          ...s,
                          vatRateId: s.vatRate.id,
                        };
                      }),
                    )
                    .flat(),
                )
                .flat(),
              ...invoice.manualInvoiceLines.map((v) => {
                return {
                  ...v,
                  vatRateId: v.vatRate.id,
                };
              }),
            ]);

            return [
              <Link to={`${invoice.id}`} className="link-text">
                {invoice.invoiceNumber ?? 'DRAFT'}
              </Link>,
              invoice.customerRef ?? '-',
              formatDate(invoice.date),
              invoice.expiresAt ? formatDate(invoice.expiresAt) : '-',
              invoice.customer.name,
              <StatusText color={InvoiceStatusToColor[invoice.status]}>
                {InvoiceStatusToText[invoice.status]}
              </StatusText>,
              <div className="whitespace-nowrap">{`€ ${formatNumber(totals.totalInclVat, 2, {
                decimalSeperator: ',',
              })}`}</div>,
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
