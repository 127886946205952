import { SearchIcon } from 'lucide-react';
import { createId } from '@paralleldrive/cuid2';
import { useField } from 'formik';
import { useMemo, useState } from 'react';

import { Button } from '../../components/button/Button';
import { Combobox } from '../../components/combobox/Combobox';
import { InputWrapper } from '../../components/InputWrapper';
import { Language, TrailerType, useGetCustomersQuery } from '../../generated/graphql';
import { getFullUrl } from '../../utils/url';
import { openTab } from '../../utils/windows';
import { IMinimalVatRate } from '../order/vatRate/VatRateComboboxField';

export type Customer = {
  id: number;
  name: string;
  deactivationReason?: string | null;
  defaultTrailerTypes: TrailerType[];
  defaultVatRate?: IMinimalVatRate | null | undefined;
  language: Language;
};

type ComboValue = Customer | { id: 'new' };

const displayCustomer = (customer: ComboValue) => {
  if (customer.id === 'new') {
    return 'Maak klant aan';
  } else {
    if (customer.deactivationReason) {
      return `${customer.name} (gedeactiveerd)`;
    } else {
      return customer.name;
    }
  }
};

const customerQueryContext = {
  suspense: false,
};

export interface ICustomerComboboxFieldProps {
  name: string;
  labelText?: string;
  helperText?: string;
  isDisabled?: boolean;
  showDeactivated?: boolean;
}

const createCustomerPopup = (): Promise<Customer> => {
  const popupId = createId();
  const targetUrl = new URL(`/internal/customers/new?minimal=1&popup-id=${popupId}`, window.location.href).href;
  const targetWindow = window.open(targetUrl, 'popup', 'width=800,height=800');
  return new Promise((resolve, reject) => {
    if (targetWindow) {
      window.addEventListener('message', (evt) => {
        const message = evt.data;
        if (typeof message === 'object' && message.id === popupId && message.type === 'created-customer') {
          resolve(message.data);
        }
      });
    } else {
      reject(new Error('Could not spawn popup window'));
    }
  });
};

export function CustomerComboboxField(props: ICustomerComboboxFieldProps) {
  const { name, labelText = 'Klant', helperText, isDisabled, showDeactivated } = props;
  const [searchValue, setSearchValue] = useState('');
  const [{ data }] = useGetCustomersQuery({
    variables: {
      filters: {
        search: searchValue,
      },
    },
    context: customerQueryContext,
  });

  const customers: ComboValue[] = useMemo(() => {
    const values: ComboValue[] = data?.customers
      ? [...data.customers].filter((v) => {
          if (showDeactivated) {
            return true;
          } else {
            return !v.deactivationReason;
          }
        })
      : [];
    values.push({
      id: 'new',
    });
    return values;
  }, [data?.customers, showDeactivated]);

  const [field, meta, helpers] = useField({ name });
  const selectedItem = field.value ?? null;

  const openCustomer = (id: number) => openTab(getFullUrl(`/internal/customers/${id}/general`));
  return (
    <div className="flex gap-2">
      <InputWrapper labelText={labelText} invalidText={meta.touched ? meta.error : undefined} helperText={helperText}>
        <Combobox
          keyName="id"
          placeholder="Zoek een klant..."
          onQueryChange={setSearchValue}
          display={displayCustomer}
          items={customers}
          selectedItem={selectedItem}
          onSelect={(val) => {
            if (val?.id !== 'new') {
              helpers.setValue(val);
            } else {
              createCustomerPopup()
                .then((val) => {
                  helpers.setValue(val);
                })
                .catch(console.error);
            }
          }}
          onBlur={() => helpers.setTouched(true)}
          isDisabled={isDisabled}
          isInvalid={Boolean(meta.touched && meta.error)}
          isNullable={true}
        />
      </InputWrapper>
      <div className="flex items-center">
        <Button
          color="secondary"
          onTrigger={() => {
            if (selectedItem) {
              openCustomer(selectedItem.id);
            }
          }}
          isDisabled={!selectedItem}
        >
          <SearchIcon className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
}
