import { Formik } from 'formik';
import * as Yup from 'yup';
import { PlusIcon } from 'lucide-react';

import { Country, Language } from '../../../generated/graphql';
import { COUNTRY_VALUES } from '../../../utils/address';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { InputField } from '../../../components/input/InputField';
import { AutocompletePostalcode } from '../../location/components/AutocompletePostalcode';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../components/textarea/TextAreaField';
import { Button } from '../../../components/button/Button';
import { useTranslation } from '../../../contexts/translation-context';

const createLocationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'errors.required').required('errors.required'),
  street: Yup.string().min(1, 'errors.required').required('errors.required'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'errors.required').required('errors.required'),
  postalCode: Yup.string().min(1, 'errors.required').required('errors.required'),
  country: Yup.mixed().nullable().required('errors.required'),
  language: Yup.mixed().nullable().required('errors.required'),
});

export interface ICustomerPortalCreateLocationValues {
  name: string;
  street: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  country: Country;
  language: Language;
  internalNotes: string;
}

export interface ICustomerPortalCreateLocationFormProps {
  onCancel?: () => void;
  onSubmit: (values: ICustomerPortalCreateLocationValues) => Promise<void>;
}

export const CustomerPortalCreateLocationForm: React.FC<ICustomerPortalCreateLocationFormProps> = (props) => {
  const { onCancel, onSubmit } = props;
  const { i18n } = useTranslation();

  return (
    <Formik
      initialValues={{
        name: '',
        street: '',
        streetNumber: '',
        city: '',
        postalCode: '',
        country: COUNTRY_VALUES.find((v) => v.key === Country.Be)!,
        language: LANGUAGE_VALUES.find((v) => v.key === Language.Nl)!,
        internalNotes: '',
      }}
      validationSchema={createLocationSchema}
      onSubmit={async (values) => {
        const inputData = {
          ...values,
          country: values.country.key as Country,
          language: values.language.key as Language,
        };
        await onSubmit(inputData);
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField labelText={i18n('customerPortal.locations.name')} type="text" name="name" />
            <div className="flex gap-4">
              <div style={{ flex: 4 }}>
                <InputField labelText={i18n('customerPortal.locations.street')} type="text" name="street" />
              </div>
              <div style={{ flex: 1 }}>
                <InputField labelText={i18n('customerPortal.locations.number')} type="text" name="streetNumber" />
              </div>
            </div>

            <AutocompletePostalcode />

            <SimpleSelectField
              labelText={i18n('customerPortal.locations.language')}
              items={LANGUAGE_VALUES}
              name="language"
            />
            <TextAreaField labelText={i18n('customerPortal.locations.notes')} name="internalNotes" spellCheck={true} />

            <div className="flex justify-between">
              {onCancel && (
                <Button
                  type="button"
                  color="secondary"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  onTrigger={onCancel}
                >
                  {i18n('cancel')}
                </Button>
              )}

              <Button
                type="submit"
                color="primary"
                iconLeft={<PlusIcon className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {i18n('customerPortal.locations.createLocation')}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
