import classNames from '@utils/classnames';
import React from 'react';

import { Spinner } from '../Spinner';

export interface BaseButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: 'secondary' | 'primary';
  isLoading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
}
export type Ref = HTMLButtonElement;

export const BaseButton = React.forwardRef<Ref, BaseButtonProps>((props, ref) => {
  const { isLoading, disabled, color = 'secondary', children, className, iconLeft, iconRight, ...otherProps } = props;

  const colorMap = {
    'text-black bg-dark-04 hover:bg-dark-03': color === 'secondary' && !disabled,
    'text-black bg-orange-00 hover:bg-orange-01': color === 'primary' && !disabled,
    'text-dark-03 bg-dark-05': disabled,
  };

  return (
    <button
      {...otherProps}
      disabled={disabled}
      className={classNames(
        'overflow-hidden relative flex items-center justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg',
        className,
        colorMap,
      )}
      style={{ height: '2.75rem' }}
      ref={ref}
    >
      <div className="w-full flex items-center whitespace-nowrap">
        {iconLeft != null && <div className="p-4 border-r border-white">{iconLeft}</div>}
        <div className="px-4">{children}</div>
        {iconRight != null && <div className="p-4 border-l border-white">{iconRight}</div>}
      </div>
      {isLoading && (
        <div className={classNames('absolute flex items-center justify-center top-0 left-0 w-full h-full', colorMap)}>
          <Spinner size={4} color={color === 'primary' ? 'primary' : 'default'} />
        </div>
      )}
    </button>
  );
});
