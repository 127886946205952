import { Suspense, useEffect, useState } from 'react';

import { Breadcrumb } from '../../../../../components/Breadcrumb';
import { PageHeader } from '../../../../../components/PageHeader';
import { OrderSelect } from './OrderSelect';
import { CustomerSelect } from './CustomerSelect';
import { CreateCreditNoteProvider, useCreateCreditNoteCtx } from './context';
import { Button } from '../../../../../components/button/Button';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from 'lucide-react';
import { CreditNoteLines } from './CreditNoteLines';
import { InvoiceSelect } from './InvoiceSelect';
import { Input } from '../../../../../components/input/Input';

const CreateCreditNoteComponent = () => {
  const { state, dispatch } = useCreateCreditNoteCtx();
  const [searchValue, setSearchValue] = useState('');

  let title = '';
  switch (state.activeWindow) {
    case 'SELECT_CUSTOMER':
      title = 'Selecteer een klant';
      break;
    case 'SELECT_INVOICE':
      title = 'Selecteer factuur';
      break;
    case 'SELECT_ORDERS':
      title = 'Selecteer order(s)';
      break;
    case 'ENTER_LINES':
      title = 'Credit nota lijnen';
      break;
  }

  let canGoForward = false;
  if (state.activeWindow === 'SELECT_CUSTOMER') {
    canGoForward = state.selectedCustomer !== null;
  } else if (state.activeWindow === 'SELECT_ORDERS') {
    canGoForward = state.orders.length > 0;
  }

  useEffect(() => {
    setSearchValue('');
  }, [state.activeWindow]);

  let searchText = 'search';
  switch (state.activeWindow) {
    case 'SELECT_CUSTOMER':
      searchText = 'Zoek een klant';
      break;
    case 'SELECT_INVOICE':
      searchText = 'Zoek een factuur';
      break;
    case 'SELECT_ORDERS':
      searchText = 'Zoek order(s)';
      break;
  }

  return (
    <>
      <PageHeader title="Nieuwe creditnota" />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: "Creditnota's",
              to: '/internal/finance/creditnotes',
            },
            {
              name: 'Nieuwe creditnota',
              to: '/internal/finance/creditnotes/new',
            },
          ]}
        />
      </div>

      <div className="flex justify-between mb-4">
        <h2 className="heading-two self-start">{title}</h2>

        <div className="flex gap-4">
          <div>
            <Button
              iconLeft={<ChevronLeftIcon className="button-icon" />}
              isDisabled={state.activeWindow === 'SELECT_CUSTOMER'}
              onTrigger={() => {
                dispatch({
                  type: 'GO_BACK',
                });
              }}
            >
              Keer terug
            </Button>
          </div>

          <div>
            <Button
              color="primary"
              iconRight={<ChevronRightIcon className="button-icon" />}
              isDisabled={!canGoForward}
              onTrigger={() => {
                dispatch({
                  type: 'GO_FORWARD',
                });
              }}
            >
              Ga verder
            </Button>
          </div>
        </div>
      </div>

      {state.activeWindow !== 'ENTER_LINES' && (
        <div className="mb-4">
          <Input
            type="text"
            placeholder={searchText}
            value={searchValue}
            onChange={setSearchValue}
            iconLeft={<SearchIcon className="input-icon" />}
          />
        </div>
      )}

      <Suspense fallback="loading...">
        {state.activeWindow === 'SELECT_CUSTOMER' && <CustomerSelect searchValue={searchValue} />}
        {state.activeWindow === 'SELECT_INVOICE' && <InvoiceSelect searchValue={searchValue} />}
        {state.activeWindow === 'SELECT_ORDERS' && <OrderSelect searchValue={searchValue} />}
        {state.activeWindow === 'ENTER_LINES' && <CreditNoteLines />}
      </Suspense>
    </>
  );
};

const CreateCreditNotePage = () => {
  return (
    <CreateCreditNoteProvider>
      <CreateCreditNoteComponent />
    </CreateCreditNoteProvider>
  );
};

export default CreateCreditNotePage;
