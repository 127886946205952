import { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useField } from 'formik';
import { MinusIcon, PlusIcon } from 'lucide-react';
import { useClient } from 'urql';
import { createId } from '@paralleldrive/cuid2';

import { InputWrapper } from '../../../components/InputWrapper';
import { Input } from '../../../components/input/Input';
import { Button } from '../../../components/button/Button';
import { formatNumber, parseNumberInput } from '../../../utils/number';
import {
  Country,
  GetQuotationRollingAverageDocument,
  GetQuotationRollingAverageInput,
  GetQuotationRollingAverageQuery,
  GetQuotationRollingAverageQueryVariables,
  QuotationType,
} from '../../../generated/graphql';

export interface IQuotationPriceInputProps {
  name: string;
  isDisabled?: boolean;

  quotationType: QuotationType | null;
  departurePostalCode: string;
  departureCountry: string;
  arrivalPostalCode: string;
  arrivalCountry: string;
}

export const QuotationPriceInput: React.FC<IQuotationPriceInputProps> = (props) => {
  const { name, isDisabled, quotationType, departurePostalCode, departureCountry, arrivalPostalCode, arrivalCountry } =
    props;
  const id = useId();
  const [field, meta, helpers] = useField({ name });
  const client = useClient();
  const [suggestion, setSuggestion] = useState<number>(0);

  const filter: GetQuotationRollingAverageInput | null = useMemo(() => {
    if (!quotationType || !departurePostalCode || !departureCountry || !arrivalPostalCode || !arrivalCountry) {
      return null;
    }

    return {
      type: quotationType,
      departurePostalCode,
      departureCountry: departureCountry as Country,
      arrivalPostalCode,
      arrivalCountry: arrivalCountry as Country,
    };
  }, [quotationType, departurePostalCode, departureCountry, arrivalPostalCode, arrivalCountry]);

  const fetchKey = useRef('');
  useEffect(() => {
    if (filter && !field.value) {
      const key = createId();
      fetchKey.current = key;
      const fetchSuggestions = async () => {
        const { data } = await client.query<GetQuotationRollingAverageQuery, GetQuotationRollingAverageQueryVariables>(
          GetQuotationRollingAverageDocument,
          {
            filter,
          },
        );

        if (data && fetchKey.current === key) {
          setSuggestion(data.quotationRollingAverage ?? 0);
        }
      };
      fetchSuggestions();
    }
  }, [filter]);

  const modifyValue = (multiplier: number) => {
    if (field.value) {
      try {
        const parsedValue = parseNumberInput(field.value, 2);
        if (parsedValue) {
          helpers.setValue(formatNumber(parsedValue * multiplier, 2));
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <InputWrapper labelText="Prijs" invalidText={meta.touched ? meta.error : undefined}>
      <div className="flex gap-2">
        <Input
          value={field.value}
          onChange={(val) => {
            helpers.setValue(val);
          }}
          onBlur={(evt) => {
            helpers.setTouched(true);
            field.onBlur(evt);
          }}
          id={id}
          type="number"
          step="0.01"
          isInvalid={Boolean(meta.touched && meta.error)}
          isDisabled={isDisabled}
        />

        <div>
          <Button
            onTrigger={() => {
              modifyValue(0.99);
            }}
          >
            <MinusIcon className="button-icon" />
          </Button>
        </div>
        <div>
          <Button
            onTrigger={() => {
              modifyValue(1.01);
            }}
          >
            <PlusIcon className="button-icon" />
          </Button>
        </div>
      </div>

      {!!suggestion && !field.value && (
        <div className="flex flex-wrap gap-1 mt-2">
          <div>Suggestie:</div>
          <div
            className="whitespace-nowrap cursor-pointer text-white hover:bg-orange-01 bg-dark-03 rounded px-1"
            onClick={() => {
              helpers.setValue(formatNumber(suggestion, 2));
            }}
          >{`€ ${formatNumber(suggestion, 2, {
            decimalSeperator: ',',
            removeTrailingZeros: true,
          })}`}</div>
        </div>
      )}
    </InputWrapper>
  );
};
