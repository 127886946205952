import { PlusIcon } from 'lucide-react';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Formik } from 'formik';
import { Language, useUpdateCustomerContactMutation } from 'generated/graphql';
import diff from 'object-diff';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { formatPhoneNumber } from '../../../../utils/phonenumber';
import { useCustomer } from '../Customer/customerContext';

const createContactSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  email: Yup.string().email('Ongeldig email'),
  phone: Yup.string().phone(),
  language: Yup.mixed().nullable().required('Vereist'),
  shouldReceiveInvoices: Yup.boolean(),
  shouldReceiveOrderConfirmations: Yup.boolean(),
  shouldReceiveCMR: Yup.boolean(),
});

const UpdateCustomerContactPage = () => {
  const navigate = useNavigate();
  const { customer } = useCustomer();
  const { customerContactId } = useParams<{ customerContactId: string }>();
  invariant(customerContactId);
  const [, updateContactMutation] = useUpdateCustomerContactMutation();

  const contact = customer.contacts.find((c) => c.id === +customerContactId);

  if (!contact) {
    return <div>Contact niet gevonden</div>;
  } else {
    const initialValues = {
      name: contact.name,
      email: contact.email,
      phone: contact.phone,
      language: LANGUAGE_VALUES.find((v) => v.key === contact.language),
      function: contact.function,
      shouldReceiveInvoices: contact.shouldReceiveInvoices,
      shouldReceiveOrderConfirmations: contact.shouldReceiveOrderConfirmations,
      shouldReceiveCMR: contact.shouldReceiveCMR,
      shouldReceiveQuotations: contact.shouldReceiveQuotations,
      shouldReceiveDieselSurcharge: contact.shouldReceiveDieselSurcharge,
    };

    return (
      <div>
        <div className="page-heading">
          <h1 className="heading-one">Pas contact aan</h1>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={createContactSchema}
          onSubmit={async (values) => {
            try {
              const patch: Partial<typeof values> = diff(initialValues, values);
              const updateData = {
                ...patch,
                phone: patch.phone ? formatPhoneNumber(patch.phone, false) : undefined,
                language: patch.language ? (patch.language.key as Language) : undefined,
              };
              const result = await updateContactMutation({
                id: contact.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
              if (result.data) {
                navigate('..');
              }
              toast.success('Contact aangepast');
            } catch (err: any) {
              toast.error('Kon contact niet aanpassen: ' + getDisplayError(err));
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Naam" type="text" name="name" />
              <InputField labelText="Email" type="text" name="email" />
              <InputField labelText="Telefoon" type="text" name="phone" />
              <InputField labelText="Functie" type="text" name="function" />
              <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />

              <h2 className="heading-two mb-2">Contact geschikt voor</h2>
              <div className="grid grid-cols-4 gap-x-4 mb-2">
                <CheckboxField labelText="Facturatie" name="shouldReceiveInvoices" />
                <CheckboxField labelText="Order bevestiging" name="shouldReceiveOrderConfirmations" />
                <CheckboxField labelText="CMR" name="shouldReceiveCMR" />
                <CheckboxField labelText="Offertes" name="shouldReceiveQuotations" />
                <CheckboxField labelText="Dieseltoeslag" name="shouldReceiveDieselSurcharge" />
              </div>
              <Button
                type="submit"
                color="primary"
                iconLeft={<PlusIcon className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Pas contact aan
              </Button>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default UpdateCustomerContactPage;
