import { useGetInvoicesQuery } from '../../../../../generated/graphql';
import { formatDate } from '../../../../../utils/date';
import { nullthrows } from '../../../../../utils/invariant';
import { formatNumber } from '../../../../../utils/number';
import { calculateInvoiceTotals } from '../../../utils/calculate';
import { useCreateCreditNoteCtx } from './context';

export interface Props {
  searchValue: string;
}

export const InvoiceSelect: React.FC<Props> = (props) => {
  const { searchValue } = props;
  const { state, dispatch } = useCreateCreditNoteCtx();
  const customer = nullthrows(state.selectedCustomer, 'No customer selected');
  const [data] = useGetInvoicesQuery({
    variables: {
      filters: {
        customerId: customer.id,
        noCreditNote: true,
        search: searchValue || undefined,
      },
    },
  });

  const invoices = data.data?.invoices || [];

  return (
    <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
      {!invoices.length && <div>Geen verkopen gevonden</div>}

      {invoices.map((i) => {
        const totals = calculateInvoiceTotals(
          i.orders
            .map((o) =>
              o.lines
                .map((l) =>
                  l.sales.map((s) => {
                    return {
                      ...s,
                      vatRateId: s.vatRate.id,
                    };
                  }),
                )
                .flat(),
            )
            .flat(),
        );

        return (
          <div
            key={i.id}
            className="card"
            data-clickable="true"
            onClick={() => {
              dispatch({
                type: 'SELECT_INVOICE',
                invoice: i,
              });
            }}
          >
            <div className="flex justify-between">
              <div className="card-heading-text">{i.invoiceNumber}</div>
              <div className="card-subtle-text">{formatDate(i.date)}</div>
            </div>

            <div className="mt-4">{`Verzonden op: ${i.sentAt ? formatDate(i.sentAt) : '-'}`}</div>
            <div>{`Betaald op: ${i.paidAt ? formatDate(i.paidAt) : '-'}`}</div>
            <div>{`Totaal: € ${formatNumber(totals.totalInclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
          </div>
        );
      })}
    </div>
  );
};
