import { PlusIcon } from 'lucide-react';
import { Button } from 'components/button/Button';
import { FormikProvider, useFormik } from 'formik';
import { useUpdateOrderLineSaleMutation } from 'generated/graphql';
import diff from 'object-diff';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { ISimpleComboboxItem } from '../../../../components/combobox/SimpleCombobox';
import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { ProductTypeComboboxField, productTypeToComboboxItem } from '../../productType/ProductTypeComboboxField';
import { useOrderLine } from '../orderLine/orderLineContext';
import { OrderLineSale } from './OrderLineSales';
import { VatRateComboboxField, vatRateToComboboxItem } from '../../vatRate/VatRateComboboxField';
import { useOrder } from '../order/orderContext';
import { useMinimalGeneralSettings } from '../../../../contexts/minimal-settings-context';

const createOrderLineSaleSchema = Yup.object().shape({
  productType: Yup.mixed().nullable().required('Vereist'),
  vatRate: Yup.mixed().nullable().required('Vereist'),
  amount: Yup.number().required('Vereist'),
  unitPrice: Yup.number().required('Vereist'),
  externalNote: Yup.string().nullable(),
});

interface IOrderLineSaleValues {
  productType: ISimpleComboboxItem | null;
  vatRate: ISimpleComboboxItem | null;
  amount: string;
  unitPrice: string;
  externalNote: string;
}

interface IPageContentProps {
  orderLineSale: OrderLineSale;
}

const PageContent: React.FC<IPageContentProps> = (props) => {
  const { orderLineSale } = props;

  const { orderLine } = useOrderLine();
  const { order } = useOrder();
  const { settings } = useMinimalGeneralSettings();

  const sales = orderLine.sales;
  const navigate = useNavigate();
  const [, updateOrderLineSaleMutation] = useUpdateOrderLineSaleMutation();

  const initialValues: IOrderLineSaleValues = useMemo(() => {
    return {
      productType: productTypeToComboboxItem(orderLineSale.productType),
      vatRate: vatRateToComboboxItem(orderLineSale.vatRate),
      amount: formatNumber(orderLineSale.amount, 2),
      unitPrice: formatNumber(orderLineSale.unitPrice, 2),
      externalNote: orderLineSale.externalNote ?? '',
    };
  }, [orderLineSale]);

  const formikbag = useFormik({
    initialValues,
    validationSchema: createOrderLineSaleSchema,
    onSubmit: async (values) => {
      try {
        const patch: Partial<typeof values> = diff(initialValues, values);
        const { productType, vatRate, amount, unitPrice, ...otherValues } = patch;
        const productTypeId = productType?.key;
        const vatRateId = vatRate?.key;
        const result = await updateOrderLineSaleMutation({
          id: orderLineSale.id,
          data: {
            productTypeId,
            vatRateId,
            amount: amount != null ? parseNumberInput(amount, 2) : undefined,
            unitPrice: unitPrice != null ? parseNumberInput(unitPrice, 2) : undefined,
            ...otherValues,
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          navigate('..');
        }
        toast.success('Verkoop aangepast');
      } catch (err: any) {
        toast.error('Kon verkoop niet aanpassen: ' + getDisplayError(err));
      }
    },
  });
  const { handleSubmit, isSubmitting, values } = formikbag;

  const dieselSurchargePercentage = order.customer?.dieselSurchargeEnabled
    ? order.customer?.hasCustomDieselSurcharge
      ? order.customer?.dieselSurchargePercentage ?? 0
      : settings.dieselSurchargePercentage
    : 0;
  const isDieselSurchargeSelected = values.productType?.key === settings.dieselSurchargeProductType?.id;
  useEffect(() => {
    if (isDieselSurchargeSelected) {
      const baseProduct = sales.find((p) => p.productType.id === settings.dieselSurchargeBaseProductType?.id);
      if (baseProduct) {
        const surchargeValue = (baseProduct.unitPrice / 100) * (dieselSurchargePercentage / 100);
        formikbag.setFieldValue('unitPrice', formatNumber(surchargeValue, 2));
      }
    }
  }, [values.productType]);

  return (
    <>
      <h1 className="heading-one mb-4">Pas verkoop aan</h1>

      <FormikProvider value={formikbag}>
        <form onSubmit={handleSubmit}>
          <ProductTypeComboboxField labelText="Product" name="productType" />

          <div className="flex gap-2">
            <div style={{ flex: 2 }}>
              <InputField labelText="Aantal" type="number" name="amount" step="0.01" />
            </div>
            <div style={{ flex: 2 }}>
              <InputField labelText="Eenheidsprijs" type="number" name="unitPrice" step="0.01" />
            </div>
            <div style={{ flex: 3 }}>
              <VatRateComboboxField labelText="Btw Tarief" name="vatRate" />
            </div>
          </div>

          <InputField labelText="Extra info" type="text" name="externalNote" spellCheck={true} />

          <Button
            type="submit"
            color="primary"
            iconLeft={<PlusIcon className="button-icon" />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Pas verkoop aan
          </Button>
        </form>

        {isDieselSurchargeSelected && (
          <div className="mt-4 text-sm">{`Diesel toeslag voor klant is ${formatNumber(
            dieselSurchargePercentage,
            2,
          )}%`}</div>
        )}
      </FormikProvider>
    </>
  );
};

export const UpdateOrderLineSalePage = () => {
  const { orderLine } = useOrderLine();
  const { orderLineSaleId: _orderLineSaleId } = useParams<{ orderLineSaleId: string }>();
  invariant(_orderLineSaleId);
  const orderLineSaleId = +_orderLineSaleId;

  const orderLineSale = useMemo(() => {
    return orderLine.sales.find((c) => c.id === orderLineSaleId);
  }, [orderLine, orderLineSaleId]);

  if (!orderLineSale) {
    return <div>Inhoud niet gevonden</div>;
  }

  return <PageContent orderLineSale={orderLineSale} />;
};
