import { useMemo } from 'react';
import { useResolvedPath } from 'react-router-dom';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { ITabItem, Tabs } from '../../../components/tabs/Tabs';
import { EmailType, DocumentType } from '../../../generated/graphql';
import { AllGeneralSettingsProvider } from '../all-settings-context';

const SettingsPage = () => {
  const generalSettingsRoute = useResolvedPath('general');
  const actionsRoute = useResolvedPath('actions');
  const orderSettingsRoute = useResolvedPath('order');
  const invoiceSettingsRoute = useResolvedPath('invoice');
  const emailSettingsRoute = useResolvedPath(`email/${EmailType.Invoice}`);
  const documentSettingsRoute = useResolvedPath(`document/${DocumentType.Tro}`);
  const mailboxSettingsRoute = useResolvedPath('mailbox');
  const usersSettingsRoute = useResolvedPath('users');
  const transporeonSettingsRoute = useResolvedPath('transporeon');

  const tabItems = useMemo<ITabItem[]>(() => {
    return [
      {
        title: 'Algemeen',
        path: generalSettingsRoute.pathname,
      },
      {
        title: 'Acties',
        path: actionsRoute.pathname,
      },
      {
        title: 'Order',
        path: orderSettingsRoute.pathname,
      },
      {
        title: 'Facturatie',
        path: invoiceSettingsRoute.pathname,
      },
      {
        title: 'Email',
        path: emailSettingsRoute.pathname,
      },
      {
        title: 'Document',
        path: documentSettingsRoute.pathname,
      },
      {
        title: 'Mailbox',
        path: mailboxSettingsRoute.pathname,
      },
      {
        title: 'Gebruikers',
        path: usersSettingsRoute.pathname,
      },
      {
        title: 'Transporeon',
        path: transporeonSettingsRoute.pathname,
      },
    ];
  }, []);

  return (
    <>
      <PageHeader title="Instellingen" />

      <div>
        <div className="page-heading">
          <div className="flex justify-between items-center">
            <Breadcrumb
              items={[
                {
                  name: 'Instellingen',
                },
              ]}
            />
          </div>
        </div>

        <AllGeneralSettingsProvider>
          <Tabs items={tabItems} />
        </AllGeneralSettingsProvider>
      </div>
    </>
  );
};

export default SettingsPage;
