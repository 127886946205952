import { Link } from 'react-router-dom';
import { PageHeader } from '../../../components/PageHeader';

export interface ILinkCardProps {
  title: string;
  to: string;
}

const LinkCard: React.FC<ILinkCardProps> = (props) => {
  const { title, to } = props;

  return (
    <Link to={to}>
      <div className="card" data-clickable="true">
        <div className="card-heading-text">{title}</div>
      </div>
    </Link>
  );
};

export const FinancePage = () => {
  return (
    <>
      <PageHeader title="Financieel" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Financieel</h1>
        </div>

        <div className="grid lg:grid-cols-3 gap-4">
          <LinkCard title="Verkopen" to="sales" />
          <LinkCard title="Offertes" to="quotations" />
          <LinkCard title="Offerte aanvragen" to="quotation-requests" />
          <LinkCard title="Credit Notas" to="creditnotes" />
          <LinkCard title="Aankopen" to="purchases" />
          <LinkCard title="Self Billing" to="self-billing" />
        </div>
      </div>
    </>
  );
};
