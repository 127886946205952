import React from 'react';
import { useTranslation } from '../contexts/translation-context';

export interface InputWrapperProps {
  labelText: React.ReactNode;
  invalidText?: string;
  helperText?: string;
  children: React.ReactNode;
  noLabel?: boolean;
  hideInvalid?: boolean;
}

const Label: React.FC<{ children: React.ReactNode; noLabel?: boolean }> = (props) => {
  return React.createElement(props.noLabel ? 'div' : 'label', { className: 'block w-full text-left' }, props.children);
};

export const InputWrapper: React.FC<InputWrapperProps> = (props) => {
  const { labelText, invalidText, hideInvalid, helperText, children, noLabel } = props;
  const { i18n } = useTranslation();

  return (
    <div className="w-full">
      <Label noLabel={noLabel}>
        <div className="label-text py-1 w-full">{labelText}</div>
        <div>{children}</div>
      </Label>

      {!hideInvalid && (
        <div className="text-sm pb-1 w-full">
          {invalidText ? (
            <span className="text-feedback-negative">{i18n(invalidText as any)}</span>
          ) : (
            <span className="text-dark-03">{helperText ?? <>&zwnj;</>}</span>
          )}
        </div>
      )}
    </div>
  );
};
