import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { PlusIcon } from 'lucide-react';

import { Button } from '../../../../components/button/Button';
import { useAllGeneralSettings } from '../../all-settings-context';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { InputField } from '../../../../components/input/InputField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { MailboxTypeSelectOptions } from './constants';
import { MailboxType, useUpdateMailboxMutation } from '../../../../generated/graphql';
import toast from 'react-hot-toast';
import { getDisplayError } from '../../../../utils/get-display-error';
import { invariant, nullthrows } from '../../../../utils/invariant';
import diff from 'object-diff';

const updateMailboxSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  type: Yup.mixed().nullable().required('Vereist'),
  imapHost: Yup.string().min(1, 'Vereist').required('Vereist'),
  imapPort: Yup.string().matches(/\d+/, 'Moet een getal zijn').min(1, 'Vereist').required('Vereist'),
  imapTls: Yup.boolean(),
  imapUser: Yup.string().min(1, 'Vereist').required('Vereist'),
  imapPassword: Yup.string(),
});

export const UpdateMailboxPage = () => {
  const navigate = useNavigate();
  const { mailboxId } = useParams<{ mailboxId: string }>();
  invariant(mailboxId);
  const { settings, refreshData } = useAllGeneralSettings();
  const [, updateMailbox] = useUpdateMailboxMutation();

  const mailbox = nullthrows(
    settings.mailboxes.find((m) => m.id === mailboxId),
    'mailbox not found',
  );

  const initialValues = useMemo(() => {
    return {
      type: MailboxTypeSelectOptions.find((v) => v.key === mailbox.type),
      name: mailbox.name,
      imapHost: mailbox.imapHost,
      imapPort: mailbox.imapPort,
      imapUser: mailbox.imapUser,
      imapPassword: '',
      imapTls: mailbox.imapTls,
    };
  }, [mailbox]);

  return (
    <div>
      <h2 className="heading-two">Pas mailbox configuratie aan</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={updateMailboxSchema}
        onSubmit={async (values) => {
          try {
            const patch: Partial<typeof values> = diff(initialValues, values);
            const result = await updateMailbox({
              mailboxId: mailbox.id,
              data: {
                ...patch,
                type: patch.type ? (patch.type.key as MailboxType) : undefined,
                imapPort: patch.imapPort ? +patch.imapPort : undefined,
              },
            });
            if (result.error) {
              throw result.error;
            }
            refreshData();
            toast.success('Mailbox aangepast');
            navigate('..');
          } catch (err) {
            toast.error('Kon mailbox niet aanpassen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <SimpleSelectField labelText="Type" items={MailboxTypeSelectOptions} name="type" />

            <InputField labelText="Naam" type="text" name="name" />
            <InputField labelText="IMAP User" type="text" name="imapUser" />
            <InputField labelText="IMAP Password" type="password" name="imapPassword" />

            <InputField labelText="IMAP Host" type="text" name="imapHost" />
            <InputField labelText="IMAP Port" type="number" name="imapPort" />
            <CheckboxField labelText="IMAP TLS" name="imapTls" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<PlusIcon className="button-icon" />}
            >
              Pas mailbox aan
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
