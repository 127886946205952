import Idbkv from 'idb-kv';

// @ts-ignore
const MAPBOX_TOKEN: string = import.meta.env.VITE_MAPBOX_TOKEN;

const cacheStore = new Idbkv('mapbox-cache-v1');

export interface IRoute {
  distance: number;
  duration: number;
  geometry: {
    coordinates: [number, number][];
    type: 'LineString';
  };
}

export function serializeCoordinates(coordinates: [number, number]) {
  return coordinates.map((c) => c.toFixed(3)).join(',');
}

export async function createRoute(
  startPoint: [number, number],
  destinationPoint: [number, number],
): Promise<IRoute | null> {
  const points = serializeCoordinates(startPoint) + ';' + serializeCoordinates(destinationPoint);

  const cacheKey = `MB_ROUTE_${points}`;
  const existingItem = await cacheStore.get(cacheKey).catch(() => null);
  if (existingItem) {
    return existingItem as any;
  }

  const searchParams = new URLSearchParams();
  searchParams.set('access_token', MAPBOX_TOKEN);
  searchParams.set('language', 'nl');
  searchParams.set('geometries', 'geojson');
  const result = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${points}.json?${searchParams.toString()}`,
    {
      headers: {
        accept: 'application/json',
      },
    },
  );
  const json = await result.json();
  if (!result.ok) {
    throw new Error('No results found');
  }
  const route = json.routes[0] ?? null;
  await cacheStore.set(cacheKey, route);
  return route;
}
