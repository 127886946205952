import * as Yup from 'yup';
import { Formik } from 'formik';
import { KeyIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { PageHeader } from '../../../components/PageHeader';
import { useSetPasswordMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { nullthrows } from '../../../utils/invariant';
import { useTranslation } from '../../../contexts/translation-context';

const setPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().min(12, 'errors.passwordTooShort').required('errors.required'),
  repeatNewPassword: Yup.string()
    .min(12, 'errors.passwordTooShort')
    .required('errors.required')
    .test('passwordsAreEqual', 'errors.passwordsDontMatch', function () {
      return this.parent.newPassword === this.parent.repeatNewPassword;
    }),
});

const SetNewPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [, setPassword] = useSetPasswordMutation();
  const { i18n } = useTranslation();

  return (
    <div className="h-screen">
      <PageHeader title={i18n('auth.configNewPassword.title')} />

      <main className="bg-dark-01 p-8 h-full">
        <div className="flex flex-col justify-center h-full items-center max-w-md mx-auto">
          <h1 className="heading-one mb-8 text-white">{i18n('auth.configNewPassword.title')}</h1>

          <div className="w-full">
            <Formik
              initialValues={{
                newPassword: '',
                repeatNewPassword: '',
              }}
              validationSchema={setPasswordSchema}
              onSubmit={async (values) => {
                try {
                  const { newPassword, repeatNewPassword } = values;
                  if (newPassword !== repeatNewPassword) {
                    throw new Error(i18n('auth.configNewPassword.passwordDontMatch'));
                  }

                  const token = nullthrows(searchParams.get('token'), i18n('auth.configNewPassword.tokenNotFound'));
                  const result = await setPassword({
                    token,
                    newPassword,
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  const succeeded = result.data?.setPassword;
                  if (!succeeded) {
                    throw new Error(i18n('auth.configNewPassword.invalidToken'));
                  }

                  window.location.href = '/login';
                } catch (err: any) {
                  toast.error(
                    i18n('auth.configNewPassword.configFailed', {
                      error: getDisplayError(err),
                    }),
                  );
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <InputField
                    labelText={i18n('auth.configNewPassword.newPassword')}
                    type="password"
                    name="newPassword"
                  />
                  <InputField
                    labelText={i18n('auth.configNewPassword.repeatNewPassword')}
                    type="password"
                    name="repeatNewPassword"
                  />

                  <div className="flex justify-between">
                    <Button
                      type="submit"
                      color="primary"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      iconLeft={<KeyIcon className="button-icon" />}
                    >
                      {i18n('auth.configNewPassword.confirmButtonText')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SetNewPasswordPage;
