import classNames from '@utils/classnames';
import React from 'react';

import { Spinner } from '../Spinner';
import { Link, LinkProps } from 'react-router-dom';

export interface LinkButtonProps extends LinkProps {
  color?: 'secondary' | 'primary';
  isLoading?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
  isDisabled?: boolean;
}
export type Ref = HTMLButtonElement;

const DisabledLink: React.FC<LinkProps> = (props) => {
  const { children, className, style } = props;

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const {
    isLoading,
    isDisabled: _disabled,
    color = 'secondary',
    children,
    className,
    iconLeft,
    iconRight,
    ...otherProps
  } = props;

  const isDisabled = _disabled || !otherProps.to;
  const colorMap = {
    'text-black bg-dark-04 hover:bg-dark-03': color === 'secondary' && !isDisabled,
    'text-black bg-orange-00 hover:bg-orange-01': color === 'primary' && !isDisabled,
    'text-dark-03 bg-dark-05': isDisabled,
  };

  const ComponentClass = isDisabled || isLoading ? DisabledLink : Link;
  return (
    <ComponentClass
      {...otherProps}
      className={classNames(
        'overflow-hidden relative flex items-center justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-lg',
        className,
        colorMap,
      )}
      style={{ height: '2.75rem' }}
    >
      <div className="w-full flex items-center whitespace-nowrap">
        {iconLeft != null && <div className="p-4 border-r border-white">{iconLeft}</div>}
        <div className="px-4 w-full">{children}</div>
        {iconRight != null && <div className="p-4 border-l border-white">{iconRight}</div>}
      </div>
      {isLoading && (
        <div className={classNames('absolute flex items-center justify-center top-0 left-0 w-full h-full', colorMap)}>
          <Spinner size={4} color={color === 'primary' ? 'primary' : 'default'} />
        </div>
      )}
    </ComponentClass>
  );
};
