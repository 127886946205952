import { PageHeader } from '../../../components/PageHeader';
import { AuditLogsList } from '../components/AuditLogsList';

export const AuditLogsPage = () => {
  return (
    <>
      <PageHeader title="Audit Logs" />

      <div>
        <div className="page-heading mb-8">
          <h1 className="heading-one">Audit Logs</h1>
        </div>

        <AuditLogsList filter={{}} />
      </div>
    </>
  );
};
