import React, { ReactNode } from 'react';
import { Cell, Pie, PieChart as RePieChart } from 'recharts';

import { hexToRGB } from '../../../utils/colors';

export interface PieChartValue {
  id: number | string;
  value: number;
  name: string;
}

export interface PieChartProps {
  hoveredId?: number | string | null;
  data: PieChartValue[];
  dataType?: 'percentage' | 'currency' | 'value';
  showLabels?: boolean;
  onHover?: (id: string | number | null) => void;
}

const COLORS = ['#E69840', '#E6AE6E', '#E6C39C', '#E6CEB3'];

const formatValue = (val: number) => {
  if (val > 1000000) {
    return `${(val / 1000000).toFixed(0)}M`;
  } else if (val > 1000) {
    return `${(val / 1000).toFixed(0)}K`;
  } else {
    return val.toFixed(0);
  }
};

const RADIAN = Math.PI / 180;
const Label = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, dataType, value, isHovered, name }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent < 0.05) {
    return null;
  }

  let labelText = '';
  switch (dataType) {
    case 'percentage':
      labelText = `${(percent * 100).toFixed(0)}%`;
      break;
    case 'currency':
      labelText = '€ ' + formatValue(value);
      break;
    default:
      labelText = formatValue(value);
      break;
  }
  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="middle" className="text-xs">
      {labelText}
    </text>
  );
};

const SIZE = 235;
export const PieChart: React.FC<PieChartProps> = (props) => {
  const { data, dataType, showLabels, hoveredId, onHover } = props;

  const sortedData = data.sort((a, b) => b.value - a.value);

  const renderLabel = React.useCallback(
    (props: any): ReactNode => {
      if (!showLabels) {
        return '';
      } else {
        return <Label {...props} dataType={dataType} />;
      }
    },
    [dataType, showLabels],
  );

  return (
    <RePieChart
      width={SIZE}
      height={SIZE}
      onMouseLeave={() => {
        onHover?.(null);
      }}
    >
      <Pie
        data={sortedData.map((v) => {
          return {
            ...v,
            value: Math.max(v.value, 0),
            isHovered: hoveredId === v.id,
          };
        })}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={SIZE / 2}
        labelLine={false}
        label={renderLabel}
        isAnimationActive={false}
      >
        {sortedData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={hexToRGB(COLORS[index % COLORS.length], hoveredId && hoveredId !== entry.id ? '0.5' : '1')}
            onMouseEnter={() => onHover?.(entry.id)}
          />
        ))}
      </Pie>
    </RePieChart>
  );
};
