import { PlusIcon } from 'lucide-react';
import { Button } from 'components/button/Button';
import { Formik } from 'formik';
import { useUpdateOrderLineContentMutation } from 'generated/graphql';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { ISimpleComboboxItem } from '../../../../components/combobox/SimpleCombobox';
import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { CargoTypeComboboxField, cargoTypeToComboboxItem } from '../../cargoType/CargoTypeComboboxField';
import { PackageTypeComboboxField } from '../../packageType/PackageTypeComboboxField';
import { useOrderLine } from '../orderLine/orderLineContext';
import { OrderLineContent } from './OrderLineContents';

const createOrderLineContentSchema = Yup.object().shape({
  cargoType: Yup.mixed().nullable().required('Vereist'),
  packages: Yup.number().required('Vereist'),
  packageType: Yup.mixed().nullable().required('Vereist'),
  weight: Yup.number().required('Vereist'),
  volume: Yup.number().required('Vereist'),
  loadingMeters: Yup.number().required('Vereist'),
});

interface IOrderLineContentValues {
  cargoType: ISimpleComboboxItem | null;
  packages: string;
  packageType: ISimpleComboboxItem | null;
  weight: string;
  volume: string;
  loadingMeters: string;
}

interface IPageContentProps {
  orderLineContent: OrderLineContent;
}

const PageContent: React.FC<IPageContentProps> = (props) => {
  const { orderLineContent } = props;

  const navigate = useNavigate();
  const [, updateOrderLineContentMutation] = useUpdateOrderLineContentMutation();

  const initialValues: IOrderLineContentValues = useMemo(() => {
    return {
      cargoType: cargoTypeToComboboxItem(orderLineContent.cargoType),
      packages: formatNumber(orderLineContent.packages, 2),
      packageType: cargoTypeToComboboxItem(orderLineContent.packageType),
      weight: formatNumber(orderLineContent.weight, 2),
      volume: formatNumber(orderLineContent.volume, 2),
      loadingMeters: formatNumber(orderLineContent.loadingMeters, 2),
    };
  }, [orderLineContent]);

  return (
    <>
      <h1 className="heading-one mb-4">Pas inhoud aan</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={createOrderLineContentSchema}
        onSubmit={async (values) => {
          try {
            const patch: Partial<typeof values> = diff(initialValues, values);
            const { cargoType, packageType, packages, weight, volume, loadingMeters, ...otherValues } = patch;
            const cargoTypeId = cargoType?.key;
            const packageTypeId = packageType?.key;
            const result = await updateOrderLineContentMutation({
              id: orderLineContent.id,
              data: {
                cargoTypeId,
                packageTypeId,
                packages: packages != null ? parseNumberInput(packages, 2) : undefined,
                weight: weight != null ? parseNumberInput(weight, 2) : undefined,
                volume: volume != null ? parseNumberInput(volume, 2) : undefined,
                loadingMeters: loadingMeters != null ? parseNumberInput(loadingMeters, 2) : undefined,
                ...otherValues,
              },
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              navigate('..');
            }
            toast.success('Inhoud aangepast');
          } catch (err: any) {
            toast.error('Kon inhoud niet aanpassen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CargoTypeComboboxField labelText="Inhoud" name="cargoType" />
              <div className="flex gap-2">
                <InputField labelText="Coli" type="number" name="packages" step="0.01" />
                <PackageTypeComboboxField labelText="Verpakking" name="packageType" />
              </div>
              <div className="flex gap-2">
                <InputField labelText="Gewicht (kg)" type="number" name="weight" step="0.01" />
                <InputField labelText="Volume (m3)" type="number" name="volume" step="0.01" />
                <InputField labelText="Laadmeter" type="number" name="loadingMeters" step="0.01" />
              </div>

              <Button
                type="submit"
                color="primary"
                iconLeft={<PlusIcon className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Pas inhoud aan
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export const UpdateOrderLineContentPage = () => {
  const { orderLine } = useOrderLine();
  const { orderLineContentId: _orderLineContentId } = useParams<{ orderLineContentId: string }>();
  invariant(_orderLineContentId);
  const orderLineContentId = +_orderLineContentId;

  const orderLineContent = useMemo(() => {
    return orderLine.contents.find((c) => c.id === orderLineContentId);
  }, [orderLine, orderLineContentId]);

  if (!orderLineContent) {
    return <div>Inhoud niet gevonden</div>;
  }

  return <PageContent orderLineContent={orderLineContent} />;
};
