import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useClient } from 'urql';

import { Button } from '../../../components/button/Button';
import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { StatusText } from '../../../components/StatusText';
import { ITableHeader, Table } from '../../../components/table/Table';
import { GetCreditNotesDocument, GetCreditNotesQuery, GetCreditNotesQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatNumber } from '../../../utils/number';
import { calculateInvoiceTotals } from '../utils/calculate';
import { CreditNoteStatusToColor, CreditNoteStatusToText } from '../utils/status';
import { ExportCreditNotesButton } from './ExportCreditNotesButton';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { LinkButton } from '../../../components/button/ButtonLink';

const CREDIT_NOTES_HEADER: ITableHeader[] = [
  {
    id: 'id',
    name: 'Creditnota Nummer',
  },
  {
    id: 'id',
    name: 'Datum',
  },
  {
    id: 'id',
    name: 'Vervaldatum',
  },
  {
    id: 'customer',
    name: 'Klant',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
];

const CreditNotesPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCreditNotesQuery, GetCreditNotesQueryVariables>(
          GetCreditNotesDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.creditNotes ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const title = `Creditnota's`;

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Financieel',
                to: '/internal/finance',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek een creditnota..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
            <div>
              <ExportCreditNotesButton />
            </div>
            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                Nieuwe Creditnota
              </LinkButton>
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={CREDIT_NOTES_HEADER}
          data={page.data}
          mapData={(creditNote) => {
            const status = creditNote.status;
            const totals = calculateInvoiceTotals(
              creditNote.creditNoteOrders
                .map((o) =>
                  o.lines.map((l) => {
                    return {
                      ...l,
                      vatRateId: l.vatRate.id,
                    };
                  }),
                )
                .flat(),
            );

            return [
              <Link to={`${creditNote.id}`} className="link-text">
                {creditNote.creditNoteNumber ?? 'DRAFT'}
              </Link>,
              formatDate(creditNote.date),
              creditNote.expiresAt ? formatDate(creditNote.expiresAt) : '-',
              creditNote.customer.name,
              <StatusText color={CreditNoteStatusToColor[status]}>{CreditNoteStatusToText[status]}</StatusText>,
              `€ ${formatNumber(totals.totalInclVat, 2, {
                decimalSeperator: ',',
              })}`,
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default CreditNotesPage;
