import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { GetLocationsDocument, GetLocationsQuery, GetLocationsQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { LinkButton } from '../../../components/button/ButtonLink';

const LocationsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetLocationsQuery, GetLocationsQueryVariables>(
          GetLocationsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.locations ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  return (
    <>
      <PageHeader title="Locaties" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Locaties</h1>

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek een locatie..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>

            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                Nieuwe locatie
              </LinkButton>
            </div>
          </div>
        </div>

        <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-4 mt-8">
          {page.data.map((location) => {
            return (
              <Link to={`${location.id}/general`} key={location.id}>
                <div className="card" data-clickable="true">
                  <div className="card-heading-text mb-4">{location.name}</div>
                  <div>{`${location.street} ${location.streetNumber}`}</div>
                  <div>{`${location.postalCode} ${location.city}, ${location.country}`}</div>

                  <div className="card-subtle-text">{location.deactivationReason ? 'Niet actief' : 'Actief'}</div>
                </div>
              </Link>
            );
          })}
        </div>

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default LocationsPage;
