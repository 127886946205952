import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { COUNTRY_VALUES } from '@utils/address';
import * as Yup from 'yup';

import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { SimpleSelectField } from 'components/select/SimpleSelectField';
import { Country, Language, useCustomerPortalUpdateCustomerMutation } from 'generated/graphql';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { formatVatNumber } from '../../../utils/vat-number';
import { getDisplayError } from '../../../utils/get-display-error';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { AutocompletePostalcode } from '../../location/components/AutocompletePostalcode';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { PageHeader } from '../../../components/PageHeader';
import { useTranslation } from '../../../contexts/translation-context';
import { DataField } from '../../../components/DataField';

const updateCustomerSchema = Yup.object().shape({
  street: Yup.string().min(1, 'errors.required').required('errors.required'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'errors.required').required('errors.required'),
  postalCode: Yup.string().min(1, 'errors.required').required('errors.required'),
  country: Yup.mixed().nullable().required('errors.required'),
  language: Yup.mixed().nullable().required('errors.required'),
});

export const CustomerPortalUpdateCustomerDetailsPage = () => {
  const { customer } = useCustomerPortalCustomer();
  const { i18n } = useTranslation();
  const [, updateCustomerMutation] = useCustomerPortalUpdateCustomerMutation();

  const initialValues = useMemo(() => {
    return {
      street: customer.street,
      streetNumber: customer.streetNumber ?? '',
      city: customer.city,
      postalCode: customer.postalCode,
      country: COUNTRY_VALUES.find((v) => v.key === customer.country)!,
      language: LANGUAGE_VALUES.find((v) => v.key === customer.language)!,
    };
  }, [customer]);

  return (
    <div>
      <PageHeader title={i18n('customerPortal.businessInformation.updateBusinessInformation')} />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: i18n('customerPortal.businessInformation.businessInformation'),
            },
          ]}
        />
      </div>

      <div className="mb-2">
        <DataField title={i18n('customerPortal.businessInformation.name')}>{customer.name}</DataField>
      </div>

      <div className="grid grid-cols-2">
        <div className="mb-2">
          <DataField title={i18n('customerPortal.businessInformation.vatNumber')}>
            {formatVatNumber(customer.vatNumber)}
          </DataField>
        </div>
        <div className="mb-2">
          <DataField title={i18n('customerPortal.businessInformation.businessNumber')}>
            {formatVatNumber(customer.companyNumber)}
          </DataField>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={updateCustomerSchema}
        onSubmit={async (newValues) => {
          try {
            const patch: Partial<typeof newValues> = diff(initialValues, newValues);
            const updateData = {
              ...patch,
              country: patch.country ? (patch.country.key as Country) : undefined,
              language: patch.language ? (patch.language.key as Language) : undefined,
            };
            if (Object.values(updateData).length) {
              const result = await updateCustomerMutation({
                id: customer.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
            }
            toast.success(i18n('customerPortal.businessInformation.informationChanged'));
          } catch (err: any) {
            toast.error(
              i18n('customerPortal.businessInformation.informationNotChanged', {
                error: getDisplayError(err),
              }),
            );
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="flex gap-4">
                <div style={{ flex: 4 }}>
                  <InputField labelText={i18n('customerPortal.locations.street')} type="text" name="street" />
                </div>
                <div style={{ flex: 1 }}>
                  <InputField labelText={i18n('customerPortal.locations.number')} type="text" name="streetNumber" />
                </div>
              </div>
              <AutocompletePostalcode />
              <SimpleSelectField
                labelText={i18n('customerPortal.locations.language')}
                items={LANGUAGE_VALUES}
                name="language"
              />

              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                iconLeft={<PlusIcon className="button-icon" />}
              >
                {i18n('customerPortal.businessInformation.saveChanges')}
              </Button>
            </form>
          );
        }}
      </Formik>

      <div className="my-4 text-sm">{i18n('customerPortal.businessInformation.requestChanges')}</div>
    </div>
  );
};
