import { PencilIcon, PlusIcon } from 'lucide-react';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { SimpleComboboxField } from '../../../components/combobox/SimpleComboboxField';
import { useCargoTypesQuery } from '../../../generated/graphql';
import { CreateCargoTypeDialog } from './CreateCargoTypeDialog';
import { UpdateCargoTypeDialog } from './UpdateCargoTypeDialog';

export interface IMinimalCargoType {
  id: string;
  nameNl: string;
}

export const cargoTypeToComboboxItem = (val: IMinimalCargoType): ISimpleComboboxItem => {
  return {
    key: val.id,
    name: `${val.id} - ${val.nameNl}`,
  };
};

export interface ICargoTypeComboboxProps {
  name: string;
  labelText?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onBlur?: () => void;
  isNullable?: boolean;
  onlySelect?: boolean;
}

export const CargoTypeComboboxField: React.FC<ICargoTypeComboboxProps> = (props) => {
  const { labelText, name, onlySelect, ...otherProps } = props;
  const [{ data }, reexecuteQuery] = useCargoTypesQuery();
  const [field, _meta, helpers] = useField({ name });

  const cargoTypes = data?.cargoTypes ?? [];

  const items: ISimpleComboboxItem[] = useMemo(() => {
    return data ? data.cargoTypes.map(cargoTypeToComboboxItem) : [];
  }, [cargoTypes]);

  const refetch = useCallback(() => {
    reexecuteQuery({
      requestPolicy: 'network-only',
    });
  }, [reexecuteQuery]);

  const value: ISimpleComboboxItem | null = field.value;
  return (
    <div>
      <div className="flex items-center">
        <SimpleComboboxField labelText={labelText ?? 'Aard'} items={items} name={name} {...otherProps} />

        {!onlySelect && (
          <div className="flex-end flex justify-end gap-2 ml-2">
            {value && (
              <UpdateCargoTypeDialog
                id={value.key}
                onSubmit={(data) => {
                  helpers.setValue(cargoTypeToComboboxItem(data));
                  refetch();
                }}
                triggerText={<PencilIcon className="button-icon" />}
              />
            )}

            <CreateCargoTypeDialog
              onSubmit={(data) => {
                helpers.setValue(cargoTypeToComboboxItem(data));
                refetch();
              }}
              triggerText={<PlusIcon className="button-icon" />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
