import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';
import { useClient } from 'urql';
import toast from 'react-hot-toast';
import ExcelJS from 'exceljs';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import {
  CustomerDocumentType,
  GetCustomersDocument,
  GetCustomersForExportDocument,
  GetCustomersForExportQuery,
  GetCustomersForExportQueryVariables,
  GetCustomersQuery,
  GetCustomersQueryVariables,
  UserRole,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { CUSTOMER_TYPE_VALUES } from '../select-values';
import { LinkButton } from '../../../components/button/ButtonLink';
import classNames from '../../../utils/classnames';
import { Button } from 'components/button/Button';
import { roleToNumber } from 'src/app/auth/roles.client';
import { useAuth } from 'contexts/auth-context';

export type Customer = NonNullable<IterableElement<GetCustomersQuery['customers']>>;

const CustomersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const { me } = useAuth();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomersQuery, GetCustomersQueryVariables>(
          GetCustomersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customers ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const isAdmin = roleToNumber(me.role) >= roleToNumber(UserRole.Admin);
  return (
    <>
      <PageHeader title="Klanten" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Klanten</h1>

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek een klant..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
            {isAdmin && (
              <div>
                <Button
                  isLoading={isExporting}
                  isDisabled={isExporting}
                  onTrigger={async () => {
                    if (isExporting) {
                      return;
                    }

                    setIsExporting(true);
                    try {
                      const customersDict: Record<string, NonNullable<GetCustomersForExportQuery['customers'][0]>> = {};

                      let cursor = null;
                      while (true) {
                        const result = await client
                          .query<GetCustomersForExportQuery, GetCustomersForExportQueryVariables>(
                            GetCustomersForExportDocument,
                            {
                              id: cursor,
                              take: 100,
                            },
                            {
                              requestPolicy: 'cache-and-network',
                            },
                          )
                          .toPromise();

                        if (result.error) {
                          throw result.error;
                        }

                        if (!result.data) {
                          throw new Error('No data');
                        }

                        let hasNewData = false;
                        for (const customer of result.data.customers) {
                          if (!customersDict[customer.id]) {
                            customersDict[customer.id] = customer;
                            hasNewData = true;
                          }

                          cursor = customer.id;
                        }

                        if (!hasNewData) {
                          break;
                        }
                      }

                      const customers = Object.values(customersDict);
                      const workbook = new ExcelJS.Workbook();
                      workbook.creator = 'TDMS';
                      const worksheet = workbook.addWorksheet('Customers');

                      worksheet.columns = [
                        { header: 'Referentie', key: 'ref', width: 10 },
                        { header: 'Naam', key: 'name', width: 32 },
                        { header: 'Bedrijfsnummer', key: 'companyNumber', width: 32 },
                        { header: 'BTW Nummer', key: 'vatNumber', width: 32 },
                        { header: 'Type', key: 'type', width: 16 },
                        { header: 'Straat', key: 'street', width: 16 },
                        { header: 'Huisnummer', key: 'streetNumber', width: 16 },
                        { header: 'Land', key: 'country', width: 16 },
                        { header: 'Postcode', key: 'postalCode', width: 32 },
                        { header: 'Stad', key: 'city', width: 32 },
                        { header: 'Betaaltermijn', key: 'paymentTerm', width: 32 },
                        { header: 'Factuur frequentie', key: 'invoiceFrequency', width: 32 },
                        { header: 'Factuurlevering', key: 'invoiceDelivery', width: 32 },
                        { header: 'Diesel toeslag', key: 'dieselSurcharge', width: 16 },
                        { header: 'Type goederen', key: 'cargoType', width: 32 },
                        { header: 'Klantenfiche', key: 'customerFileDoc', width: 32 },
                        { header: 'Akkoord e-facturatie', key: 'eInvoicingDoc', width: 32 },
                        { header: 'Transport instructies', key: 'transportInstructionsDoc', width: 32 },
                      ];

                      for (const customer of customers) {
                        worksheet.addRow({
                          ref: customer.id,
                          name: customer.name,
                          companyNumber: customer.companyNumber,
                          vatNumber: customer.vatNumber,
                          type: CUSTOMER_TYPE_VALUES.find((v) => v.key === customer.type)?.name,
                          street: customer.street,
                          streetNumber: customer.streetNumber,
                          country: customer.country,
                          postalCode: customer.postalCode,
                          city: customer.city,
                          paymentTerm: customer.paymentTerm,
                          invoiceFrequency: customer.invoiceFrequency,
                          invoiceDelivery: customer.invoiceDeliveryMethod,
                          dieselSurcharge: customer.dieselSurchargePercentage || '-',
                          cargoType: customer.defaultCargoType?.id || '-',
                          customerFileDoc: customer.documents.find((v) => v.type === CustomerDocumentType.CustomerFile)
                            ? 'Yes'
                            : 'No',
                          eInvoicingDoc: customer.documents.find(
                            (v) => v.type === CustomerDocumentType.EInvoiceAgreement,
                          )
                            ? 'Yes'
                            : 'No',
                          transportInstructionsDoc: customer.documents.find(
                            (v) => v.type === CustomerDocumentType.TransportInstructions,
                          )
                            ? 'Yes'
                            : 'No',
                        });
                      }

                      const arr = (await workbook.xlsx.writeBuffer()) as Buffer;
                      const blob = new Blob([arr], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      });
                      const url = URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = 'klanten.xlsx';
                      a.click();
                      URL.revokeObjectURL(url);

                      toast.success('Export voltooid');
                    } catch (err) {
                      console.error(err);
                      toast.error('Klanten export mislukt');
                    }
                    setIsExporting(false);
                  }}
                >
                  Exporteer klanten
                </Button>
              </div>
            )}
            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                Nieuwe klant
              </LinkButton>
            </div>
          </div>
        </div>

        <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3 mb-4 mt-8">
          {page.data.map((customer) => {
            return (
              <Link to={`${customer.id}/general`} key={customer.id}>
                <div
                  className={classNames('card', {
                    'card-deactivated': !!customer.deactivationReason,
                  })}
                  data-clickable="true"
                >
                  <div className="flex justify-between">
                    <div className="card-heading-text">{customer.name}</div>
                    <div className="card-subtle-text">{customer.vatNumber}</div>
                  </div>
                  <div className="card-subtle-text mb-4">
                    {CUSTOMER_TYPE_VALUES.find((v) => v.key === customer.type)?.name}
                    {!!customer.deactivationReason && ` - gedeactiveerd`}
                  </div>

                  <div>{`${customer.street} ${customer.streetNumber}`}</div>
                  <div>{`${customer.postalCode} ${customer.city}, ${customer.country}`}</div>
                </div>
              </Link>
            );
          })}
        </div>

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default CustomersPage;
