import * as Dialog from '@radix-ui/react-dialog';
import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { PlusIcon, TrashIcon } from 'lucide-react';

import { BaseButton } from '../../../components/button/BaseButton';
import { IncomingDocument } from '../pages/IncomingDoc';
import { DOCUMENT_CATEGORY_VALUES } from '../../document/constants.client';
import { Input } from '../../../components/input/Input';
import { InputWrapper } from '../../../components/InputWrapper';
import { Button } from '../../../components/button/Button';
import { getDisplayError } from '../../../utils/get-display-error';

export interface IDocumentSplitterDialogProps {
  pages: IncomingDocument['pages'];
  executeSplit: (pageGroups: IParsedPageRange[]) => Promise<void>;
  isDisabled?: boolean;
}

export interface IPageRange {
  start: string;
  end: string;
}

export interface IParsedPageRange {
  start: number;
  end: number;
}

export function DocumentSplitterDialog(props: IDocumentSplitterDialogProps): JSX.Element {
  const { pages, executeSplit, isDisabled } = props;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageGroups, setPageGroups] = useState<IPageRange[]>([
    {
      start: '1',
      end: pages.length.toString(10),
    },
  ]);

  const triggerText = 'Splits document';
  const groupValidations = useMemo(() => {
    return pageGroups.map((pageGroup, index) => {
      const prevPageGroup = pageGroups[index - 1] ?? { start: '', end: '' };
      const prevPageGroupEnd = prevPageGroup.end ? +prevPageGroup.end : 0;
      let pageGroupStart = pageGroup.start ? +pageGroup.start : 0;
      let pageGroupEnd = pageGroup.end ? +pageGroup.end : 0;
      let errorText = '';
      if (prevPageGroupEnd >= pageGroupStart) {
        errorText = 'Start moet na vorig einde komen';
      }

      if (pageGroupStart > pageGroupEnd) {
        errorText = 'Einde moet na start komen';
      }

      return errorText;
    });
  }, [pageGroups]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <BaseButton isLoading={isLoading} color="primary" disabled={isDisabled} title={triggerText}>
          {triggerText}
        </BaseButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="dialog-overlay" />
        <Dialog.Content className="dialog-content">
          <Dialog.Title className="heading-one mb-4">Splits document op in stukken</Dialog.Title>

          <div>
            <div>
              <div className="flex justify-between items-center">
                <div className="font-medium">Groepen</div>

                <div>
                  <Button
                    onTrigger={() => {
                      setPageGroups((prevPageGroups) => {
                        const newPageGroups = [...prevPageGroups];
                        newPageGroups.push({
                          start: '',
                          end: '',
                        });
                        return newPageGroups;
                      });
                    }}
                  >
                    <PlusIcon className="button-icon" />
                  </Button>
                </div>
              </div>

              <div>
                {pageGroups.map((pageGroup, index) => {
                  const errorText = groupValidations[index];

                  return (
                    <div key={`page-group-${index}`}>
                      <div className="flex items-center gap-2 mb-2">
                        <InputWrapper labelText="Start" noLabel={true} hideInvalid={true}>
                          <Input
                            type="number"
                            value={pageGroup.start}
                            onChange={(newValue) => {
                              setPageGroups((prevPageGroups) => {
                                const newPageGroups = [...prevPageGroups];
                                newPageGroups[index].start = newValue;
                                return newPageGroups;
                              });
                            }}
                          />
                        </InputWrapper>
                        <InputWrapper labelText="Einde" noLabel={true} hideInvalid={true}>
                          <Input
                            type="number"
                            value={pageGroup.end}
                            onChange={(newValue) => {
                              setPageGroups((prevPageGroups) => {
                                const newPageGroups = [...prevPageGroups];
                                newPageGroups[index].end = newValue;
                                return newPageGroups;
                              });
                            }}
                          />
                        </InputWrapper>
                        <div className="self-end">
                          <Button
                            onTrigger={() => {
                              setPageGroups((prevPageGroups) => {
                                const newPageGroups = [...prevPageGroups];
                                newPageGroups.splice(index, 1);
                                return newPageGroups;
                              });
                            }}
                          >
                            <TrashIcon className="button-icon" />
                          </Button>
                        </div>
                      </div>

                      <div className="text-sm text-feedback-negative font-medium my-1">{errorText}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="grid grid-cols-3 font-medium">
              <div>Pagina</div>
              <div>Categorie</div>
              <div>Groep</div>
            </div>

            {pages.map((page, index) => {
              const categoryName =
                DOCUMENT_CATEGORY_VALUES.find((v) => v.key === page.prediction.category)?.name ??
                page.prediction.category;

              const groupIdx = pageGroups.findIndex((group) => {
                if (group.start && group.end) {
                  return index + 1 >= +group.start && index + 1 <= +group.end;
                } else {
                  return false;
                }
              });

              return (
                <div className="grid grid-cols-3 rounded" key={`cat-${index}`}>
                  <div>{page.pageIndex + 1}</div>
                  <div>{categoryName}</div>
                  <div>{groupIdx > -1 ? (groupIdx + 1).toString(10) : '-'}</div>
                </div>
              );
            })}

            <div className="mt-4">
              <div className="flex justify-end">
                <Button
                  color="primary"
                  isDisabled={isLoading || !!groupValidations.find((v) => !!v)}
                  isLoading={isLoading}
                  onTrigger={async () => {
                    setIsLoading(true);
                    try {
                      const parsedPageGroups = pageGroups
                        .filter((v) => v.start && v.end)
                        .map((pageGroup) => {
                          return {
                            start: +pageGroup.start,
                            end: +pageGroup.end,
                          };
                        });

                      await executeSplit(parsedPageGroups);
                      toast.success('Document is sucesvol in stukken gesplitst');
                      setOpen(false);
                    } catch (err) {
                      console.error(err);
                      toast.error('Er is een fout opgetreden bij het splitsen van het document', getDisplayError(err));
                    }

                    setIsLoading(false);
                  }}
                >
                  Split document
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
