import classNames from '@utils/classnames';
import React, { useId, useRef } from 'react';

export interface TextAreaProps {
  id?: string;
  value: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: () => void;
  onChange: (newValue: string) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  rows?: number;
  cols?: number;
  params: string[];
}

export const TemplateTextArea: React.FC<TextAreaProps> = (props) => {
  const { id: _id, value, onBlur, onFocus, onChange, isInvalid, isDisabled, placeholder, rows, cols, params } = props;
  const ref = useRef<HTMLTextAreaElement | null>(null);

  const handleClickParam = (param: string) => {
    onChange(`${value.slice(0, ref.current?.selectionStart)}{{${param}}}${value.slice(ref.current?.selectionEnd)}`);
  };

  const generatedId = useId();
  const id = _id ?? generatedId;
  return (
    <div>
      <div className="flex flex-wrap gap-2 mb-2">
        {params.map((p) => {
          return (
            <button
              type="button"
              className="px-2 py-1 bg-dark-05 hover:bg-dark-04 text-dark-01 rounded-lg"
              key={`${id}-${p}`}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();

                handleClickParam(p);
              }}
            >{`{{${p}}}`}</button>
          );
        })}
      </div>

      <div>
        <textarea
          ref={ref}
          id={id}
          rows={rows}
          cols={cols}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          onChange={(evt) => onChange(evt.target.value)}
          className={classNames('py-2 px-3 block w-full border rounded-lg focus:outline-none bg-offwhite', {
            'focus:border-indigo-500 border-feedback-negative ': isInvalid,
            'focus:border-indigo-500 border-dark-04 focus:border-dark-01': !isInvalid,
          })}
          disabled={isDisabled}
          autoComplete="off"
        />
      </div>
    </div>
  );
};
