import { IterableElement } from 'type-fest';
import { Link, useParams } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { GetAuditLogsQuery, useGetAuditLogQuery } from '../../../generated/graphql';
import { invariant, nullthrows } from '../../../utils/invariant';
import { DataField } from '../../../components/DataField';
import { Breadcrumb } from '../../../components/Breadcrumb';

export type AuditLog = NonNullable<IterableElement<GetAuditLogsQuery['auditLogs']>>;

export const AuditLogPage = () => {
  const { auditLogId } = useParams<{ auditLogId: string }>();
  invariant(auditLogId);
  console.log({ auditLogId });
  const [res] = useGetAuditLogQuery({
    variables: {
      id: auditLogId,
    },
  });

  const auditLog = nullthrows(res.data?.auditLog, 'Audit log not found');
  return (
    <>
      <PageHeader title="Audit Log" />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Audit Logs',
                to: '/internal/audit-logs',
              },
              {
                name: 'Audit Log',
              },
            ]}
          />
        </div>

        <div className="my-8 flex flex-col gap-2">
          <DataField title="Actor">{auditLog.actorName}</DataField>
          <DataField title="Actie">{auditLog.actionType}</DataField>
          <DataField title="Beschrijving">{auditLog.description}</DataField>
          {auditLog.order != null && (
            <DataField title="Order">
              <Link to={`/internal/orders/${auditLog.order.id}`} className="link-text">
                {auditLog.order.orderNumber ?? 'DRAFT'}
              </Link>
            </DataField>
          )}
          {auditLog.customer != null && (
            <DataField title="Klant">
              <Link to={`/internal/customers/${auditLog.customer.id}`} className="link-text">
                {auditLog.customer.name}
              </Link>
            </DataField>
          )}
          {auditLog.location != null && (
            <DataField title="Locatie">
              <Link to={`/internal/locations/${auditLog.location.id}`} className="link-text">
                {auditLog.location.name}
              </Link>
            </DataField>
          )}
          {auditLog.supplier != null && (
            <DataField title="Vervoerder">
              <Link to={`/internal/suppliers/${auditLog.supplier.id}`} className="link-text">
                {auditLog.supplier.name}
              </Link>
            </DataField>
          )}
          {auditLog.details && (
            <DataField title="Details">
              <div className="whitespace-pre-line bg-dark-05 rounded p-2">{auditLog.details}</div>
            </DataField>
          )}
        </div>
      </div>
    </>
  );
};
