import { SearchIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  CustomerPortalQuotationRequestsDocument,
  CustomerPortalQuotationRequestsQuery,
  CustomerPortalQuotationRequestsQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { useTranslation } from '../../../contexts/translation-context';
import { LinkButton } from '../../../components/button/ButtonLink';

export const CustomerPortalQuotationRequestsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const { i18n } = useTranslation();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<CustomerPortalQuotationRequestsQuery, CustomerPortalQuotationRequestsQueryVariables>(
          CustomerPortalQuotationRequestsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customerPortalQuotationRequests ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  const tableHeaders: ITableHeader[] = [
    {
      id: 'reference',
      name: i18n('customerPortal.quotes.quoteReference'),
    },
    {
      id: 'quote',
      name: i18n('customerPortal.quotes.quote'),
    },
    {
      id: 'date',
      name: i18n('customerPortal.quotes.requestedAt'),
    },
  ];

  const title = i18n('customerPortal.quotes.requests');
  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder={i18n('customerPortal.quotes.search')}
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>

            <div className="flex gap-2">
              <LinkButton to="new">{i18n('customerPortal.quotes.request')}</LinkButton>
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={tableHeaders}
          data={page.data}
          mapData={(request) => {
            return [
              <Link to={`${request.id}`} className="link-text">
                {request.reference ?? 'DRAFT'}
              </Link>,
              request.quotationId ? <Link to={`../${request.quotationId}`}>{request.reference}</Link> : 'n/a',
              formatDate(request.createdAt),
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
