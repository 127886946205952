import { Link, useParams } from 'react-router-dom';

import { PageHeader } from '../../../components/PageHeader';
import { invariant, nullthrows } from '../../../utils/invariant';
import { useCustomerPortalQuotationRequestQuery } from '../../../generated/graphql';
import { DataField } from '../../../components/DataField';
import { formatDate } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { useTranslation } from '../../../contexts/translation-context';

export const CustomerPortalQuotationRequestPage = () => {
  const { i18n } = useTranslation();
  const { quotationRequestId } = useParams<{ quotationRequestId: string }>();
  invariant(quotationRequestId);

  const [{ data }] = useCustomerPortalQuotationRequestQuery({
    variables: {
      id: quotationRequestId,
    },
  });

  const quotation = nullthrows(data?.customerPortalQuotationRequest, i18n('customerPortal.quotes.notFound'));
  const title = i18n('customerPortal.quotes.requestTitle', {
    reference: quotation.reference,
  });

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: i18n('customerPortal.quotes.requests'),
                to: '..',
              },
              {
                name: title,
              },
            ]}
          />
        </div>

        <div className="flex gap-4 mb-8">
          <DataField title={i18n('customerPortal.quotes.type')}>{quotation.type}</DataField>
          <DataField title={i18n('customerPortal.quotes.quoteReference')}>{quotation.reference}</DataField>
          <DataField title={i18n('customerPortal.quotes.requestedAt')}>{formatDate(quotation.createdAt)}</DataField>
          <DataField title={i18n('customerPortal.quotes.quote')}>
            {quotation.quotationId ? <Link to={`../../${quotation.quotationId}`}>{quotation.reference}</Link> : 'n/a'}
          </DataField>
        </div>

        {quotation.notes.length > 0 && (
          <div className="mb-4">
            <DataField title="Notities">
              <span className="whitespace-pre-wrap">{quotation.notes}</span>
            </DataField>
          </div>
        )}

        <div>
          <div className="grid grid-cols-8 font-medium mb-2">
            <div className="col-span-3">{i18n('customerPortal.quotes.departurePlace')}</div>
            <div className="col-span-3">{i18n('customerPortal.quotes.destination')}</div>
          </div>

          {quotation.lines.map((line) => {
            return (
              <div className="my-1" key={line.id}>
                <div className="grid grid-cols-8">
                  <div className="col-span-3">{`${line.departureCountry}${line.departurePostalCode} ${line.departureCity}`}</div>
                  <div className="col-span-3">{`${line.arrivalCountry}${line.arrivalPostalCode} ${line.arrivalCity}`}</div>
                </div>

                <div className="flex flex-wrap gap-2 mt-2">
                  {line.trailerTypes.map((trailerType) => {
                    return (
                      <div
                        className="bg-dark-05 rounded flex flex-col items-center justify-center px-2 py-1"
                        key={`${line.id}-${trailerType}`}
                      >
                        <TrailerTypeIcon type={trailerType} variant="small" />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
