import { Link } from 'react-router-dom';
import { PlusIcon, TrashIcon } from 'lucide-react';
import toast from 'react-hot-toast';

import { useAllGeneralSettings } from '../../all-settings-context';
import { MailboxTypeToText } from './constants';
import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { useDeleteMailboxMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LinkButton } from '../../../../components/button/ButtonLink';

export const MailboxesSettingsPage = () => {
  const { settings } = useAllGeneralSettings();
  const [, deleteMailbox] = useDeleteMailboxMutation();

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="heading-two">Mailboxen</h2>
        <div>
          <LinkButton to="configure" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
            Nieuwe Mailbox
          </LinkButton>
        </div>
      </div>
      <div>
        {!settings.mailboxes.length && 'Geen mailboxen geconfigureerd'}
        {!!settings.mailboxes.length && (
          <div className="grid gap-4 lg:grid-cols-2">
            {settings.mailboxes.map((v) => {
              return (
                <Link to={v.id} key={v.id}>
                  <div className="card" data-clickable="true">
                    <div className="flex justify-between">
                      <div className="card-heading-text">{v.name}</div>
                      <div className="flex gap-4">
                        <div className="card-subtle-text">{MailboxTypeToText[v.type]}</div>
                        <div>
                          <ConfirmDialog
                            triggerText={<TrashIcon className="button-icon" />}
                            title={`Ben je zeker dat je de mailbox ${v.name} wilt verwijderen?`}
                            submitText="Mailbox verwijderen"
                            description={`Ben je zeker dat je de mailbox ${v.name} wilt verwijderen? Om dit ongedaan te maken zal je de mailbox opnieuw moeten aanmaken.`}
                            onSubmit={async () => {
                              try {
                                const result = await deleteMailbox({
                                  mailboxId: v.id,
                                });
                                if (result.error) {
                                  throw result.error;
                                }
                                toast.success('Mailbox verwijderd');
                              } catch (err) {
                                toast.error(`Kon mailbox niet verwijderen: ${getDisplayError(err)}`);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-subtle-text">{v.imapUser}</div>
                    <div className="card-subtle-text">{v.imapHost}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
