import {
  useCustomersWithPendingOrdersQuery,
  useGetTotalPendingCustomerOrdersCountQuery,
} from '../../../../../generated/graphql';
import { CUSTOMER_TYPE_VALUES } from '../../../../customer/select-values';
import { useCreateInvoiceCtx } from './context';

export interface ICustomerSelectProps {
  searchValue: string;
}

export const CustomerSelect: React.FC<ICustomerSelectProps> = (props) => {
  const { searchValue } = props;
  const { dispatch } = useCreateInvoiceCtx();
  const [totalsData] = useGetTotalPendingCustomerOrdersCountQuery({
    requestPolicy: 'network-only',
  });
  const [data] = useCustomersWithPendingOrdersQuery({
    requestPolicy: 'network-only',
    variables: {
      query: searchValue || undefined,
    },
  });

  const customers = data.data?.customersWithPendingOrders ?? [];
  if (!customers.length) {
    return <div>Geen klanten met openstaande orders gevonden.</div>;
  }

  const totals = totalsData.data?.totalPendingCustomerOrdersCount ?? {
    pendingOrders: 0,
    pendingCmrOrders: 0,
  };

  return (
    <div>
      <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
        {customers
          .sort((a, b) => b.pendingOrders - a.pendingOrders)
          .map((c) => {
            return (
              <div
                key={c.customer.id}
                className="card"
                data-clickable="true"
                onClick={() => {
                  dispatch({
                    type: 'SELECT_CUSTOMER',
                    customer: c,
                  });
                }}
              >
                <div className="flex justify-between">
                  <div className="card-heading-text">{c.customer.name}</div>
                  <div className="card-subtle-text">{c.customer.vatNumber}</div>
                </div>

                <div className="card-subtle-text mb-4">
                  {CUSTOMER_TYPE_VALUES.find((v) => v.key === c.customer.type)?.name}
                </div>

                <div>{`${c.customer.street} ${c.customer.streetNumber}`}</div>
                <div>{`${c.customer.postalCode} ${c.customer.city}, ${c.customer.country}`}</div>

                <div className="font-medium mt-2 text-orange-01">
                  {c.pendingOrders} openstaande orders ({c.pendingCmrOrders} met CMR)
                </div>
              </div>
            );
          })}
      </div>

      <div className="my-4">
        {`Totaal: ${totals.pendingOrders} openstaande orders (${totals.pendingCmrOrders} met CMR)`}
      </div>
    </div>
  );
};
