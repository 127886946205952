import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { StatusText } from '../../../components/StatusText';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetIncomingDocumentsDocument,
  GetIncomingDocumentsQuery,
  GetIncomingDocumentsQueryVariables,
  IncomingDocumentStatus,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDateTime } from '../../../utils/date';
import {
  IncomingDocumentStatusToColor,
  IncomingDocumentStatusToText,
  IncomingDocumentTypeToText,
} from '../utils/status';
import { SimpleSelect } from '../../../components/select/SimpleSelect';
import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { INCOMING_DOCUMENT_STATUS_OPTIONS } from '../constants';

const TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'name',
    name: 'Naam bijlage',
  },
  {
    id: 'subject',
    name: 'Onderwerp e-mail',
  },
  {
    id: 'type',
    name: 'Type document',
  },
  {
    id: 'isProcessed',
    name: 'Status',
  },
  {
    id: 'date',
    name: 'Datum',
  },
];

const IncomingCmrsPage = () => {
  const client = useClient();
  const [selectedDocStatus, setSelectedDocStatus] = useState<ISimpleComboboxItem | null>(
    INCOMING_DOCUMENT_STATUS_OPTIONS.find((v) => v.key === IncomingDocumentStatus.New) ?? null,
  );
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetIncomingDocumentsQuery, GetIncomingDocumentsQueryVariables>(
          GetIncomingDocumentsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              status: selectedDocStatus ? [selectedDocStatus?.key as IncomingDocumentStatus] : undefined,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.incomingDocuments ?? [];
    },
    [client, selectedDocStatus],
  );

  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [selectedDocStatus]);

  return (
    <>
      <PageHeader title="Inkomende Documenten" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Inkomende Documenten</h1>
        </div>

        <div className="flex justify-end items-end gap-4 mb-4">
          <div className="w-48">
            <label>
              <div className="label-text">Status</div>
              <SimpleSelect
                selectedItem={selectedDocStatus}
                items={[...INCOMING_DOCUMENT_STATUS_OPTIONS]}
                isOptional={true}
                onSelect={(val) => {
                  if (val?.key === selectedDocStatus?.key) {
                    setSelectedDocStatus(null);
                  } else {
                    setSelectedDocStatus(val);
                  }
                }}
              />
            </label>
          </div>
        </div>

        <Table
          idKey="id"
          headers={TABLE_HEADERS}
          data={page.data}
          mapData={(incomingDoc) => {
            return [
              <Link to={`${incomingDoc.id}`} className="link-text">
                {incomingDoc.document.name}
              </Link>,
              incomingDoc.subject,
              IncomingDocumentTypeToText[incomingDoc.type],
              <StatusText color={IncomingDocumentStatusToColor[incomingDoc.status]}>
                {IncomingDocumentStatusToText[incomingDoc.status]}
              </StatusText>,
              formatDateTime(incomingDoc.date),
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default IncomingCmrsPage;
