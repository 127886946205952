import { PlusIcon, TrashIcon } from 'lucide-react';
import { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ConfirmDialog } from '../../../../components/dialog/ConfirmDialog';
import { ITableHeader, Table } from '../../../../components/table/Table';
import { TableActionBar } from '../../../../components/table/TableActionBar';
import { useDeleteOrderLinePurchaseMutation } from '../../../../generated/graphql';
import { useUniqueArrayReducer } from '../../../../hooks/useUniqueArrayReducer';
import { getDisplayError } from '../../../../utils/get-display-error';
import { formatNumber } from '../../../../utils/number';
import { calculateLineTotal } from '../../utils/price';
import { Order } from '../order/Order';
import { useOrderLine } from '../orderLine/orderLineContext';
import { LinkButton } from '../../../../components/button/ButtonLink';

export type OrderLinePurchase = IterableElement<IterableElement<Order['lines']>['purchases']>;

const ORDERLINE_HEADERS: ITableHeader[] = [
  {
    id: 'product',
    name: 'Artikel',
  },
  {
    id: 'amount',
    name: 'Hoeveelheid',
  },
  {
    id: 'unitPrice',
    name: 'Prijs',
  },
  {
    id: 'vatRate',
    name: 'Btw',
  },
  {
    id: 'total',
    name: 'Totaal',
  },
  {
    id: 'delete',
    name: '',
  },
];

export const OrderPurchasesPage = () => {
  const { orderLine } = useOrderLine();
  const [_res, deleteOrderLinePurchase] = useDeleteOrderLinePurchaseMutation();
  const [, mutatePendingDeletion] = useUniqueArrayReducer<number>();

  const purchases: OrderLinePurchase[] = orderLine.purchases;

  const lines: Array<OrderLinePurchase | 'total'> = useMemo(() => {
    return [...purchases, 'total'];
  }, [purchases]);

  const total = useMemo(() => {
    return purchases.reduce((acc, item) => {
      return acc + calculateLineTotal(item);
    }, 0);
  }, [purchases]);

  return (
    <div>
      <TableActionBar>
        <div className="flex justify-end">
          <div className="flex">
            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                Voeg aankoop toe
              </LinkButton>
            </div>
          </div>
        </div>
      </TableActionBar>
      <Table
        idKey="id"
        headers={ORDERLINE_HEADERS}
        data={lines}
        mapData={(data) => {
          if (data === 'total') {
            return [
              <span className="font-medium">Totaal</span>,
              '',
              '',
              '',
              <span className="font-medium whitespace-nowrap">{`€ ${formatNumber(total, 2, {
                decimalSeperator: ',',
              })}`}</span>,
              '',
            ];
          } else {
            const deleteLine = async () => {
              mutatePendingDeletion({
                type: 'add',
                value: data.id,
              });
              try {
                const result = await deleteOrderLinePurchase({
                  id: data.id,
                });
                if (result.error) {
                  throw result.error;
                }
                toast.success('Order verkoop lijn gewist');
              } catch (err) {
                toast.error('Kon order verkoop lijn niet wissen: ' + getDisplayError(err));
              }
              mutatePendingDeletion({
                type: 'delete',
                value: data.id,
              });
            };

            return [
              <Link to={`${data.id}`} className="link-text">
                {data.productType.nameNl}
              </Link>,
              <div className="whitespace-nowrap">
                {`${formatNumber(data.amount, 2, {
                  decimalSeperator: ',',
                })}`}
              </div>,
              <div className="whitespace-nowrap">{`€ ${formatNumber(data.unitPrice, 2, {
                decimalSeperator: ',',
              })}`}</div>,
              <div className="whitespace-nowrap">{`${formatNumber(data.vatRatePercentage, 2, {
                decimalSeperator: ',',
              })}%`}</div>,
              <div className="whitespace-nowrap">{`€ ${formatNumber(calculateLineTotal(data), 2, {
                decimalSeperator: ',',
              })}`}</div>,
              <ConfirmDialog
                triggerText={<TrashIcon className="button-icon" />}
                title="Verwijder aankoop"
                submitText="Verwijder"
                description={<div>{`Ben je zeker dat je deze aankoop ${data.productType.nameNl} wilt wissen?`}</div>}
                onSubmit={deleteLine}
              />,
            ];
          }
        }}
        isMultiSelect
      />
    </div>
  );
};
