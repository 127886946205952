import { SearchIcon, PlusIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IterableElement } from 'type-fest';
import { useClient } from 'urql';

import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { GetSuppliersDocument, GetSuppliersQuery, GetSuppliersQueryVariables } from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { LinkButton } from '../../../components/button/ButtonLink';
import classNames from '../../../utils/classnames';

type Supplier = NonNullable<IterableElement<GetSuppliersQuery['suppliers']>>;

const SuppliersPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetSuppliersQuery, GetSuppliersQueryVariables>(
          GetSuppliersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.suppliers ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  return (
    <>
      <PageHeader title="Vervoerders" />

      <div className="page-heading">
        <h1 className="heading-one">Vervoerders</h1>

        <div className="flex gap-4">
          <div className="flex w-96">
            <Input
              type="text"
              placeholder="Zoek een vervoerder..."
              value={searchValue}
              onChange={setSearchValue}
              iconLeft={<SearchIcon className="input-icon" />}
            />
          </div>
          <div>
            <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
              Nieuwe vervoerder
            </LinkButton>
          </div>
        </div>
      </div>

      <div>
        <div className="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3 mb-4 mt-8">
          {page.data.map((supplier) => {
            return (
              <Link to={`${supplier.id}/general`} key={supplier.id}>
                <div
                  className={classNames('card', {
                    'card-deactivated': !!supplier.deactivationReason,
                  })}
                  data-clickable="true"
                >
                  <div className="flex flex-col mb-4">
                    <div className="card-heading-text">{supplier.name}</div>
                    <div className="card-subtle-text">
                      {supplier.vatNumber}
                      {!!supplier.deactivationReason && ' - Gedeactiveerd'}
                    </div>
                  </div>

                  <div>{`${supplier.street} ${supplier.streetNumber}`}</div>
                  <div>{`${supplier.postalCode} ${supplier.city}, ${supplier.country}`}</div>
                </div>
              </Link>
            );
          })}
        </div>

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default SuppliersPage;
