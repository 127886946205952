import { useTranslation } from '../../contexts/translation-context';
import { Button } from '../button/Button';

export interface IPaginationProps {
  hasPrevious: boolean;
  previous: () => void;
  hasNext: boolean;
  next: () => void;
  isFetching: boolean;
}

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const { hasPrevious, previous, hasNext, next, isFetching } = props;
  const { i18n } = useTranslation();

  return (
    <div className="flex justify-between">
      <div>
        <Button isDisabled={isFetching || !hasPrevious} onTrigger={previous} isLoading={isFetching}>
          {i18n('previous')}
        </Button>
      </div>
      <div>
        <Button color="primary" isDisabled={isFetching || !hasNext} onTrigger={next} isLoading={isFetching}>
          {i18n('next')}
        </Button>
      </div>
    </div>
  );
};
