import { BanknoteIcon, TruckIcon, UsersIcon, SettingsIcon, MailIcon, MapPinIcon } from 'lucide-react';
import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../contexts/auth-context';
import { SpinnerBlock } from '../../components/Spinner';
import { useTranslation } from '../../contexts/translation-context';
import { Avatar } from '../../components/avatar/Avatar';

export interface ICustomerPortalDashboardProps {
  children: React.ReactNode;
}

export const CustomerPortalDashboard: React.FC<ICustomerPortalDashboardProps> = (props) => {
  const { children } = props;
  const { me } = useAuth();
  const { i18n, lang } = useTranslation();

  const navigation = useMemo(() => {
    const routes = [];
    routes.push(
      { name: i18n('customerPortal.orders.orders'), to: './orders', icon: TruckIcon },
      { name: i18n('customerPortal.locations.locations'), to: './locations', icon: MapPinIcon },
      { name: i18n('customerPortal.quotes.quotes'), to: './quotations', icon: BanknoteIcon },
      { name: i18n('customerPortal.invoices.invoices'), to: './invoices', icon: BanknoteIcon },
      {
        name: i18n('customerPortal.businessInformation.businessInformation'),
        to: './business-information',
        icon: SettingsIcon,
      },
      { name: i18n('customerPortal.contacts.contacts'), to: './contacts', icon: MailIcon },
      { name: i18n('customerPortal.users.users'), to: './users', icon: UsersIcon },
    );
    return routes;
  }, [i18n, lang]);

  return (
    <div className="grid h-screen" style={{ gridTemplateColumns: '20vw 1fr' }}>
      <div className="flex flex-col overflow-y-auto">
        <div className="flex flex-grow flex-col bg-dark-01 border-r pt-5 border-box">
          <div className="mb-4">
            <Link to="/customer-portal">
              <img style={{ width: '12rem', height: '5rem' }} className="ml-5" src="/static/logo.svg" />
            </Link>
          </div>

          <div className="sidebar-item">
            <div className="sidebar-item-icon-container">
              <Avatar name={me.name} url={me.image} />
            </div>

            <div className="flex-1 flex justify-between h-full">
              <Link to="me" className="flex-1 h-full flex items-center overflow-hidden px-4 hover:bg-orange-02">
                {me.name}
              </Link>
            </div>
          </div>

          <div className="flex flex-1 flex-col">
            <nav className="flex-1">
              {navigation.map((item) => {
                return (
                  <Link key={item.name} to={item.to} className="sidebar-item hoverable-sidebar-item">
                    <div className="sidebar-item-icon-container">
                      <item.icon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    </div>
                    <div className="sidebar-item-text-container">{item.name}</div>
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </div>

      <div className="overflow-y-auto">
        <main className="px-16 py-1 pb-16">
          <Suspense fallback={<SpinnerBlock message={i18n('customerPortal.loading')} />}>{children}</Suspense>
        </main>
      </div>
    </div>
  );
};
