import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';

import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetSupplierSelfBillsDocument,
  GetSupplierSelfBillsQuery,
  GetSupplierSelfBillsQueryVariables,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDateTime } from '../../../utils/date';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Input } from '../../../components/input/Input';
import { SearchIcon } from 'lucide-react';

const SELF_BILL_HEADERS: ITableHeader[] = [
  {
    id: 'entryNumber',
    name: 'ID',
  },
  {
    id: 'supplier',
    name: 'Vervoerder',
  },
  {
    id: 'emailSentAt',
    name: 'Email verzonden op',
  },
  {
    id: 'createdAt',
    name: 'Datum',
  },
  {
    id: 'dueDate',
    name: 'Vervaldatum',
  },
];

const SelfBillingsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetSupplierSelfBillsQuery, GetSupplierSelfBillsQueryVariables>(
          GetSupplierSelfBillsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters: {
              search: searchValue,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.supplierSelfBills ?? [];
    },
    [client, searchValue],
  );

  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  const title = 'Self Billing';

  return (
    <>
      <PageHeader title={title} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Financieel',
                to: '/internal/finance',
              },
              {
                name: title,
              },
            ]}
          />

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder="Zoek een self billing..."
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>
          </div>
        </div>

        <Table
          idKey="id"
          headers={SELF_BILL_HEADERS}
          data={page.data}
          mapData={(selfBill) => {
            return [
              <Link to={`${selfBill.id}`} className="link-text">
                {selfBill.entryNumber}
              </Link>,
              selfBill.supplier.name,
              selfBill.emailSentAt ? formatDateTime(selfBill.emailSentAt) : '-',
              formatDateTime(selfBill.createdAt),
              formatDateTime(selfBill.dueDate),
            ];
          }}
        />

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};

export default SelfBillingsPage;
