import { PlusIcon } from 'lucide-react';
import { Button } from 'components/button/Button';
import { TextAreaField } from 'components/textarea/TextAreaField';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { OrderLineStopType } from 'generated/graphql';
import { invariant } from '@utils/invariant';
import * as Yup from 'yup';

import { InputField } from '../../../../components/input/InputField';
import { ISimpleSelectItem } from '../../../../components/select/SimpleSelect';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { formatInputDate, parseInputTime } from '../../../../utils/date';
import { Location, LocationComboboxField } from '../../../location/LocationCombobox';
import { STOP_TYPE_ITEMS } from './constants';
import { isRecent, validateLoadTime } from './utils';

export const createOrderLineStopSchema = Yup.object().shape({
  location: Yup.mixed().nullable().required('Vereist'),
  date: Yup.string()
    .required('Vereist')
    .test('isRecent', 'Datum ligt te ver in het verleden', function () {
      const value = this.parent[this.path];
      return isRecent(value);
    }),
  timeStart: Yup.string()
    .required('Vereist')
    .test('isBefore', 'Start tijd moet voor stop tijd vallen', function () {
      return validateLoadTime(this.parent);
    }),
  timeEnd: Yup.string()
    .required('Vereist')
    .test('isBefore', 'Start tijd moet voor stop tijd vallen', function () {
      return validateLoadTime(this.parent);
    }),
  reference: Yup.string().required('Vereist'),
  notes: Yup.string(),
});

export interface IOrderLineStopValues {
  type: ISimpleSelectItem | null;
  location: Location | null;
  date: string;
  timeStart: string;
  timeEnd: string;
  reference: string;
  notes: string;
}

export const initialValues: IOrderLineStopValues = {
  type: null,
  location: null,
  date: '',
  timeStart: '',
  timeEnd: '',
  reference: '',
  notes: '',
};

export interface INormalizedOrderLineStopValues {
  type: OrderLineStopType;
  location: Location;
  date: string;
  timeStart: number;
  timeEnd: number;
  reference: string;
  notes: string;
}

export interface ICreateOrderLineStopProps {
  initialType?: OrderLineStopType;
  initialDate?: string;
  onCancel: () => void;
  onComplete: (values: INormalizedOrderLineStopValues) => Promise<void>;
}

const CreateOrderLineStopForm: React.FC<ICreateOrderLineStopProps> = (props) => {
  const { initialType, initialDate, onCancel, onComplete } = props;
  const formikbag = useFormik({
    initialValues: {
      ...initialValues,
      type: STOP_TYPE_ITEMS.find((v) => v.key === (initialType ?? OrderLineStopType.Load)) ?? null,
      date: formatInputDate(initialDate ?? initialValues.date),
    },
    validationSchema: createOrderLineStopSchema,
    onSubmit: async (values) => {
      const { location, timeStart, timeEnd, type, ...otherValues } = values;
      invariant(location);
      invariant(type);
      await onComplete({
        type: type.key as OrderLineStopType,
        location,
        timeStart: parseInputTime(timeStart),
        timeEnd: parseInputTime(timeEnd),
        ...otherValues,
      });
    },
  });

  const isDateInWeekend = formikbag.values.date
    ? dayjs(formikbag.values.date).day() === 0 || dayjs(formikbag.values.date).day() === 6
    : false;

  const { handleSubmit, isSubmitting } = formikbag;
  return (
    <FormikProvider value={formikbag}>
      <form onSubmit={handleSubmit} className="block w-full">
        <SimpleSelectField labelText="Type stop" items={STOP_TYPE_ITEMS} name="type" />
        <LocationComboboxField labelText="Locatie" name="location" isDisabled={isSubmitting} />
        {isDateInWeekend && <div className="font-medium text-orange-01">Waarschuwing: datum valt in een weekend.</div>}
        <div className="flex gap-2">
          <div className="flex-1">
            <InputField labelText="Datum" type="date" name="date" isDisabled={isSubmitting} />
          </div>
          <div className="flex gap-2 flex-1">
            <InputField labelText="Start" type="time" name="timeStart" isDisabled={isSubmitting} />
            <InputField labelText="Stop" type="time" name="timeEnd" isDisabled={isSubmitting} />
          </div>
        </div>
        <InputField labelText="Referentie" type="text" name="reference" isDisabled={isSubmitting} />
        <TextAreaField labelText="Notities" name="notes" isDisabled={isSubmitting} spellCheck={true} />

        <div className="flex justify-between">
          <Button color="secondary" isDisabled={isSubmitting} isLoading={isSubmitting} onTrigger={onCancel}>
            Annuleer
          </Button>

          <Button
            type="submit"
            color="primary"
            iconLeft={<PlusIcon className="button-icon" />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Maak stop aan
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

export default CreateOrderLineStopForm;
