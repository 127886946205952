import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useCustomerPortalCreateLocationMutation } from '../../../generated/graphql';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { getDisplayError } from '../../../utils/get-display-error';
import { CustomerPortalCreateLocationForm } from './CustomerPortalCreateLocationForm';
import { useTranslation } from '../../../contexts/translation-context';

export const CustomerPortalCreateLocationPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [, createLocationMutation] = useCustomerPortalCreateLocationMutation();

  return (
    <>
      <PageHeader title={i18n('customerPortal.locations.newLocation')} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: i18n('customerPortal.locations.locations'),
                to: '..',
              },
              {
                name: i18n('customerPortal.locations.newLocation'),
              },
            ]}
          />
        </div>

        <CustomerPortalCreateLocationForm
          onSubmit={async (values) => {
            try {
              const result = await createLocationMutation({
                data: values,
              });
              if (result.error) {
                throw result.error;
              }
              toast.success(i18n('customerPortal.locations.locationCreated'));
              navigate('..');
            } catch (err: any) {
              toast.error(
                i18n('customerPortal.locations.couldNotCreateLocation', {
                  error: getDisplayError(err),
                }),
              );
            }
          }}
        />
      </div>
    </>
  );
};
