import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';
import diff from 'object-diff';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { COUNTRY_VALUES } from '@utils/address';
import * as Yup from 'yup';

import { Button } from '../../../../components/button/Button';
import { CheckboxField } from '../../../../components/checkbox/CheckboxField';
import { InputField } from '../../../../components/input/InputField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { TextAreaField } from '../../../../components/textarea/TextAreaField';
import { Country, Language, useUpdateLocationMutation } from '../../../../generated/graphql';
import { getDisplayError } from '../../../../utils/get-display-error';
import { LANGUAGE_VALUES } from '../../../../utils/language';
import { useLocation } from './locationContext';
import { AutocompletePostalcode } from '../../components/AutocompletePostalcode';

const updateLocationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Vereist').required('Vereist'),
  street: Yup.string().min(1, 'Vereist').required('Vereist'),
  streetNumber: Yup.string(),
  city: Yup.string().min(1, 'Vereist').required('Vereist'),
  postalCode: Yup.string().min(1, 'Vereist').required('Vereist'),
  country: Yup.mixed().nullable().required('Vereist'),
  language: Yup.mixed().nullable().required('Vereist'),
});

export const UpdateLocationForm = () => {
  const { location } = useLocation();
  const [, updateLocationMutation] = useUpdateLocationMutation();

  const initialValues = useMemo(() => {
    return {
      name: location.name,
      street: location.street,
      streetNumber: location.streetNumber,
      city: location.city,
      postalCode: location.postalCode,
      country: COUNTRY_VALUES.find((v) => v.key === location.country)!,
      language: LANGUAGE_VALUES.find((v) => v.key === location.language)!,
      internalNotes: location.internalNotes,
      externalNotes: location.externalNotes,
      isExternalBooking: location.isExternalBooking,
    };
  }, [location]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={updateLocationSchema}
        onSubmit={async (newValues) => {
          try {
            const patch: Partial<typeof newValues> = diff(initialValues, newValues);
            const updateData = {
              ...patch,
              country: patch.country ? (patch.country.key as Country) : undefined,
              language: patch.language ? (patch.language.key as Language) : undefined,
            };
            if (Object.values(updateData).length) {
              const result = await updateLocationMutation({
                id: location.id,
                data: updateData,
              });
              if (result.error) {
                throw result.error;
              }
            }
            toast.success('Locatie aangepast');
          } catch (err: any) {
            toast.error('Kon locatie niet aanpassen: ' + getDisplayError(err));
          }
        }}
      >
        {({ handleSubmit, isSubmitting, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <InputField labelText="Naam" type="text" name="name" />
              <div className="flex gap-4">
                <div style={{ flex: 4 }}>
                  <InputField labelText="Straat" type="text" name="street" />
                </div>
                <div style={{ flex: 1 }}>
                  <InputField labelText="Nr" type="text" name="streetNumber" />
                </div>
              </div>

              <AutocompletePostalcode />

              <SimpleSelectField labelText="Taal" items={LANGUAGE_VALUES} name="language" />
              <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} />
              <TextAreaField labelText="Externe notities (voor vervoerder)" name="externalNotes" spellCheck={true} />
              <CheckboxField labelText="Externe boeking" name="isExternalBooking" />

              <Button
                type="submit"
                color="primary"
                iconLeft={<PlusIcon className="button-icon" />}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Pas locatie aan
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
