import React from 'react';
import classNames from '@utils/classnames';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CalendarDaysIcon, ClockIcon } from 'lucide-react';
import toast from 'react-hot-toast';

import { PageHeader } from 'components/PageHeader';
import {
  GetSupplierSelfBillQuery,
  OrderLineStopType,
  useGenerateSelfBillMutation,
  useGetSupplierSelfBillQuery,
  useSendSelfBillMutation,
} from 'generated/graphql';
import { invariant, nullthrows } from '@utils/invariant';

import { Breadcrumb } from '../../../components/Breadcrumb';
import { DataField } from '../../../components/DataField';
import { formatDate, formatInputTime } from '../../../utils/date';
import { calculateInvoiceTotals } from '../../sale/utils/calculate';
import { extractIntFromText, formatNumber } from '../../../utils/number';
import { FileUploaderButton } from '../../document/components/FileUploaderButton';
import { ContactsDialog } from '../../../components/dialog/ContactsDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';

export type SelfBill = NonNullable<GetSupplierSelfBillQuery['supplierSelfBill']>;

const SelfBillPage = () => {
  const navigate = useNavigate();
  const { selfBillId } = useParams<{ selfBillId: string }>();
  invariant(selfBillId);
  const [{ data, error }, refetch] = useGetSupplierSelfBillQuery({
    variables: {
      id: selfBillId,
    },
  });
  const [_sendSelfBillState, sendSelfBill] = useSendSelfBillMutation();
  const [_regenerateSelfbillState, regenerateSelfbill] = useGenerateSelfBillMutation();

  const selfBill = nullthrows(data?.supplierSelfBill, 'Self billing not found');
  const billTotals = calculateInvoiceTotals(
    selfBill.orders
      .map((o) =>
        o.lines
          .map((l) =>
            l.purchases.map((p) => {
              return {
                ...p,
                vatRateId: p.vatRate.id,
              };
            }),
          )
          .flat(),
      )
      .flat(),
  );
  return (
    <>
      <PageHeader title={`Self Bill ${selfBill.entryNumber}`} />

      <div>
        <div className="page-heading">
          <Breadcrumb
            items={[
              {
                name: 'Self Billing',
                to: '/internal/finance/self-billing',
              },
              {
                name: selfBill.entryNumber,
              },
            ]}
          />
        </div>

        {selfBill.generatedTotalExclVat !== billTotals.totalExclVat && (
          <div className="my-4 bg-feedback-negative py-1 px-2 rounded text-white">
            {`Totaal op pdf komt niet meer overeen met waarden op deze pagina: Totaal op PDF €${formatNumber(
              selfBill.generatedTotalExclVat,
              2,
              {
                decimalSeperator: ',',
              },
            )}, totaal op deze pagina €${formatNumber(billTotals.totalExclVat, 2, {
              decimalSeperator: ',',
            })}.`}
          </div>
        )}

        <div className="flex justify-between">
          <div className="flex flex-wrap gap-6 mb-4">
            <DataField title="Vervoerder">
              {selfBill.supplier.name} ({selfBill.supplier.id})
            </DataField>
            <DataField title="Factuur datum">{formatDate(selfBill.createdAt)}</DataField>
          </div>

          <div className="flex gap-2">
            <ConfirmDialog
              triggerText="Genereer opnieuw"
              title="Genereer de self-billing pdf opnieuw?"
              submitText="Genereer"
              description="Ben je zeker dat je de self-billing pdf opnieuw wilt genereren?"
              onSubmit={async () => {
                try {
                  const res = await regenerateSelfbill({
                    selfBillingId: selfBill.id,
                  });
                  if (res.error) {
                    throw res.error;
                  }
                  toast.success('Self-billing gegenereerd');
                } catch (err) {
                  toast.error(`Kon self-billing niet genereren: ${getDisplayError(err)}`);
                }
              }}
            />

            <FileUploaderButton
              buttonText="Factuur"
              title="Self Billing Invoice"
              file={selfBill.document}
              disableUpload
            />

            <ContactsDialog
              triggerText="Verzend Self billing"
              triggerColor="primary"
              title="Verzend Self billing"
              submitText="Verzend"
              description={`Ben je zeker dat je de Self billing naar ${selfBill.supplier?.name} wilt verzenden?`}
              contacts={selfBill.supplier.contacts.map((c) => {
                return {
                  id: '' + c.id,
                  name: c.name,
                  email: c.email,
                };
              })}
              initialValue={selfBill.supplier.contacts.filter((v) => v.shouldReceiveSelfBillings).map((v) => '' + v.id)}
              onSubmit={async (contacts) => {
                try {
                  const res = await sendSelfBill({
                    selfBillingId: selfBill.id,
                    contacts: contacts.map((c) => +c),
                  });
                  if (res.error) {
                    throw res.error;
                  }
                  toast.success('Self billing verstuurd');
                } catch (err: any) {
                  toast.error('Kon self billing niet versturen: ' + getDisplayError(err));
                  throw err;
                }
              }}
            />
          </div>
        </div>

        <div>
          {selfBill.orders
            .sort((a, b) => extractIntFromText(a.orderNumber || '') - extractIntFromText(b.orderNumber || ''))
            .map((order) => {
              const orderTotals = calculateInvoiceTotals(
                order.lines
                  .map((l) =>
                    l.purchases.map((p) => {
                      return {
                        ...p,
                        vatRateId: p.vatRate.id,
                      };
                    }),
                  )
                  .flat(),
              );

              return (
                <div key={`order-${order.orderNumber}`}>
                  <Link to={`/internal/orders/${order.id}/general`} className="heading-two mb-4">
                    Order {order.orderNumber}
                  </Link>

                  <div>
                    {order.lines.map((line) => {
                      return (
                        <div key={`line-${line.id}`}>
                          <div>
                            {line.stops
                              .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                              .map((stop) => {
                                return (
                                  <div className="grid grid-cols-4" key={`line-${line.id}-${stop.id}`}>
                                    <div className="font-medium">
                                      {stop.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}
                                    </div>
                                    <div>
                                      {stop.location.country}-{stop.location.name}
                                    </div>

                                    <div className="flex gap-2 items-center">
                                      <CalendarDaysIcon className="w-4 h-4" />
                                      {formatDate(stop.date)}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                      <ClockIcon className="w-4 h-4" />
                                      {`${formatInputTime(stop.timeStart)}-${formatInputTime(stop.timeEnd)}`}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="border-t py-4 my-4 border-dark-04">
                            <div className="grid grid-cols-5 gap-x-4 mb-1">
                              <div className="bold-label">Beschrijving</div>
                              <div className="bold-label">Aantal</div>
                              <div className="bold-label">Eenheidsprijs</div>
                              <div className="bold-label">Totaal</div>
                              <div className="bold-label">Btw</div>
                            </div>

                            <div className="grid grid-cols-5 gap-x-4 gap-y-1">
                              {line.purchases.map((purchaseLine) => {
                                return (
                                  <React.Fragment key={`sale-${purchaseLine.id}`}>
                                    <div>{purchaseLine.productType.nameNl}</div>
                                    <div>
                                      {formatNumber(purchaseLine.amount, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div className="whitespace-nowrap">
                                      €{' '}
                                      {formatNumber(purchaseLine.unitPrice, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div className="whitespace-nowrap">
                                      €{' '}
                                      {formatNumber((purchaseLine.amount * purchaseLine.unitPrice) / 100, 2, {
                                        decimalSeperator: ',',
                                      })}
                                    </div>
                                    <div
                                      className={classNames('whitespace-nowrap', {
                                        'text-feedback-negative':
                                          purchaseLine.vatRate.id !== selfBill.supplier.defaultVatRate.id,
                                      })}
                                    >
                                      {`€ ${formatNumber(
                                        (purchaseLine.amount *
                                          purchaseLine.unitPrice *
                                          purchaseLine.vatRatePercentage) /
                                          1000000,
                                        2,
                                        {
                                          decimalSeperator: ',',
                                        },
                                      )} - ${formatNumber(purchaseLine.vatRatePercentage, 2, {
                                        decimalSeperator: ',',
                                      })}%`}
                                    </div>

                                    {!!purchaseLine.externalNote && (
                                      <div className="col-span-5">
                                        <div className="text-sm">Extra informatie: {purchaseLine.externalNote}</div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="bold-label border-b border-dark-04 pb-4 my-4 whitespace-nowrap">
                      {`Order totaal: € ${formatNumber(orderTotals.totalExclVat, 2, {
                        decimalSeperator: ',',
                      })}`}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="grid grid-cols-2">
          <div></div>
          <div className="grid grid-cols-2 text-md gap-x-4">
            <div className="bold-label">Totaal excl. btw:</div>
            <div>{`€ ${formatNumber(billTotals.totalExclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
            <div className="bold-label">Totaal btw:</div>
            <div>{`€ ${formatNumber(billTotals.totalInclVat - billTotals.totalExclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
            <div className="bold-label">Totaal incl. btw:</div>
            <div>{`€ ${formatNumber(billTotals.totalInclVat, 2, {
              decimalSeperator: ',',
            })}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelfBillPage;
