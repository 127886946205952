import { Suspense, useEffect, useState } from 'react';

import { PageHeader } from '../../../components/PageHeader';
import { Spinner } from '../../../components/Spinner';
import { TimeFrame } from '../../../components/time-frame/time-frame';
import { TimeFrameState } from '../../../components/time-frame/types';
import { useOrderStatsQuery } from '../../../generated/graphql';
import { DashboardProvider } from '../context';
import { Tabs } from '../../../components/tabs/Tabs';

interface StatsProps {
  timeFrame: TimeFrameState;
  onFetchingChange: (fetching: boolean) => void;
}

const Statistics: React.FC<StatsProps> = (props) => {
  const { timeFrame, onFetchingChange } = props;
  const [{ data, fetching }, refetch] = useOrderStatsQuery({
    variables: {
      filters: {
        startDate: timeFrame.startDate,
        endDate: timeFrame.endDate,
      },
    },
  });

  useEffect(() => {
    onFetchingChange(fetching);
  }, [fetching]);

  useEffect(() => {
    refetch({
      requestPolicy: 'network-only',
    });
  }, [timeFrame.startDate, timeFrame.endDate]);

  if (!data) {
    return null;
  }

  const entries = data.orderStats;
  return (
    <DashboardProvider entries={entries} refreshData={refetch}>
      <Tabs
        items={[
          {
            title: 'Overzicht',
            path: 'overview',
          },
          {
            title: 'Boekhouding',
            path: 'accounting',
          },
        ]}
      />
    </DashboardProvider>
  );
};

export const StatisticsPage = () => {
  const [timeFrame, setTimeFrame] = useState<null | TimeFrameState>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  return (
    <>
      <PageHeader title="Statistieken" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Statistieken</h1>

          <div className="flex items-center">
            <div className="mr-8">{isFetching && <Spinner size={8} />}</div>

            <TimeFrame onChange={setTimeFrame} />
          </div>
        </div>

        <Suspense
          fallback={
            <div className="flex justify-center items-center mt-32">
              <Spinner size={8} />
            </div>
          }
        >
          {timeFrame && <Statistics timeFrame={timeFrame} onFetchingChange={setIsFetching} />}
        </Suspense>
      </div>
    </>
  );
};
