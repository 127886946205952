import classNames from '@utils/classnames';

export type StatusColor = 'green' | 'orange' | 'red' | 'black';

export interface ITagProps {
  children: React.ReactNode;
  color?: StatusColor;
}

export const StatusBadge: React.FC<ITagProps> = (props) => {
  const { children, color } = props;

  return (
    <div className="badge-container">
      <div
        className={classNames('w-1 h-1 rounded-full mr-2', {
          'bg-dark-01': color === 'black',
          'bg-feedback-negative': color === 'red',
          'bg-orange-01': color === 'orange',
          'bg-green-01': color === 'green',
        })}
      ></div>
      <div>{children}</div>
    </div>
  );
};
