import { PlusIcon } from 'lucide-react';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import diff from 'object-diff';

import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { Button } from 'components/button/Button';
import { InputField } from 'components/input/InputField';
import { Language, useUpdateCustomerContactMutation } from 'generated/graphql';
import { LANGUAGE_VALUES } from '../../../utils/language';
import { formatPhoneNumber } from '../../../utils/phonenumber';
import { getDisplayError } from '../../../utils/get-display-error';
import { SimpleSelectField } from '../../../components/select/SimpleSelectField';
import { CheckboxField } from '../../../components/checkbox/CheckboxField';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { nullthrows } from '../../../utils/invariant';
import { useTranslation } from '../../../contexts/translation-context';

const createContactSchema = Yup.object().shape({
  name: Yup.string().min(1, 'errors.required').required('errors.required'),
  email: Yup.string().email('errors.invalidEmail'),
  phone: Yup.string().phone(),
  language: Yup.mixed().nullable().required('errors.required'),
  shouldReceiveInvoices: Yup.boolean(),
  shouldReceiveOrderConfirmations: Yup.boolean(),
  shouldReceiveCMR: Yup.boolean(),
  shouldReceiveQuotations: Yup.boolean(),
  shouldReceiveDieselSurcharge: Yup.boolean(),
});

export const CustomerPortalUpdateContactPage = () => {
  const navigate = useNavigate();
  const { customerContactId: _customerContactId } = useParams<{ customerContactId: string }>();
  const customerContactId = nullthrows(_customerContactId, 'customer contact id not defined');
  const { customer, refreshData: refetchCustomerData } = useCustomerPortalCustomer();
  const [, updateContactMutation] = useUpdateCustomerContactMutation();
  const { i18n } = useTranslation();

  const contact = nullthrows(
    customer.contacts.find((c) => c.id === +customerContactId),
    i18n('customerPortal.contacts.notFound'),
  );

  const initialValues = {
    name: contact.name,
    email: contact.email,
    phone: contact.phone,
    language: LANGUAGE_VALUES.find((v) => v.key === contact.language),
    function: contact.function,
    internalNotes: contact.internalNotes,
    shouldReceiveInvoices: contact.shouldReceiveInvoices,
    shouldReceiveOrderConfirmations: contact.shouldReceiveOrderConfirmations,
    shouldReceiveCMR: contact.shouldReceiveCMR,
    shouldReceiveQuotations: contact.shouldReceiveQuotations,
    shouldReceiveDieselSurcharge: contact.shouldReceiveDieselSurcharge,
  };

  return (
    <div>
      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: i18n('customerPortal.contacts.contacts'),
              to: '..',
            },
            {
              name: contact.name,
            },
          ]}
        />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={createContactSchema}
        onSubmit={async (values) => {
          try {
            const patch: Partial<typeof values> = diff(initialValues, values);
            const updateData = {
              ...patch,
              phone: patch.phone ? formatPhoneNumber(patch.phone, false) : undefined,
              language: patch.language ? (patch.language.key as Language) : undefined,
            };
            const result = await updateContactMutation({
              id: contact.id,
              data: updateData,
            });
            if (result.error) {
              throw result.error;
            }
            if (result.data) {
              refetchCustomerData();
              navigate('..');
            }
            toast.success(i18n('customerPortal.contacts.updatedContact'));
          } catch (err: any) {
            toast.error(
              i18n('customerPortal.contacts.couldNotUpdateContact', {
                error: getDisplayError(err),
              }),
            );
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText={i18n('customerPortal.contacts.name')} type="text" name="name" />
            <InputField labelText={i18n('customerPortal.contacts.email')} type="email" name="email" />
            <InputField labelText={i18n('customerPortal.contacts.phone')} type="text" name="phone" />
            <InputField labelText={i18n('customerPortal.contacts.function')} type="text" name="function" />
            <SimpleSelectField
              labelText={i18n('customerPortal.contacts.language')}
              items={LANGUAGE_VALUES}
              name="language"
            />
            {/* <TextAreaField labelText="Interne notities" name="internalNotes" spellCheck={true} /> */}

            <h2 className="heading-two mb-2">{i18n('customerPortal.contacts.notifications')}</h2>
            <div className="grid grid-cols-4 gap-x-4 mb-2">
              <CheckboxField labelText={i18n('customerContactNotifications.invoice')} name="shouldReceiveInvoices" />
              <CheckboxField
                labelText={i18n('customerContactNotifications.orderConfirmation')}
                name="shouldReceiveOrderConfirmations"
              />
              <CheckboxField labelText={i18n('customerContactNotifications.cmr')} name="shouldReceiveCMR" />
              <CheckboxField
                labelText={i18n('customerContactNotifications.quotation')}
                name="shouldReceiveQuotations"
              />
              <CheckboxField
                labelText={i18n('customerContactNotifications.dieselSurcharge')}
                name="shouldReceiveDieselSurcharge"
              />
            </div>

            <Button
              type="submit"
              color="primary"
              iconLeft={<PlusIcon className="button-icon" />}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {i18n('customerPortal.contacts.updateContact')}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
