import { DocumentType } from '../../../../generated/graphql';
import { ITabItem, Tabs } from '../../../../components/tabs/Tabs';
import { useResolvedPath } from 'react-router-dom';
import { DocumentTypeNames } from './constants';

const DocumentNoteSettingsPage = () => {
  const tabItems: ITabItem[] = [DocumentType.Tro, DocumentType.Planning, DocumentType.Quotation].map((v) => {
    return {
      title: DocumentTypeNames[v],
      path: useResolvedPath(v).pathname,
    };
  });

  return <Tabs items={tabItems} />;
};

export default DocumentNoteSettingsPage;
