import { useCallback, useEffect, useState } from 'react';
import { useClient } from 'urql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import {
  GetCustomerPortalLocationsDocument,
  GetCustomerPortalLocationsQuery,
  GetCustomerPortalLocationsQueryVariables,
} from '../../../generated/graphql';
import { PageHeader } from '../../../components/PageHeader';
import { Input } from '../../../components/input/Input';
import { PlusIcon, SearchIcon } from 'lucide-react';
import { LinkButton } from '../../../components/button/ButtonLink';
import { Link } from 'react-router-dom';
import { Pagination } from '../../../components/pagination/Pagination';
import { useTranslation } from '../../../contexts/translation-context';

export const CustomerPortalLocationsPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const client = useClient();
  const { i18n } = useTranslation();
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetCustomerPortalLocationsQuery, GetCustomerPortalLocationsQueryVariables>(
          GetCustomerPortalLocationsDocument,
          {
            id: variables.cursor,
            take: variables.take,
            search: searchValue,
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.customerPortalLocations ?? [];
    },
    [client, searchValue],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 20,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [searchValue]);

  return (
    <>
      <PageHeader title={i18n('customerPortal.locations.locations')} />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">{i18n('customerPortal.locations.locations')}</h1>

          <div className="flex gap-4">
            <div className="flex w-96">
              <Input
                type="text"
                placeholder={i18n('customerPortal.locations.findLocation')}
                value={searchValue}
                onChange={setSearchValue}
                iconLeft={<SearchIcon className="input-icon" />}
              />
            </div>

            <div>
              <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
                {i18n('customerPortal.locations.newLocation')}
              </LinkButton>
            </div>
          </div>
        </div>

        <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-4 mt-8">
          {page.data.map((location) => {
            return (
              <Link to={`${location.id}`} key={location.id}>
                <div className="card" data-clickable="true">
                  <div className="card-heading-text mb-4">{location.name}</div>
                  <div>{`${location.street} ${location.streetNumber}`}</div>
                  <div>{`${location.postalCode} ${location.city}, ${location.country}`}</div>
                </div>
              </Link>
            );
          })}
        </div>

        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </>
  );
};
