import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { EyeIcon, KeyIcon } from 'lucide-react';

import { Button } from '../../../components/button/Button';
import { GoogleIcon } from '../../../components/icons/GoogleIcon';
import { PageHeader } from '../../../components/PageHeader';
import { useMeQuery, usePasswordLoginMutation } from '../../../generated/graphql';
import { getDisplayError } from '../../../utils/get-display-error';
import { InputField } from '../../../components/input/InputField';
import { useTranslation } from '../../../contexts/translation-context';
import { LinkButton } from '../../../components/button/ButtonLink';

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Vereist'),
  password: Yup.string().min(1, 'Vereist').required('Vereist'),
});

export const CustomerPortalLoginPage = () => {
  const navigate = useNavigate();
  const [{ data }] = useMeQuery({
    requestPolicy: 'cache-and-network',
  });
  const [, passwordLogin] = usePasswordLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data?.me) {
      navigate('/customer-portal');
    }
  }, [data?.me]);

  const redirectUri = window.location.origin;
  return (
    <div className="h-screen md:grid grid-cols-4">
      <PageHeader title={i18n('auth.login.customerLoginTitle')} />

      <div className="hidden md:block col-span-2 xl:col-span-3 relative">
        <img src="/static/login-banner.jpg" className="w-full h-full object-cover" />

        <img style={{ width: '16rem', height: '8rem' }} className="absolute top-0 left-0 ml-8" src="/static/logo.svg" />
      </div>

      <main className="bg-dark-01 p-8 col-span-2 xl:col-span-1 h-full">
        <div className="flex flex-col justify-center h-full items-center">
          <h1 className="heading-one mb-8 text-white">{i18n('auth.login.customerLoginTitle')}</h1>

          <div className="w-full">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginSchema}
              onSubmit={async (values) => {
                try {
                  const { email, password } = values;
                  const result = await passwordLogin({
                    email,
                    password,
                  });
                  if (result.error) {
                    throw result.error;
                  }

                  const succeeded = result.data?.passwordLogin;
                  if (!succeeded) {
                    throw new Error(i18n('auth.login.incorrectCredentials'));
                  }

                  // This should trigger the redirect to the correct page
                  // Logins should invalidate all mem-caches
                  window.location.reload();
                } catch (err: any) {
                  toast.error(i18n('auth.login.loginFailed', { error: getDisplayError(err) }));
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <InputField labelText={i18n('auth.login.email')} type="email" name="email" />

                  <div className="relative">
                    <div className="absolute flex text-sm underline text-dark-04 hover:text-white justify-end right-0 mt-1">
                      <Link to="/forgot-password">{i18n('auth.login.forgotPassword')}</Link>
                    </div>

                    <InputField
                      labelText={i18n('auth.login.password')}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                    />

                    <EyeIcon
                      className="absolute text-dark-04 hover:text-dark-02 cursor-pointer"
                      style={{ right: '.5rem', bottom: '2rem' }}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>

                  <div className="flex justify-end mt-6">
                    <Button
                      type="submit"
                      color="primary"
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      iconLeft={<KeyIcon className="button-icon" />}
                    >
                      {i18n('auth.login.login')}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          <div className="w-full flex items-center gap-4 my-8">
            <div style={{ height: 2 }} className="w-full bg-white select-none">
              &nbsp;
            </div>
            <div className="text-white">{i18n('or')}</div>
            <div style={{ height: 2 }} className="w-full bg-white select-none">
              &nbsp;
            </div>
          </div>

          <div>
            <Button
              color="primary"
              type="button"
              onTrigger={() => {
                window.location.href = `/api/auth/google/redirect?redirectUri=${encodeURI(redirectUri)}`;
              }}
              iconLeft={<GoogleIcon className="button-icon" />}
            >
              {i18n('auth.login.loginWithGoogle')}
            </Button>
          </div>

          <div className="flex w-full mt-16">
            <LinkButton to="/request-customer-portal-account" color="primary" className="mt-4 w-full">
              <div className="w-full text-center">{i18n('customerPortal.requestAccount.title')}</div>
            </LinkButton>
          </div>
        </div>
      </main>
    </div>
  );
};
