import { SendIcon } from 'lucide-react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'components/button/Button';
import { useCustomerPortalCustomer } from '../CustomerPortalCustomerContext';
import { InputField } from 'components/input/InputField';
import { getDisplayError } from '../../../utils/get-display-error';
import { useInviteCustomerUserMutation } from 'generated/graphql';
import { PageHeader } from 'components/PageHeader';
import { Breadcrumb } from 'components/Breadcrumb';
import { useTranslation } from '../../../contexts/translation-context';

const inviteCustomerUserSchema = Yup.object().shape({
  email: Yup.string().email().required('errors.required'),
});

export const CustomerPortalInviteUserPage = () => {
  const navigate = useNavigate();
  const { customer } = useCustomerPortalCustomer();
  const [, inviteCustomerUser] = useInviteCustomerUserMutation();
  const { i18n } = useTranslation();

  return (
    <div>
      <PageHeader title={i18n('customerPortal.users.inviteUser')} />

      <div className="page-heading">
        <Breadcrumb
          items={[
            {
              name: i18n('customerPortal.users.users'),
              to: '..',
            },
            {
              name: i18n('customerPortal.users.inviteUser'),
            },
          ]}
        />
      </div>

      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={inviteCustomerUserSchema}
        onSubmit={async (values) => {
          try {
            const result = await inviteCustomerUser({
              email: values.email,
              customerId: customer.id,
            });
            if (result.error) {
              throw result.error;
            }
            toast.success(i18n('customerPortal.users.userInvited'));
            navigate('..');
          } catch (err: any) {
            toast.error(
              i18n('customerPortal.users.couldNotInviteUser', {
                error: getDisplayError(err),
              }),
            );
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <InputField labelText={i18n('customerPortal.myProfile.email')} type="email" name="email" />

            <Button
              type="submit"
              color="primary"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              iconLeft={<SendIcon className="button-icon" />}
            >
              {i18n('customerPortal.users.inviteUser')}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
