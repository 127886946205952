import { PlusIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { IterableElement } from 'type-fest';

import { ITableHeader, Table } from '../../../../components/table/Table';
import { Supplier } from '../Supplier';
import { useSupplier } from '../Supplier/supplierContext';
import { formatDate } from '../../../../utils/date';
import { SUPPLIER_TRUCK_DOCUMENT_MAP } from './constants';
import { LinkButton } from '../../../../components/button/ButtonLink';

export type SupplierTruck = IterableElement<Supplier['trucks']>;

const CONTACT_HEADERS: ITableHeader[] = [
  {
    id: 'licensePlate',
    name: 'Nummerplaat',
  },
  {
    id: 'name',
    name: 'Codenaam',
  },
  {
    id: 'planner',
    name: 'Planner',
  },
  {
    id: 'isActive',
    name: 'Actief',
  },
  {
    id: 'insuranceExpiresAt',
    name: 'Verzekering vervalt op',
  },
  {
    id: 'examinationExpiresAt',
    name: 'Keuring vervalt op',
  },
  {
    id: 'documents',
    name: 'Documenten',
  },
];

export const SupplierTrucksPage = () => {
  const { supplier } = useSupplier();

  return (
    <div>
      <div className="flex justify-end">
        <div className="flex">
          <div>
            <LinkButton to="new" color="primary" iconLeft={<PlusIcon className="button-icon" />}>
              Nieuw trekker
            </LinkButton>
          </div>
        </div>
      </div>
      <Table
        idKey="id"
        headers={CONTACT_HEADERS}
        data={supplier.trucks}
        mapData={(data) => {
          return [
            <Link to={`${data.id}`} className="link-text">
              {data.licensePlate}
            </Link>,
            data.name,
            data.planner?.name ?? '-',
            data.isActive ? 'Ja' : 'Nee',
            formatDate(data.insuranceExpiresAt),
            formatDate(data.examinationExpiresAt),
            data.documents.map((v) => SUPPLIER_TRUCK_DOCUMENT_MAP[v.type]).join(', '),
          ];
        }}
        isMultiSelect
      />
    </div>
  );
};
