import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Identifier } from 'dnd-core';
import { ClockIcon, DownloadIcon, SendIcon, Calendar } from 'lucide-react';
import classNames from '@utils/classnames';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

import { PageHeader } from '../../../components/PageHeader';
import { formatDate, formatGraphQLDate, formatInputDate, formatInputTime } from '../../../utils/date';
import { Input } from '../../../components/input/Input';
import {
  GetSuppliersQuery,
  GetOrdersQuery,
  SortDirection,
  useGetOrdersQuery,
  useGetSuppliersQuery,
  OrderDateFilterType,
  OrderLineStopType,
  useUpdateOrderMutation,
  useCreateOrderLinePurchaseMutation,
  usePersistOrderSortingMutation,
  useSendPlanningMutation,
  OrderStatus,
  useDeleteOrderLinePurchaseMutation,
} from '../../../generated/graphql';
import { usePlanningReducer } from './reducer';
import { ORDER_STATUS_COLOR, ORDER_STATUS_OPTIONS } from '../../order/constants';
import { StatusBadge } from '../../../components/StatusBadge';
import { formatNumber, parseNumberInput } from '../../../utils/number';
import { calculateLinesTotalExclVat } from '../../order/utils/price';
import { getDisplayError } from '../../../utils/get-display-error';
import { SpinnerBlock } from '../../../components/Spinner';
import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';
import { useMinimalGeneralSettings } from '../../../contexts/minimal-settings-context';
import { Button } from '../../../components/button/Button';
import { ContactsDialog } from '../../../components/dialog/ContactsDialog';
import { TrailerTypeIcon } from '../../order/pages/order/TrailerTypes';
import { InternalUser, UserCombobox } from '../../user/components/UserCombobox';
import { useAuth } from '../../../contexts/auth-context';

function roundPrice(price: number): number {
  const rounded = Math.round(price / 100) * 100;
  if (Math.abs(price - rounded) <= 5) {
    return rounded;
  } else {
    return price;
  }
}

const noop = () => {};

type SequenceChangedFn = (orderId: string, newSequence: number, date: string) => void;

export type SupplierType = GetSuppliersQuery['suppliers'][0];
export type SupplierTruckType = SupplierType['trucks'][0];
export type OrderType = GetOrdersQuery['orders'][0];
export type OrderSupplierType = NonNullable<OrderType['supplier']>;
export type OrderSupplierTruckType = NonNullable<OrderType['supplierTruck']>;

type OnDropFn = (
  order: OrderType,
  supplier: OrderSupplierType | null,
  previousSupplier: OrderSupplierType | null,
  supplierTruck: OrderSupplierTruckType | null,
  previousSupplierTruck: OrderSupplierTruckType | null,
) => void;

export interface IDraggableOrderProps {
  index: number;
  date: string;
  order: OrderType;
  onDrop: OnDropFn;
  useMinimalLayout?: boolean;
  sequenceChanged: SequenceChangedFn;
}

export interface SupplierDropResult {
  type: 'supplier';
  supplier: SupplierType;
  truck: SupplierTruckType;
}

export type DropResult = SupplierDropResult | null;

export interface DragItem {
  order: OrderType;
  index: number;
}

const DraggableOrder: React.FC<IDraggableOrderProps> = (props) => {
  const { index, date, order, onDrop, sequenceChanged } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [sequence, setSequence] = useState(() => {
    return (order.routeSortings.find((v) => dayjs(v.date).isSame(date, 'day'))?.sequence ?? 1).toString(10);
  });
  const [_persistSortingState, persistSortingMutate] = usePersistOrderSortingMutation();
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'order',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current || !item) {
        return;
      }
    },
  });
  const [_collected, drag] = useDrag(() => ({
    type: 'order',
    item: (): DragItem => {
      return {
        order,
        index,
      };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item) {
        if (dropResult) {
          onDrop(
            item.order,
            dropResult.supplier,
            item.order.supplier ?? null,
            dropResult.truck,
            item.order.supplierTruck ?? null,
          );
        } else {
          onDrop(item.order, null, item.order.supplier ?? null, null, item.order.supplierTruck ?? null);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const lineSales = order.lines.map((l) => l.sales).flat();
  const linePurchases = order.lines.map((l) => l.purchases).flat();
  const saleTotal = calculateLinesTotalExclVat(lineSales);
  const purchaseTotal = calculateLinesTotalExclVat(linePurchases);

  const persistSorting = async () => {
    try {
      const sequenceInt = parseInt(sequence, 10);
      if (isNaN(sequenceInt)) {
        throw new Error('Geen geldig getal');
      }

      const result = await persistSortingMutate({
        orderId: order.id,
        sequence: sequenceInt,
        date: formatGraphQLDate(date),
      });
      if (result.error) {
        throw result.error;
      }
      sequenceChanged(order.id, sequenceInt, date);
      toast.success('Sortering opgeslagen');
    } catch (err) {
      toast.error(`Kon sortering niet opslaan: ${getDisplayError(err)}`);
    }
  };

  return (
    <div className="rounded bg-dark-05 p-2" ref={drag(drop(ref)) as any} data-handler-id={handlerId}>
      {order.supplier && (
        <div className="flex justify-end mb-2">
          <div className="w-16">
            <Input
              type="number"
              value={sequence}
              onChange={setSequence}
              onBlur={() => persistSorting()}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter') {
                  persistSorting();
                }
              }}
            />
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <div className="font-medium">{order.orderNumber || 'DRAFT'}</div>
        <div className="whitespace-nowrap">{`AK € ${formatNumber(purchaseTotal, 2, {
          decimalSeperator: ',',
        })} / VK € ${formatNumber(saleTotal, 2, {
          decimalSeperator: ',',
        })}`}</div>
        <StatusBadge color={ORDER_STATUS_COLOR[order.status]}>
          {ORDER_STATUS_OPTIONS.find((v) => v.key === order.status)?.name ?? order.status}
        </StatusBadge>
      </div>

      <div>Referentie klant: {order.customerRef}</div>

      <div>
        {order.lines.map((l) => {
          return (
            <div key={l.id}>
              {l.stops
                .sort((a, b) => a.sequenceIndex - b.sequenceIndex)
                .map((s) => {
                  return (
                    <div key={s.id}>
                      <div className="font-medium">{s.type === OrderLineStopType.Load ? 'Laden' : 'Lossen'}</div>
                      <div className="col-span-2">{`${s.location.name} - ${s.location.country} ${s.location.city}`}</div>
                      <div className="grid grid-cols-3">
                        <div className="font-regular">Ref: {s.reference}</div>
                        <div className="flex gap-4 col-span-2">
                          <div className="flex gap-1 items-center">
                            <Calendar className="button-icon" /> {formatDate(s.date)}
                          </div>
                          <div className="flex gap-1 items-center">
                            <ClockIcon className="button-icon" />
                            {`${formatInputTime(s.timeStart)}-${formatInputTime(s.timeEnd)}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}

        <div className="flex flex-wrap gap-4 mt-2">
          {order.allowedTrailerTypes.map((v) => {
            return <TrailerTypeIcon key={`${order.id}-${v}`} type={v} variant="small" />;
          })}
        </div>
      </div>
    </div>
  );
};

export interface ISupplierDropzoneProps {
  date: string;
  supplier: SupplierType;
  supplierTruck: SupplierTruckType;
  orders: OrderType[];
  onDrop: OnDropFn;
  refreshOrders: () => void;
  sequenceChanged: SequenceChangedFn;
}

const SupplierDropzone: React.FC<ISupplierDropzoneProps> = (props) => {
  const { date, supplier, supplierTruck, orders, onDrop, refreshOrders, sequenceChanged } = props;
  const [collectedProps, drop] = useDrop(() => ({
    accept: 'order',
    drop: (): SupplierDropResult => {
      return {
        type: 'supplier',
        supplier,
        truck: supplierTruck,
      };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const { settings } = useMinimalGeneralSettings();
  const [_createOrderLinePurchaseState, createOrderLinePurchase] = useCreateOrderLinePurchaseMutation();
  const [_deleteOrderLinePurchaseState, deleteOrderLinePurchase] = useDeleteOrderLinePurchaseMutation();
  const [_sendPlanningState, sendPlanning] = useSendPlanningMutation();

  const dropProps = collectedProps as any;
  const isActive = dropProps.canDrop && dropProps.isOver;

  const filteredOrdersForPurchase = orders.filter((o) => {
    const unloadStops = o.lines.map((l) => l.stops.filter((s) => s.type === OrderLineStopType.Unload)).flat();
    const unloadDates = unloadStops
      .sort((a, b) => {
        return dayjs(a.date).diff(b.date);
      })
      .map((v) => v.date);
    const lastUnloadDate = unloadDates[unloadDates.length - 1];
    return lastUnloadDate && dayjs(lastUnloadDate).isSame(date, 'day');
  });

  return (
    <div
      className={classNames('border rounded', {
        'border-orange-00 bg-white': isActive,
        'border-dark-04 border-dotted bg-offwhite': !isActive,
      })}
      ref={drop}
    >
      <div className="grid gap-4 px-2 py-1 font-medium text-dark-01" style={{ gridTemplateColumns: '1fr auto' }}>
        <div className="flex items-center">{`${supplier.name} - ${supplier.country}${supplier.postalCode} - ${supplierTruck.licensePlate} ${supplierTruck.name}`}</div>
        <div className="flex gap-2">
          <FormDialog
            triggerText="Aankoopprijs instellen"
            title="Stel aankoopprijs in"
            submitText="Stel prijs in"
            isDisabled={filteredOrdersForPurchase.length === 0}
            initialValues={{ purchaseAmount: '' }}
            onSubmit={async (values) => {
              const purchaseAmount = parseNumberInput(values.purchaseAmount.trim(), 2);
              if (isNaN(purchaseAmount)) {
                toast.error('Aankoopprijs is geen geldig getal');
                return;
              }

              const totalSalePrice = filteredOrdersForPurchase.reduce((acc, o) => {
                return acc + calculateLinesTotalExclVat(o.lines.map((l) => l.sales).flat());
              }, 0);
              const purchasePriceMultipliers = filteredOrdersForPurchase.map((o) => {
                const totalOrderSalePrice = calculateLinesTotalExclVat(o.lines.map((l) => l.sales).flat());
                return totalOrderSalePrice / totalSalePrice;
              });

              try {
                for (let i = 0; i < filteredOrdersForPurchase.length; i++) {
                  const order = filteredOrdersForPurchase[i];

                  if (order.status !== OrderStatus.Created && order.status !== OrderStatus.Draft) {
                    toast.error(
                      `Kan aankoopprijs niet aanpassen voor bevestigde / uitgevoerde order ${
                        order.orderNumber ?? 'DRAFT'
                      }`,
                    );
                    continue;
                  }

                  for (const line of order.lines) {
                    for (const p of line.purchases) {
                      const res = await deleteOrderLinePurchase({
                        id: p.id,
                      });

                      if (res.error) {
                        throw res.error;
                      }
                    }
                  }

                  const multiplier = purchasePriceMultipliers[i];
                  const orderPurchaseAmount = purchaseAmount * multiplier;
                  const linePrice = orderPurchaseAmount / order.lines.length;

                  for (const line of order.lines) {
                    const res = await createOrderLinePurchase({
                      orderLineId: line.id,
                      data: {
                        productTypeId: settings.defaultProductType?.id ?? '',
                        vatRateId: supplier.defaultVatRate.id,
                        amount: 100,
                        unitPrice: roundPrice(Math.floor(linePrice)),
                        externalNote: '',
                      },
                    });

                    if (res.error) {
                      throw res.error;
                    }
                  }

                  toast.success(`Aankoopprijs ingesteld voor order ${order.orderNumber ?? 'DRAFT'}`);
                }

                refreshOrders();
              } catch (err) {
                toast.error(`Kon orders niet aanpassen: ${getDisplayError(err)}`);
              }
            }}
          >
            <InputField labelText="Totale aankoopprijs" name="purchaseAmount" type="number" step="0.01" />
          </FormDialog>

          <Button
            color="secondary"
            isDisabled={orders.length === 0}
            onTrigger={() => {
              const url = new URL(
                `/api/planning/export-document?date=${date}&truckId=${encodeURIComponent(
                  supplierTruck.id,
                )}&orderIds=${encodeURIComponent(orders.map((o) => o.id).join(';'))}`,
                window.location.href,
              );
              window.open(url.toString());
            }}
          >
            <DownloadIcon className="button-icon" />
          </Button>

          <ContactsDialog
            triggerText={<SendIcon className="button-icon" />}
            triggerColor={'primary'}
            isDisabled={orders.length === 0}
            title="Verzend planning"
            submitText="Verzend"
            description={`Ben je zeker dat je de planning naar ${supplier.name} wilt verzenden?`}
            contacts={supplier.contacts.map((c) => {
              return {
                id: '' + c.id,
                name: c.name,
                email: c.email,
              };
            })}
            initialValue={supplier.contacts.filter((c) => c.shouldReceiveTransportOrders).map((v) => '' + v.id)}
            onSubmit={async (contacts) => {
              try {
                const res = await sendPlanning({
                  date,
                  orderIds: orders.map((v) => v.id),
                  truckId: supplierTruck.id,
                  contacts: contacts.map((c) => +c),
                });
                if (res.error) {
                  throw res.error;
                }
                toast.success('Transport order verstuurd');
              } catch (err: any) {
                toast.error('Kon transport order niet versturen: ' + getDisplayError(err));
                throw err;
              }
            }}
          />
        </div>
      </div>

      <div className="grid gap-4 p-4 pb-8" style={{ minHeight: 200 }}>
        {orders.map((o, idx) => {
          return (
            <DraggableOrder
              index={idx}
              date={date}
              order={o}
              key={o.id}
              onDrop={onDrop}
              sequenceChanged={sequenceChanged}
            />
          );
        })}
      </div>
    </div>
  );
};

export interface IOrderBacklogProps {
  orders: OrderType[];
  onDrop: OnDropFn;
  date: string;
}

const OrderBacklog: React.FC<IOrderBacklogProps> = (props) => {
  const { orders, onDrop, date } = props;
  const normalizedDate = dayjs(date).format('YYYY-MM-DD');
  const [type, setType] = useState<'load' | 'unload'>('load');
  const [collectedProps, drop] = useDrop(() => ({
    accept: 'order',
    drop: () => {
      return null;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const dropProps = collectedProps as any;
  const isActive = dropProps.canDrop && dropProps.isOver;

  const filteredOrders = orders.filter((o) => {
    const relevantDates = new Set(
      o.lines
        .map((v) =>
          v.stops
            .filter((v) => {
              if (type === 'load') {
                return v.type === OrderLineStopType.Load;
              } else {
                return v.type === OrderLineStopType.Unload;
              }
            })
            .map((s) => dayjs(s.date).format('YYYY-MM-DD')),
        )
        .flat(),
    );

    return relevantDates.has(normalizedDate);
  });

  const loadOrderCount = type === 'load' ? filteredOrders.length : orders.length - filteredOrders.length;
  const unloadOrderCount = type === 'unload' ? filteredOrders.length : orders.length - filteredOrders.length;

  return (
    <div
      className={classNames('border rounded', {
        'border-orange-00 bg-white': isActive,
        'border-dark-04 border-dotted bg-offwhite': !isActive,
      })}
      ref={drop}
    >
      <div className="grid grid-cols-2 p-2 gap-2">
        <div
          className={classNames('bg-dark-05 hover:bg-dark-04 rounded px-2 cursor-pointer', {
            'bg-dark-04': type === 'load',
          })}
          onClick={() => {
            setType('load');
          }}
        >
          Laden ({loadOrderCount})
        </div>
        <div
          className={classNames('bg-dark-05 hover:bg-dark-04 rounded px-2 cursor-pointer', {
            'bg-dark-04': type === 'unload',
          })}
          onClick={() => {
            setType('unload');
          }}
        >
          Lossen ({unloadOrderCount})
        </div>
      </div>

      <div className="grid gap-2 p-2 overflow-y-auto" style={{ height: 'calc(100vh - 300px)' }}>
        {filteredOrders.map((o, idx) => {
          return (
            <DraggableOrder
              index={idx}
              date={date}
              order={o}
              key={o.id}
              onDrop={onDrop}
              useMinimalLayout={true}
              sequenceChanged={noop}
            />
          );
        })}
      </div>
    </div>
  );
};

export interface IPlanningContainerProps {
  userId?: string | null;
  date: string;
}

const PlanningContainer: React.FC<IPlanningContainerProps> = (props) => {
  const { date, userId } = props;

  const [suppliersResult] = useGetSuppliersQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      filters: {
        isRegularCarrier: true,
      },
    },
  });
  const [loadOrdersResult, refetchLoadOrders] = useGetOrdersQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      orderBy: {
        orderNumber: SortDirection.Asc,
      },
      filters: {
        dateType: OrderDateFilterType.Load,
        startDate: date,
        endDate: date,
      },
    },
  });
  const [unloadOrdersResult, refetchUnloadOrders] = useGetOrdersQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      orderBy: {
        orderNumber: SortDirection.Asc,
      },
      filters: {
        dateType: OrderDateFilterType.Unload,
        startDate: date,
        endDate: date,
      },
    },
  });
  const [_updateOrderState, updateOrder] = useUpdateOrderMutation();

  const [state, dispatch] = usePlanningReducer(new Date(date), userId ?? null);

  useEffect(() => {
    dispatch({
      type: 'suppliers-fetched',
      suppliers: suppliersResult.data?.suppliers || [],
    });
  }, [suppliersResult.data?.suppliers]);

  useEffect(() => {
    const _orders = [...(loadOrdersResult.data?.orders || []), ...(unloadOrdersResult.data?.orders || [])];
    const foundOrderIds = new Set<string>();
    const orders = _orders.filter((o) => {
      if (foundOrderIds.has(o.id)) {
        return false;
      } else {
        foundOrderIds.add(o.id);
        return true;
      }
    });
    dispatch({
      type: 'orders-fetched',
      orders,
    });
  }, [loadOrdersResult.data?.orders, unloadOrdersResult.data?.orders]);

  const refreshOrders = () => {
    refetchLoadOrders({
      requestPolicy: 'cache-and-network',
    });
    refetchUnloadOrders({
      requestPolicy: 'cache-and-network',
    });
  };

  const handleDrop = async (
    order: OrderType,
    supplier: OrderSupplierType | null,
    previousSupplier: OrderSupplierType | null,
    supplierTruck: OrderSupplierTruckType | null,
    previousSupplierTruck: OrderSupplierTruckType | null,
  ) => {
    if (supplier?.id === previousSupplier?.id && supplierTruck?.id === previousSupplierTruck?.id) {
      return;
    }

    try {
      const orderCount = state.supplierOrders.get(supplierTruck?.id ?? '')?.length ?? 0;
      const res = await updateOrder({
        id: order.id,
        data: {
          supplierId: supplier?.id ?? null,
          supplierTruckId: supplierTruck?.id ?? null,
          routeSortingOffset: orderCount,
        },
      });
      if (res.error) {
        throw res.error;
      }
      toast.success('Order upgedate');
    } catch (err) {
      toast.error(`Kon order niet updaten: ${getDisplayError(err)}`);
    }
  };

  const supplierTrucks: Array<SupplierTruckType & { supplier: SupplierType }> = [];
  for (const supplier of state.suppliers) {
    for (const truck of supplier.trucks) {
      if (truck.isActive) {
        supplierTrucks.push({
          ...truck,
          supplier,
        });
      }
    }
  }

  const handleSequenceChanged = useCallback(
    (orderId: string, newSequence: number, date: string) => {
      dispatch({
        type: 'sequence-changed',
        orderId,
        sequence: newSequence,
        date,
      });
    },
    [dispatch],
  );

  return (
    <div className="grid my-6 gap-4" style={{ gridTemplateColumns: '3fr 5fr' }}>
      <DndProvider backend={HTML5Backend}>
        <div>
          <div className="heading-two mb-2">Orders</div>

          <OrderBacklog orders={state.pendingOrders} onDrop={handleDrop} date={date} />
        </div>

        <div>
          <div className="heading-two mb-2">Vervoerders</div>

          <div className="grid gap-4 overflow-y-auto" style={{ height: 'calc(100vh - 300px)' }}>
            {supplierTrucks.map((v) => {
              return (
                <SupplierDropzone
                  key={v.id}
                  supplier={v.supplier}
                  supplierTruck={v}
                  date={date}
                  orders={state.supplierOrders.get(v.id) ?? []}
                  onDrop={handleDrop}
                  refreshOrders={refreshOrders}
                  sequenceChanged={handleSequenceChanged}
                />
              );
            })}
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

export const PlanningPage = () => {
  const { me } = useAuth();
  const [date, setDate] = useState(() => formatInputDate(new Date()));
  const [user, setUser] = useState<InternalUser | null>(() => ({ id: me.id, name: me.name }));

  return (
    <>
      <PageHeader title="Planning" />

      <div>
        <div className="page-heading">
          <h1 className="heading-one">Planning</h1>
        </div>

        <div className="flex gap-4">
          <label className="block max-w-xs">
            <div className="label-text py-1 w-full">Datum</div>
            <div>
              <Input type="date" value={date} onChange={setDate} />
            </div>
          </label>
          <label className="block max-w-xs">
            <div className="label-text py-1 w-full">Planner</div>
            <div>
              <UserCombobox value={user} onChange={setUser} />
            </div>
          </label>
        </div>

        <React.Suspense fallback={<SpinnerBlock message="Orders aan het laden..." />}>
          <PlanningContainer date={date} userId={user?.id} key={`${formatDate(date)}-${user?.id ?? '-'}`} />
        </React.Suspense>
      </div>
    </>
  );
};
