import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components/button/Button';
import { GoogleIcon } from '../../../components/icons/GoogleIcon';
import { PageHeader } from '../../../components/PageHeader';
import { useMeQuery } from '../../../generated/graphql';

export const InternalLoginPage = () => {
  const navigate = useNavigate();
  const [{ data }] = useMeQuery({
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.me) {
      navigate('/internal');
    }
  }, [data?.me]);

  const redirectUri = window.location.origin;
  return (
    <div className="h-screen md:grid grid-cols-4">
      <PageHeader title="Interne Login" />

      <div className="hidden md:block col-span-2 xl:col-span-3 relative">
        <img src="/static/login-banner.jpg" className="w-full h-full object-cover" />

        <img style={{ width: '16rem', height: '8rem' }} className="absolute top-0 left-0 ml-8" src="/static/logo.svg" />
      </div>

      <main className="bg-dark-01 p-8 col-span-2 xl:col-span-1 h-full">
        <div className="flex flex-col justify-center h-full items-center">
          <h1 className="heading-one mb-8 text-white">Interne Login</h1>

          <div>
            <Button
              color="primary"
              type="button"
              onTrigger={() => {
                window.location.href = `/api/auth/google/redirect?redirectUri=${encodeURI(redirectUri)}`;
              }}
              iconLeft={<GoogleIcon className="button-icon" />}
            >
              Login met Google
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};
